import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { PwaService, ThemeService } from '@shared/services';
import { DomainService } from '@shared/services/domain.service';
import { MessagingService } from '@shared/services/messaging.service';
import { Subscription } from 'rxjs';
import { TranslateConfigService } from './shared/services/translate-config.service';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewChecked {
  loaded = false;
  title = 'frontend';
  message;
  color: any;
  @ViewChild('div') themeContainer: ElementRef;
  subscription: Subscription;

  constructor(private pwaService: PwaService,
              private messagingService: MessagingService,
              public themeService: ThemeService,
              private translateConfigService: TranslateConfigService,
              private changeDetector: ChangeDetectorRef,
              private router: Router,
              private domainService: DomainService,
              private renderer: Renderer2,
              private el: ElementRef) {

        this.subscription = router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            browserRefresh = !router.navigated;
          }
        });
  }

  ngOnInit(): void {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'canonical');
    this.renderer.setAttribute(link, 'href', window.location.href);
    const head = this.el.nativeElement.ownerDocument.head;
    this.renderer.appendChild(head, link);

    this.domainService.checkDomain().then(() => {
      this.loaded = true;
    });

    /*this.messagingService.Inicialize();
    this.iniToken = this.messagingService.requestPermission();
    this.currentToken = this.messagingService.getToken();
    if (this.iniToken) {
      this.messagingService.saveDeviceUserToken(this.iniToken);
    }
    if (this.currentToken) {
      this.messagingService.saveDeviceUserToken(this.currentToken);
    }
    this.messagingService.receiveMessage();
    this.messagingService.listen();
    this.message = this.messagingService.currentMessage;*/
  }

  ngAfterViewChecked() {
    this.color = localStorage.getItem('color');
    if ((this.themeContainer !== undefined) && (document.getElementById('gallegazo') !== null)) {
      this.color = window.getComputedStyle(document.getElementById('gallegazo')).backgroundColor;
    } else if ((this.themeContainer !== undefined) && (document.getElementById('gallegazo2') !== null)) {
      this.color = window.getComputedStyle(document.getElementById('gallegazo2')).color;
    } else if ((this.themeContainer !== undefined) && (document.getElementById('gallegazo3') !== null)) {
      this.color = window.getComputedStyle(document.getElementById('gallegazo3')).backgroundColor;
    } else if ((this.themeContainer !== undefined) && (document.getElementById('gallegazo4') !== null)) {
      this.color = window.getComputedStyle(document.getElementById('gallegazo4')).backgroundColor;
    }
    localStorage.setItem('color', this.color);
    this.changeDetector.detectChanges();
  }
}
