<!-- Table mode -->
<mat-card *ngIf="displayMode == 'table'" class="table-mode-container">
  <mat-card-title *ngIf="title" [ngClass]="{'title-with-icon': titleIcon}" class="table-mode-title">
    <mat-icon *ngIf="titleIcon" [svgIcon]="titleIcon"></mat-icon>
    <span class="title">{{ title }}</span>
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </mat-card-title>
  <mat-card-content class="model-files-content border-bottom" fxLayout="row wrap">
    <table [ngClass]="{'model-files-table-with-preview' : preview}" class="model-files-table">
      <colgroup>
        <col class="col-1-width" span="1">
        <col class="col-2-width" span="1">
        <col class="col-3-width" span="1">
        <col class="col-4-width" span="1">
      </colgroup>
      <tbody>

      <tr *ngFor="let myFile of myFiles; let i = index;"
          class="upload-item-content model-files-border-bottom">
        <td>
                <span *ngIf="!preview" [ngSwitch]="myFile.extension">
                  <mat-icon *ngSwitchCase="'pdf'" svgIcon="icon-doc-pdf"></mat-icon>
                  <mat-icon *ngSwitchCase="'xls'" svgIcon="icon-doc-xls"></mat-icon>
                  <mat-icon *ngSwitchCase="'xlsx'" svgIcon="icon-doc-xlsx"></mat-icon>
                  <mat-icon *ngSwitchCase="'csv'" svgIcon="icon-doc-csv"></mat-icon>
                  <mat-icon *ngSwitchCase="'jpg'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'jpeg'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'gif'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'png'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'svg'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
                </span>
          <div (click)="openImagePreview(i)" *ngIf="preview" [beeBackgroundImage]="imagePreviews[i]"
               class="image-container">
                             <span class="preview-icon">
                                <mat-icon svgIcon="image-search"></mat-icon>
                            </span>
          </div>
        </td>
        <td>
          <span (click)="downloadFile(myFile)" class="file-name">{{ myFile.name }}</span>
        </td>
        <td *ngIf="uploadEnabled">
                <span style="max-height: 45px">
                        <svg version="1.1"
                             viewBox="0 0 130.2 130.2"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle class="path circle" cx="65.1" cy="65.1" fill="none"
                                    r="62.1" stroke="#359964" stroke-miterlimit="10" stroke-width="6"/>
                            <polyline class="path check" fill="none" points="100.2,40.2 51.5,88.8 29.8,67.5 " stroke="#359964"
                                      stroke-linecap="round" stroke-miterlimit="10"
                                      stroke-width="6"/>
                        </svg>
                        </span>
        </td>
        <td (click)="deleteFile(myFile)" *ngIf="uploadEnabled" class="action-icon" matTooltip="Eliminar"
            matTooltipPosition="above">
          <mat-icon svgIcon="delete"></mat-icon>
        </td>
      </tr>

      <ng-container *ngIf="uploadEnabled">
        <tr *ngFor="let f of files; let i = index;"
            class="upload-item-content model-files-border-bottom">
          <td>
                      <span *ngIf="!preview" [ngSwitch]="f.type">
                        <mat-icon *ngSwitchCase="'application/pdf'" svgIcon="icon-doc-pdf"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.ms-excel'" svgIcon="icon-doc-xls"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                  svgIcon="icon-doc-xlsx"></mat-icon>
                        <mat-icon *ngSwitchCase="'text/csv'" svgIcon="icon-doc-csv"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpeg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/gif'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/png'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/svg+xml'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
                      </span>
            <div (click)="openImagePreview(i+ myFiles.length)" *ngIf="preview" [beeBackgroundImage]="imagePreviews[i+ myFiles.length]"
                 class="image-container">
                               <span class="preview-icon">
                                  <mat-icon svgIcon="image-search"></mat-icon>
                              </span>
            </div>
          </td>
          <td>
            <span (click)="downloadFile(newFiles[i])" class="file-name">{{ newFiles[i]?.name }}</span>
          </td>
          <td>
            <mat-progress-bar *ngIf='f?.progress?.data?.percentage!=100 || !uploadedFiles[i]' mode="determinate"
                              value="{{f?.progress?.data?.percentage}}"></mat-progress-bar>
            <span *ngIf=' f?.progress?.data?.percentage>0' style="max-height: 45px">
                        <svg *ngIf='f?.progress?.data?.percentage==100 && uploadedFiles[i]' version="1.1"
                             viewBox="0 0 130.2 130.2"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle class="path circle" cx="65.1" cy="65.1" fill="none"
                                    r="62.1" stroke="#359964" stroke-miterlimit="10" stroke-width="6"/>
                            <polyline class="path check" fill="none" points="100.2,40.2 51.5,88.8 29.8,67.5 " stroke="#359964"
                                      stroke-linecap="round" stroke-miterlimit="10"
                                      stroke-width="6"/>
                        </svg>
                        <svg *ngIf="f?.progress?.data?.percentage<100 && !uploadedFiles[i]" version="1.1"
                             viewBox="0 0 130.2 130.2" xmlns="http://www.w3.org/2000/svg">
                            <circle class="path circle" cx="65.1" cy="65.1" fill="none"
                                    r="62.1" stroke="#D06079" stroke-miterlimit="10" stroke-width="6"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-linecap="round" stroke-miterlimit="10"
                                  stroke-width="6" x1="34.4" x2="95.8" y1="37.9" y2="92.3"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-linecap="round" stroke-miterlimit="10"
                                  stroke-width="6" x1="95.8" x2="34.4" y1="38" y2="92.2"/>
                        </svg>
                        </span>
          </td>
          <td (click)="deleteNewFile(newFiles[i], f)" class="action-icon" matTooltip="Eliminar"
              matTooltipPosition="above">
            <mat-icon svgIcon="delete"></mat-icon>
          </td>
        </tr>
        <!--archivos rechazados-->
        <tr *ngIf="rejectedFiles.length!=0" class="upload-item-content model-files-border-bottom">
          <td colspan="2">Archivos no subidos:</td>
          <td></td>
          <!--<td style="font-size: small">(formatos válidos: {{allowedFileFormats}})</td>-->
          <td (click)="removeAllFilesNoLoaded()" class="action-icon" matTooltip="Eliminar todos"
              matTooltipPosition="above">
            <mat-icon svgIcon="delete"></mat-icon>
          </td>
        </tr>
        <tr *ngFor="let f of rejectedFiles; let i = index;"
            class="upload-item-content model-files-border-bottom">
          <td *ngIf="!preview">
                  <span [ngSwitch]="f.type">
                        <mat-icon *ngSwitchCase="'application/pdf'" svgIcon="icon-doc-pdf"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.ms-excel'" svgIcon="icon-doc-xls"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                  svgIcon="icon-doc-xlsx"></mat-icon>
                        <mat-icon *ngSwitchCase="'text/csv'" svgIcon="icon-doc-csv"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpeg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/gif'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/png'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/svg+xml'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
                      </span>
          </td>
          <td *ngIf="!preview" class="filename">
            <span>{{ f.name }} </span>
          </td>
          <td *ngIf="preview" colspan="2">
            <span><b>{{ i + 1 }}.</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ f.name }}</span>
          </td>
          <td>
                    <span style="max-height: 45px">
                        <svg version="1.1"
                             viewBox="0 0 130.2 130.2" xmlns="http://www.w3.org/2000/svg">
                            <circle class="path circle" cx="65.1" cy="65.1" fill="none"
                                    r="62.1" stroke="#D06079" stroke-miterlimit="10" stroke-width="6"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-linecap="round" stroke-miterlimit="10"
                                  stroke-width="6" x1="34.4" x2="95.8" y1="37.9" y2="92.3"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-linecap="round" stroke-miterlimit="10"
                                  stroke-width="6" x1="95.8" x2="34.4" y1="38" y2="92.2"/>
                        </svg>
                        </span>
          </td>
          <td (click)="removeFileNoLoaded(i)" class="action-icon" matTooltip="Eliminar"
              matTooltipPosition="above">
            <mat-icon svgIcon="delete"></mat-icon>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <div *ngIf="uploadEnabled" class="table-mode-actions">
      <!--
      <label fxFlex="calc(35%-12px)" fxFlex.lt-md="100" ngFileDrop [options]="options"
             (uploadOutput)="checkOutput($event)" [uploadInput]="uploadInput"
             class="table-mode-button" [attr.for]="id">
        <mat-icon svgIcon="plus"></mat-icon>
        <span class="plus-input-text">{{ inputText }}</span>
      </label>
      -->
      <label (uploadOutput)="checkOutput($event)" [attr.for]="id" [options]="options"
             [uploadInput]="uploadInput" class="table-mode-drop-box" ngFileDrop>
        <mat-icon svgIcon="paperclip"></mat-icon>
        {{ dropBoxText }}
      </label>

      <input (uploadOutput)="checkOutput($event)" [id]="id" [options]="options" [uploadInput]="uploadInput" multiple
             ngFileSelect onclick="this.value = null" style="display: none"
             type="file">
    </div>
    <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
      {{ recommendedDimensions }}
    </div>
    <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
      {{ maxFileSizeTip }}
    </div>
  </mat-card-content>
</mat-card>

<!-- Circular mode (1 file) -->
<div *ngIf="displayMode == 'circle'" class="circular-mode-container">
  <div class="circular-mode-image-container">
    <label *ngIf="myFiles.length == 0 && newFiles.length == 0"
           [attr.for]="id"
           [beeBackgroundImage]="placeholderImageUrl"
           class="circular-mode-image-circle">
             <span class="preview-icon">
                        <mat-icon svgIcon="pencil"></mat-icon>
             </span>
    </label>
    <div (click)="openImagePreview(0)" *ngIf="myFiles.length == 1 || newFiles.length == 1"
         [beeBackgroundImage]="imagePreviews[0]" class="circular-mode-image-circle">
                 <span class="preview-icon">
                        <mat-icon svgIcon="image-search"></mat-icon>
                 </span>
    </div>
  </div>
  <div class="circular-mode-actions-container">
    <label *ngIf="selectImageLabel" [attr.for]="id" class="circular-mode-actions">
      <mat-icon svgIcon="pencil"></mat-icon>
      <span *ngIf="myFiles.length == 0 && newFiles.length == 0">{{ selectImageLabel }}</span>
      <span *ngIf="myFiles.length == 1 || newFiles.length == 1">{{ inputText }}</span>
    </label>
    <label *ngIf="!selectImageLabel" [attr.for]="id" class="circular-mode-actions-absolute">
      <mat-icon svgIcon="camera"></mat-icon>
    </label>

    <input (change)="openCrop($event)" *ngIf="cropEnabled" [id]="id" onclick="this.value = null"
           style="display: none"
           type="file"/>

    <input (uploadOutput)="onResetFiles($event)" *ngIf="!cropEnabled" [id]="id" [options]="options" [uploadInput]="uploadInput" multiple
           ngFileSelect onclick="this.value = null" style="display: none"
           type="file">
  </div>
</div>

<!-- Rectangular mode (1 file) -->
<div *ngIf="displayMode == 'square'" class="square-mode-container">
  <div *ngIf="title" class="square-mode-title">
    {{ title }}
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </div>

  <label *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile" [attr.for]="id"
         class="square-mode-button-container">
    <mat-icon [svgIcon]="buttonIcon"></mat-icon>
    {{ inputText }}
  </label>

  <div (click)="openImagePreview(0)" *ngIf="preview && (myFiles.length == 1 || newFiles.length == 1)"
       [beeBackgroundImage]="imagePreviews[0]"
       [style.height.px]="160/aspectRatio"
       class="square-mode-image-container">
      <span class="preview-icon">
          <mat-icon svgIcon="image-search"></mat-icon>
      </span>
  </div>

  <div *ngIf="!preview && (myFiles.length == 1 || newFiles.length == 1)">
        <span *ngIf="newFiles.length == 1">
            {{ newFiles[0].name }}
        </span>
    <span *ngIf="myFiles.length == 1">
            {{ myFiles[0].name }}
        </span>
  </div>

  <div class="square-mode-actions-container">
    <label [attr.for]="id" class="square-mode-edit-action">
      <!--<mat-icon svgIcon="pencil" *ngIf="imagePreviews.length == 0"></mat-icon>-->
      <span
          *ngIf="noFilesLabelEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile">{{ noFilesLabel }}</span>
      <span *ngIf="myFiles.length == 1 || newFiles.length == 1 || uploadingFile">
        <mat-icon svgIcon="pencil"></mat-icon>
        {{ editButtonText }}
      </span>
    </label>

    <div (click)="deleteNewFile(newFiles[0], files[0])" *ngIf="newFiles.length == 1"
         class="square-mode-delete-action">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>
    <div (click)="deleteFile(myFiles[0])" *ngIf="myFiles.length == 1" class="square-mode-delete-action">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>

    <input (change)="openCrop($event)" *ngIf="cropEnabled" [id]="id" onclick="this.value = null"
           style="display: none"
           type="file"/>

    <input (uploadOutput)="onResetFiles($event)" *ngIf="!cropEnabled" [id]="id" [options]="options" [uploadInput]="uploadInput" multiple
           ngFileSelect onclick="this.value = null" style="display: none"
           type="file">
  </div>

  <div *ngIf="showSize && newFiles.length == 1" class="dimensions-tip" fxFlex="100">
    {{ newFiles[0].size / 1000000 | number: '1.2-2' }} MB
    <span *ngIf="showPages">{{ newFiles[0].pages }}páginas</span>
  </div>

  <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
    {{ recommendedDimensions }}
  </div>
  <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
    {{ maxFileSizeTip }}
  </div>
  <bracelit-preloader *ngIf="uploadingFile" [diameter]="20" [loadingText]="uploadingFileText">
  </bracelit-preloader>
</div>

<!-- Compound mode (1 file) -->
<div *ngIf="displayMode == 'compound'" class="compound-mode-container">
  <div class="compound-mode-title">
    <span *ngIf="title">{{ title }}</span>
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </div>

  <label *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile" [attr.for]="id"
         class="compound-mode-button-container">
    <mat-icon svgIcon="image-plus"></mat-icon>
    {{ inputText }}
  </label>

  <div (click)="openImagePreview(0)" *ngIf="preview && (myFiles.length == 1 || newFiles.length == 1)"
       [beeBackgroundImage]="imagePreviews[0]"
       class="compound-mode-image-container">
      <span class="preview-icon">
          <mat-icon svgIcon="image-search"></mat-icon>
      </span>
  </div>

  <div *ngIf="!preview && (myFiles.length == 1 || newFiles.length == 1)">
        <span *ngIf="newFiles.length == 1">
            {{ newFiles[0].name }}
        </span>
    <span *ngIf="myFiles.length == 1">
            {{ myFiles[0].name }}
        </span>
  </div>
  <div *ngIf="uploadEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile"
       class="compound-mode-drop-box-separator">- o -
  </div>

  <div *ngIf="uploadEnabled" class="compound-mode-actions">
    <label (uploadOutput)="checkOutput($event)" [attr.for]="id" [options]="options"
           [uploadInput]="uploadInput" class="compound-mode-drop-box" ngFileDrop>
      <ng-container *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile">
        <div class="compound-mode-drop-box-box">
          <mat-icon *ngIf="dropBoxIconEnabled" svgIcon="paperclip"></mat-icon>
          {{ dropBoxText }}
        </div>
      </ng-container>
    </label>

    <input (change)="openCrop($event)" *ngIf="cropEnabled" [id]="id" onclick="this.value = null"
           style="display: none"
           type="file"/>

    <input (uploadOutput)="onResetFiles($event)" *ngIf="!cropEnabled" [id]="id" [options]="options" [uploadInput]="uploadInput" multiple
           ngFileSelect onclick="this.value = null" style="display: none"
           type="file">
  </div>

  <div [ngClass]="{'full-width': myFiles.length == 0 && newFiles.length == 0 && !uploadingFile}"
       class="compound-mode-actions-container">
    <label [attr.for]="id" class="compound-mode-edit-action">
      <!--<mat-icon svgIcon="pencil" *ngIf="imagePreviews.length == 0"></mat-icon>-->
      <span *ngIf="noFilesLabelEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile"
            class="no-files-text-container">
        {{ noFilesLabel }}
      </span>
      <span *ngIf="myFiles.length == 1 || newFiles.length == 1 || uploadingFile">
        <mat-icon svgIcon="pencil"></mat-icon>
        {{ editButtonText }}
      </span>
    </label>

    <div (click)="deleteNewFile(newFiles[0], files[0])" *ngIf="newFiles.length == 1"
         class="compound-mode-delete-action">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>
    <div (click)="deleteFile(myFiles[0])" *ngIf="myFiles.length == 1" class="compound-mode-delete-action">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>
  </div>

  <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
    {{ recommendedDimensions }}
  </div>
  <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
    {{ maxFileSizeTip }}
  </div>
  <bracelit-preloader *ngIf="uploadingFile" [diameter]="20" [loadingText]="uploadingFileText">
  </bracelit-preloader>
</div>

<!-- Mobile mode (1 file) -->
<div *ngIf="displayMode == 'mobile'" class="mobile-mode-container">
  <div *ngIf="title" class="mobile-mode-title">
    {{ title }}
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </div>

  <label *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile" [attr.for]="id"
         class="mobile-mode-button-container">
    <mat-icon [svgIcon]="buttonIcon"></mat-icon>
    {{ inputText }}
  </label>

  <div (click)="openImagePreview(0)" *ngIf="preview && (myFiles.length == 1 || newFiles.length == 1)"
       [beeBackgroundImage]="imagePreviews[0]"
       class="mobile-mode-image-container">
      <span class="preview-icon">
          <mat-icon svgIcon="image-search"></mat-icon>
      </span>
  </div>

  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">

    <div *ngIf="fileTypeIcon && (myFiles.length == 1 || newFiles.length == 1)" fxFlex="40px">
      <span *ngIf="myFiles.length == 1" [ngSwitch]="myFiles[0].extension">
        <mat-icon *ngSwitchCase="'pdf'" svgIcon="icon-doc-pdf"></mat-icon>
        <mat-icon *ngSwitchCase="'xls'" svgIcon="icon-doc-xls"></mat-icon>
        <mat-icon *ngSwitchCase="'xlsx'" svgIcon="icon-doc-xlsx"></mat-icon>
        <mat-icon *ngSwitchCase="'csv'" svgIcon="icon-doc-csv"></mat-icon>
        <mat-icon *ngSwitchCase="'jpg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'jpeg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'gif'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'png'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'svg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'docx'" svgIcon="icon-doc-word"></mat-icon>
        <mat-icon *ngSwitchCase="'odt'" svgIcon="icon-doc-odt"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
      </span>

      <span *ngIf="newFiles.length == 1" [ngSwitch]="newFiles[0].extension">
        <mat-icon *ngSwitchCase="'pdf'" svgIcon="icon-doc-pdf"></mat-icon>
        <mat-icon *ngSwitchCase="'xls'" svgIcon="icon-doc-xls"></mat-icon>
        <mat-icon *ngSwitchCase="'xlsx'" svgIcon="icon-doc-xlsx"></mat-icon>
        <mat-icon *ngSwitchCase="'csv'" svgIcon="icon-doc-csv"></mat-icon>
        <mat-icon *ngSwitchCase="'jpg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'jpeg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'gif'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'png'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'svg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'docx'" svgIcon="icon-doc-word"></mat-icon>
        <mat-icon *ngSwitchCase="'odt'" svgIcon="icon-doc-odt"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
      </span>
    </div>

    <div fxFlex="calc(100% - 80px)" fxLayout="row wrap" fxLayoutAlign="center center">
      <div *ngIf="!preview && (myFiles.length == 1 || newFiles.length == 1)" fxFlex="100">
        <span *ngIf="newFiles.length == 1" class="name-container">
            {{ newFiles[0].name }}
        </span>
        <span *ngIf="myFiles.length == 1" class="name-container">
            {{ myFiles[0].name }}
        </span>
      </div>

      <div *ngIf="showSize && newFiles.length == 1" class="dimensions-tip" fxFlex="100">
        <span *ngIf="newFiles[0].size >= 10000">{{ newFiles[0].size / 1000000 | number: '1.2-2' }} MB</span>
        <span *ngIf="newFiles[0].size < 10000">{{ newFiles[0].size / 10000 | number: '1.2-2' }} KB</span>
        <span *ngIf="showPages">&nbsp;&middot;{{ newFiles[0].pages }} páginas</span>
      </div>

      <div *ngIf="showSize && myFiles.length == 1" class="dimensions-tip" fxFlex="100">
        <span *ngIf="myFiles[0].size >= 10000">{{ myFiles[0].size / 1000000 | number: '1.2-2' }} MB</span>
        <span *ngIf="myFiles[0].size < 10000">{{ myFiles[0].size / 10000 | number: '1.2-2' }} KB</span>
        <span *ngIf="showPages">&nbsp;&middot;{{ myFiles[0].pages }} páginas</span>
      </div>

      <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
        {{ recommendedDimensions }}
      </div>
      <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
        {{ maxFileSizeTip }}
      </div>
      <bracelit-preloader *ngIf="uploadingFile" [diameter]="20" [loadingText]="uploadingFileText">
      </bracelit-preloader>
    </div>

    <!--archivos rechazados-->
    <div *ngIf="rejectedFiles.length!=0 && myFiles.length == 0 && newFiles.length == 0" fxFlex="100">
        <span class="mobile-rejected-files-tip">
          {{rejectedFileError}}
        </span>
    </div>

    <div class="mobile-mode-actions-container" fxFlex="40px">
      <label [attr.for]="id" class="mobile-mode-edit-action">
        <!--<mat-icon svgIcon="pencil" *ngIf="imagePreviews.length == 0"></mat-icon>-->
        <span *ngIf="noFilesLabelEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile">
        {{ noFilesLabel }}
      </span>
        <!--<span *ngIf="myFiles.length == 1 || newFiles.length == 1 || uploadingFile">-->
        <!--<mat-icon svgIcon="pencil"></mat-icon>-->
        <!--{{editButtonText}}-->
        <!--</span>-->
      </label>

      <div (click)="deleteNewFile(newFiles[0], files[0])" *ngIf="newFiles.length == 1 && deleteEnabled"
           class="mobile-mode-delete-action">
        <mat-icon svgIcon="window-close"></mat-icon>
        <!--{{deleteButtonText}}-->
      </div>
      <div (click)="deleteFile(myFiles[0])" *ngIf="myFiles.length == 1 && deleteEnabled"
           class="mobile-mode-delete-action">
        <mat-icon svgIcon="window-close"></mat-icon>
        <!--{{deleteButtonText}}-->
      </div>

      <input (change)="openCrop($event)" *ngIf="cropEnabled" [id]="id" onclick="this.value = null"
             style="display: none"
             type="file"/>

      <input (uploadOutput)="onResetFiles($event)" *ngIf="!cropEnabled" [id]="id" [options]="options" [uploadInput]="uploadInput" multiple
             ngFileSelect onclick="this.value = null" style="display: none"
             type="file">
    </div>
  </div>
</div>
