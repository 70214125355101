import { MatStepperIntl } from '@angular/material/stepper';

/**
 * Spanish stepper intl
 * @returns {MatStepperIntl}
 */
export function getSpanishStepperIntl() {
  const intl = new MatStepperIntl();

  intl.optionalLabel = 'Opcional';

  return intl;
}
