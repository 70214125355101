import { FormControl } from '@angular/forms';

/**
 * Validates IBAN.
 */
export class BeeIBANValidator {

  /**
   * The validator.
   * @param control
   */
  static validator(control: FormControl) {
    if (control.value === '' || !control.value) {
      return null;
    }
    if (BeeIBANValidator.checkIBAN(control.value)) {
      return null;
    }

    return {invalidIBAN: true};
  }

  /**
   * Validates IBAN.
   * @param IBAN
   */
  static checkIBAN(IBAN) {
    // Limpiamos el numero de IBAN
    IBAN = IBAN.toUpperCase();  // Todo a Mayus
    IBAN = IBAN.trim(); // Quitamos blancos de principio y final.
    IBAN = IBAN.replace(/\s/g, '');  // Quitamos blancos del medio.
    IBAN = IBAN.replace(/\-/g, ''); // Quitamos los guiones.

    // Para obtener la letra del NIE solo hay que sustituir la X del principio por un 0 y la Y por un 1 dividir el número entre 23
    // Letra (X,Y,Z) seguida de número de 7 cifras y otra letra (digito de control). (NIE).
    if (IBAN.length > 34 || IBAN.length < 5) {
      return false;
    }

    const codigoPais = IBAN.substring(0, 2).toUpperCase();
    const digitoControl = IBAN.substring(2, 4);
    const numeroCuenta = IBAN.substring(4).toUpperCase();
    const numCuenta = numeroCuenta + codigoPais + '00';
    const Modulus = this.IBANCleaner(numCuenta);

    return 98 - this.IBANModule(Modulus, 97) === parseInt(digitoControl, 10);
  }

  /**
   * IBANCleaner, used to validate the IBAN.
   * @param IBAN
   * @constructor
   */
  static IBANCleaner(IBAN) {
    const lsLetras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (const letra of lsLetras) {
      const replacewith = lsLetras.search(letra) + 10;
      const replace = letra.toString();
      IBAN = this.replaceAll(IBAN, replace, replacewith.toString());
    }

    return IBAN;
  }

  /**
   * IBANModule, used to validate the IBAN.
   * @param sModulus
   * @param divisor
   * @constructor
   */
  static IBANModule(sModulus, divisor) {
    let iStart;
    let iEnde;
    let iErgebniss;
    let iRestTmp;
    let iBuffer;
    let iRest = '';

    iStart = 0;
    iEnde = 0;
    while (iEnde <= sModulus.length - 1) {
      iBuffer = parseInt(iRest + sModulus.substring(iStart, iEnde + 1), 10);

      if (iBuffer >= divisor) {
        iErgebniss = Math.floor(iBuffer / divisor);
        iRestTmp = iBuffer - iErgebniss * divisor;
        iRest = iRestTmp;

        iStart = iEnde + 1;
        iEnde = iStart;
      } else {

        iEnde = iEnde + 1;
      }
    }
    if (iStart <= sModulus.length) {
      iRest = iRest + sModulus.substring(iStart);
    }

    return parseInt(iRest, 10);
  }

  /**
   * ReplaceAll helper.
   * @param text
   * @param replace
   * @param replaceWith
   */
  static replaceAll(text, replace, replaceWith) {
    while (text.toString().includes(replace)) {
      text = text.toString().replace(replace, replaceWith);
    }

    return text;
  }

  static validatorCode(control: FormControl) {
    if (control.value === '' || !control.value) {
      return null;
    }
    if (BeeIBANValidator.checkCode(control.value)) {
      return null;
    }

    return {invalidCode: true};
  }

  static checkCode(code) {
    const code1 = code.toUpperCase();
    const code21 = localStorage.getItem('chinoCode').toUpperCase();
    const code22 = localStorage.getItem('associatedQrId').toUpperCase();
    if ((code1 === code21) || (code1 === code22)) {
      return true;
    }

    return false;
  }
}
