<div id="qr-scan-container" fxLayout="row wrap">
  <div class="back-button-container">
    <button *ngIf="backIcon || backText" mat-button type="button" id="qr-back-button"
            (click)="goToPreviousPage()">
      <mat-icon *ngIf="backIcon" [svgIcon]="backIcon"></mat-icon>
      <img *ngIf="backImage" [src]="backImage" [alt]="'back'">
      <span *ngIf="backText" [ngClass]="backTextClass" class="back-text">{{backText}}</span>
    </button>
  </div>

  <div class="titles-container" fxFlex="100" fxLayoutAlign="center center" fxLayout="row wrap"
       [ngStyle]="{'margin-top.px' : titleMarginTop}">
    <div *ngIf="title" id="title" fxFlex="100" fxLayoutAlign="center center" [ngClass]="titleClass">
      {{title}}
    </div>
    <div *ngIf="subTitle" id="subTitle" fxFlex="100" fxLayoutAlign="center center" [ngClass]="subTitleClass">
      {{subTitle}}
    </div>
  </div>

  <div *ngIf="!hasPermission" fxFlex="100" id="no-camera-permissions-container" fxLayout="row"
       fxLayoutAlign="center center">
    <div id="no-camera-permissions-background" fxLayout="row wrap" fxLayoutAlign="center center">
      <div id="no-camera-permissions-title" fxFlex="100">
        {{ 'scanQR.withoutPermission' | translate }}<span *ngIf="iosPlatform">&nbsp;{{ 'scanQR.safari' | translate }}</span>
      </div>
      <button style="font-weight: bold !important; font-size: 18px !important" mat-raised-button color="primary" type="button" (click)="askForCameraPermission()">
        {{ 'scanQR.camera' | translate }}
      </button>
    </div>
  </div>

  <div class="scan-container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
    <zxing-scanner #scanner
                   class="qr-scanner"
                   [autostart]="autoStart"
                   [torch]="torchEnabled"
                   [device]="deviceCurrent"
                   [formats]="formatsEnabled"
                   [tryHarder]="tryHarder"
                   (permissionResponse)="onHasPermission($event)"
                   (camerasFound)="onCamerasFound($event)"
                   (camerasNotFound)="onCamerasNotFound($event)"
                   (scanSuccess)="handleQrCodeResult($event)"
                   (torchCompatible)="onTorchCompatible($event)">
      <!-- (deviceChange)="onDeviceChange($event)" -->
    </zxing-scanner>

    <ng-container *ngIf="hasPermission && hasDevices && deviceCurrent">
      <div class="scan-opacity-one primary-background"
           [ngStyle]="{ 'height': scanFrameHeightPercentage ? 'calc(50% - ' + scanFrameHeightPercentage/2 +'%)' : 'calc(50% - ' + (scanFrameHeightPx/2 - verticalGap) + 'px)'}"></div>

      <div class="scan-frame-container"
           [ngStyle]="{'height': scanFrameHeightPercentage? scanFrameHeightPercentage + '%' : scanFrameHeightPx + 'px'}">
        <div class="scan-opacity-two primary-background"
             [ngStyle]="{ 'width': scanFrameWidthPercentage ? 'calc(50% - ' + scanFrameWidthPercentage/2 +'%)' : 'calc(50% - ' + scanFrameWidthPx/2 +'px)'}"></div>
        <div class="scan-frame"
             [ngStyle]="{'width': scanFrameWidthPercentage ? scanFrameWidthPercentage + '%' : scanFrameWidthPx + 'px'}">
          <div class="scan-line tertiary-background"></div>
          <div class="scan-aux-one">
            <div class="scan-aux-left"></div>
            <div class="scan-aux-center"></div>
            <div class="scan-aux-right"></div>
          </div>
          <div class="scan-aux-two"></div>
          <div class="scan-aux-three">
            <div class="scan-aux-left"></div>
            <div class="scan-aux-center"></div>
            <div class="scan-aux-right"></div>
          </div>
        </div>
        <div class="scan-opacity-two primary-background"
             [ngStyle]="{ 'width': scanFrameWidthPercentage ? 'calc(50% - ' + scanFrameWidthPercentage/2 +'%)' : 'calc(50% - ' + scanFrameWidthPx/2 +'px)'}">
        </div>
      </div>
      <div class="scan-opacity-one primary-background"
           [ngStyle]="{ 'height': scanFrameHeightPercentage ? 'calc(50% - ' + scanFrameHeightPercentage/2 +'%)' : 'calc(50% - ' + (scanFrameHeightPx/2 + verticalGap) +'px)'}">
      </div>
    </ng-container>
  </div>

  <div *ngIf="availableDevices.length > 1" class="device-select-container" fxFlex="100" fxLayout="row"
       fxLayoutAlign="center center">
    <mat-form-field [appearance]="appearance">
      <mat-select [value]="deviceSelected" (valueChange)="onDeviceSelectChange($event)">
        <mat-option value="">{{ 'scanQR.cameraSelected' | translate }}</mat-option>
        <mat-option *ngFor="let device of availableDevices" [value]="device.deviceId">{{device.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="logo || explanation" class="logo-and-explanation-container" fxFlex="100" fxLayoutAlign="center center"
       fxLayout="row wrap">
    <div class="qr-logo-container" *ngIf="logo">
      <img [src]="logo" alt="logo-container" id="qr-logo">
    </div>
    <div id="qr-explanation" *ngIf="explanation" [ngClass]="explanationClass">{{explanation}}</div>
  </div>
</div>
