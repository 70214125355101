import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Animation for the selector container (open/close)
 */
export const showSelectorStateTrigger = trigger('showSelectorState', [
  state('open', style({})),
  transition('* => open', [
    style({
      opacity: 0,
    }),
    animate('200ms ease-out')
  ]),
]);
