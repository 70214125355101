import { BeeModel } from '@shared/abstract/bee-model';

import { BillingData } from './billing-data';
import { SavitarFile } from './savitar-file';
import { User } from './user';
import { Zone } from './zone';

/**
 * Account model, have multiple billingData, users and can customize multiple options.
 */
export class Account extends BeeModel {
  /** The province id of the account. */
  provinceId?: string;
  /** The country id of the account. */
  countryId?: string;
  /** The name of the account. */
  name: string;
  /** The slug of the account. */
  slug: string;
  /** The nif of the account. */
  nif?: string;
  /** Checks if the account is activePolygon and its users can log in. */
  isActive: boolean;
  /** The city of the account. */
  city?: string;
  /** The address of the account. */
  address?: string;
  /** The zipCode of the account. */
  zipCode?: string;
  /** The website of the account. */
  website?: string;
  /** The phone of the account. */
  phone?: string;
  /** The fax of the account. */
  fax?: string;
  /** The email of the account. */
  email?: string;
  /** The facebookUrl of the account. */
  facebookUrl?: string;
  /** The twitterUrl of the account. */
  twitterUrl?: string;
  /** The youtubeUrl of the account. */
  youtubeUrl?: string;
  /** The instagramUrl of the account. */
  instagramUrl?: string;
  /** The selectedTheme of the account. */
  selectedTheme: string;
  /** Checks if the account has done the initial configuration. */
  initialConfiguration: boolean;
  /** The observations of the account. */
  observations?: string;
  /** The contactName of the account. */
  contactName?: string;
  /** The contactPhone of the account. */
  contactPhone?: string;

  /** The province of the account. */
  province?: Zone;
  /** The country of the account. */
  country?: Zone;
  /** The billingData of the account. */
  billingData?: BillingData[] = [];
  /** The users of the account. */
  users?: User[] = [];
  /** The files of the account. */
  files?: SavitarFile[] = [];
  /** The logo of the account. */
  logo?: SavitarFile[] = [];

  // Redentradas custom
  /** The points of sale of the account. */

  // pointsOfSale?: PointOfSale[];

  /**
   * Constructor
   * @param data
   */
  constructor(data: object) {
    super(data);
    if (data.hasOwnProperty('province') && data['province']) {
      this.province = new Zone(data['province']);
    }
    if (data.hasOwnProperty('country') && data['country']) {
      this.country = new Zone(data['country']);
    }
    if (data.hasOwnProperty('billingData') && data['billingData']) {
      this.billingData = [];
      for (const billingData of data['billingData']) {
        this.billingData.push(new BillingData(billingData));
      }
    }
    if (data.hasOwnProperty('users') && data['users']) {
      this.users = [];
      for (const user of data['users']) {
        this.users.push(new User(user));
      }
    }
    if (data.hasOwnProperty('files') && data['files']) {
      this.files = [];
      this.logo = [];
      for (const file of data['files']) {
        this.files.push(new SavitarFile(file));
        if (file.category === 'logo') {
          this.logo.push(new SavitarFile(file));
        }
      }
    }
    // Redentradas custom
    /*
    if (data.hasOwnProperty('pointsOfSale') && data['pointsOfSale']) {
      this.pointsOfSale = [];
      for (const pointOfSale of data['pointsOfSale']) {
        this.pointsOfSale.push(new PointOfSale(pointOfSale));
      }
    }
    /*
    this.logo = data.logo.map(file => new File(file));
    */
  }
}
