<div id="not-found-container">
  <img [src]="notFoundImageUrl" alt="404">

  <div fxFlex="100" id="not-found-text">
    <h2 *ngIf="notFoundText">
      {{notFoundText}}
    </h2>
    <p *ngIf="notFoundAdditionalText">
      {{notFoundAdditionalText}}
    </p>
  </div>

  <button (click)="goToPreviousPage()" class="primary-border" color="primary" id="bee-back-button" mat-button
        type="button">
  <span>Volver</span>
</button>
</div>
