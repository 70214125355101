<canvas #sigPad width="300" height="150" (mousedown)="onMouseDown($event)"
        (touchstart)="onMouseDown($event)"
        (touchmove)="onMouseMove($event)"
        (mousemove)="onMouseMove($event)"></canvas>
<br/>
<button (click)="clear()">Limpiar</button>
<button (click)="save()">Guardar</button>
<br/>
<img [src]="img">
<br/>
