<div class="container">
  <div style="color: #0F223E; font-size: 20px; font-weight: bold; margin-bottom: 0;">
    {{ 'qrDialog.code' | translate }}
  </div>

  <bee-qr-generator [qrData]="qrValue"></bee-qr-generator>

  <button class="white-background primary-border regular-20px-center-marine"
          mat-raised-button
          type="button"
          style="box-shadow: none; border: 1px solid; width: 200px;"
          (click)="close()" >
      {{ 'qrDialog.close' | translate }}
  </button>
</div>
