import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthenticationService } from '@shared/services';

@Component({
  selector: 'app-contact-qr',
  templateUrl: './contact-qr.component.html',
  styleUrls: ['./contact-qr.component.scss']
})
export class ContactQrComponent implements OnInit {
  myForm: FormGroup;
  clientId: string;
  userId: string;
  vCard: string;
  tel: string;

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              public dialogRef: MatDialogRef<ContactQrComponent>) {
    // this.myForm = this.formBuilder.group({
    //   email: [
    //     {value: '', disabled: true},
    //     [Validators.pattern('^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'),
    //       Validators.required
    //     ]
    //   ],
    //   placeholderPassword: [{value: '*******', disabled: true}],
    //   password: [null],
    //   name: ['', Validators.required],
    //   surname: ['', Validators.required],
    //   nif: ['', Validators.required],
    //   address: ['', Validators.required],
    //   phone: ['', Validators.required]

    // });
    this.clientId = this.authenticationService.clientId;
    this.userId = this.authenticationService.user.id;
    // this.myForm.controls['email'].setValue(this.authenticationService.user.email);
    // this.myForm.controls['name'].setValue(this.authenticationService.client.name);
    // this.myForm.controls['surname'].setValue(this.authenticationService.client.surname);
    // this.myForm.controls['nif'].setValue(this.authenticationService.client.nif);
    // this.myForm.controls['address'].setValue(this.authenticationService.client.address);
    // this.myForm.controls['phone'].setValue(this.authenticationService.client.phone);
   }

  ngOnInit(): void {

    const name = this.authenticationService.client.name,
    surname = this.authenticationService.client.surname,
    address = this.authenticationService.client.address,
    email = this.authenticationService.user.email;
    this.tel = this.authenticationService.client.phone;

    // this.vCard = `BEGIN:VCARD\?VERSION:4.0\?N:${surname};${name}\?EMAIL:${email}\?TEL;TYPE=work:${this.tel}\?END:VCARD`
    // "BEGIN:VCARD\r\nVERSION:2.1\r\nN:Satya;Dash;;;\r\nADR;DOM;PARCEL;HOME:;;****Mission Street;Cuttack City;Orissa;94014;INDIA.\r\nEMAIL;INTERNET:satya@domain.com\r\nTEL;CELL:22-122-4567\r\nTEL;CELL:133-156-3345\r\nEND:VCARD"
    this.vCard = `BEGIN:VCARD\r\nVERSION:4.0\r\nN:${surname};${name};;;\r\nEMAIL;INTERNET:${email}\r\nTEL;CELL:${this.tel}\r\nEND:VCARD`;

  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
