import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { PaymentDetailsModule } from '../payment-details/payment-details.module';
import { PaymentHistoryComponent } from './payment-history.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [PaymentHistoryComponent],
    imports: [
        SharedModule,
        PaymentDetailsModule,
        TranslateModule
    ]
})
export class PaymentHistoryModule {
}
