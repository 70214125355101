import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { BracelitEntryComponentsModule } from '../../../shared/components/entry-components/bracelit-entry-components.module';
import { SharedModule } from '../../../shared/shared.module';
import { EditEventUserComponent } from './edit-event-user/edit-event-user.component';
import { EventDetailsRoutingModule } from './event-details-routing.module';
import { EventDetailsComponent } from './event-details.component';
import { PaymentHistoryComponent } from './payments/payment-history/payment-history.component';
import { PaymentHistoryModule } from './payments/payment-history/payment-history.module';
import { QrDialogComponent } from './qr-dialog/qr-dialog.component';
import { IframeDialogComponent } from './iframe-dialog/iframe-dialog.component'; // la taja
import { ReadQrComponent } from './lead-scanner/read-qr/read-qr.component';
import { InfoComponent } from './info/info.component';
import { TranslateModule } from '@ngx-translate/core';
import { MoneyReturnComponent } from './money-return/money-return.component';
import { QuestionaryComponent } from './questionary/questionary.component';
import { QuizComponent } from './quiz/quiz.component';

@NgModule({
    declarations: [
        EventDetailsComponent,
        QrDialogComponent,
        IframeDialogComponent,
        EditEventUserComponent,
        ReadQrComponent,
        InfoComponent,
        MoneyReturnComponent,
        QuestionaryComponent,
        QuizComponent
    ],
    imports: [
        SharedModule, EventDetailsRoutingModule, MatRippleModule, BracelitEntryComponentsModule, PaymentHistoryModule, TranslateModule
    ]
})
export class EventDetailsModule {
}
