import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/angular-material';

import { BracelitRatingComponent } from './bracelit-rating.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    BracelitRatingComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [
    BracelitRatingComponent
  ]
})
export class BracelitRatingModule {
}
