<div class="header-container" style="padding: 40px 0 15px 0 !important; background-color: #FFFFFF;">
  <div class="left-icon-container">
    <button (click)="onGoBack()"  mat-button type="button" style="margin-top: 0px !important;">
      <mat-icon svgIcon="back" style="height: 38px; width: 38px;" class="marine-color"></mat-icon>
    </button>
  </div>
  <div class="central-container">
    <div *ngIf="data.type === 'privacity' && lang === 'es'" class="bold-20px-center-marine">Política de Privacidad</div>
    <div *ngIf="data.type === 'privacity' && lang === 'en'" class="bold-20px-center-marine">Privacy Policy</div>
    <div *ngIf="data.type === 'purchasing' && lang === 'es'" class="bold-20px-center-marine">Política de Compras</div>
    <div *ngIf="data.type === 'purchasing' && lang === 'en'" class="bold-20px-center-marine">Purchasing Policy</div>
  </div>
  <div class="right-icon-container" style="margin-right: 25px;">
  </div>
</div>
<div id="policy-container" *ngIf="data.type === 'privacity' && lang === 'es'">
  <h2 style="padding-top: 60px;">1.Tratamiento de datos de Carácter Personal</h2>
  <div>Los datos personales recogidos serán objeto de tratamiento por la entidad BRACELIT, S.L.U (en adelante BRACELIT), con CIF B19650704 y domicilio en Granada, Calle Puentezuelas, número 3, 2ºB, CP. 18002, Granada (España), en función de los servicios contratados, como Responsable del Tratamiento. Así mismo, informamos que BRACELIT se ajusta a las condiciones que establece el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016.
  De acuerdo a la mencionada legislación, BRACELIT ha nombrado un Delegado de Protección de Datos. Por tanto, para cualquier cuestión en esta materia, el cliente/usuario puede ponerse en contacto con el Delegado de Protección de Datos de BRACELIT mediante correo electrónico remitido a la siguiente dirección rgpd@bracelit.es
  <h3> - Finalidad del tratamiento de los datos personales</h3>
  BRACELIT, tratará los datos del usuario, de manera manual y/o automatizada, para las siguientes finalidades:
  Atender y gestionar adecuadamente las solicitudes de información que nos hayan remitido los usuarios a través de
  nuestros diferentes canales de comunicación.
  Gestionar la contratación de nuestros servicios de pago y gestión de eventos, así como la facturación y envío
  correspondiente.
  Remitir periódicamente (a través de correo electrónico, vía postal y/o SMS) comunicaciones, noticias e informes
  relacionados con nuestros servicios y actividades.
  Dar respuesta a las consultas y dudas planteadas en los distintos formularios de contacto.
  Gestionar adecuadamente los datos facilitados en los procesos de selección de personal ofertados por la empresa.
  Elaboración de perfiles estadísticos anónimos respecto de los datos facilitados.
  Gestionar adecuadamente la navegación a través del presente sitio web.
  Análisis estadísticos de acuerdo a las visitas al sitio web y el comportamiento de los usuarios en la misma.
  Dar cumplimiento a las obligaciones legalmente establecidas.
  </div>
  <h2>1.2 Legitimación</h2>
  <div>La legitimación para el tratamiento de los datos del usuario por parte de BRACELIT para atender y gestionar
    adecuadamente las solicitudes se encuentra en el consentimiento del interesado de éstos, solicitado en cada caso.
    Por su parte, la gestión de la contratación de servicios a través de la Web, facturación y envío correspondientes,
    está legitimada por la propia ejecución del contrato.
    El tratamiento de los datos facilitados a través del apartado “Contacto”, está legitimado por la propia resolución
    de la consulta planteada por el usuario.
    El tratamiento de los datos con el fin de remitir boletines electrónicos con noticias e informes relacionados con
    nuestros servicios y actividades, información comercial, la elaboración de informes estadísticos anónimos respecto a
    los datos facilitados para el uso del sistema de pago contratado con BRACELIT, se basan en el interés legítimo de la
    empresa para llevar a cabo dichos tratamientos de acuerdo con la normativa vigente.
    Los consentimientos obtenidos para las finalidades mencionadas son independientes por lo que el usuario podrá
    revocar uno o varios de ellos no afectando a los demás. Igualmente, BRACELIT podrá utilizar la información del
    usuario para el cumplimiento de las diferentes obligaciones legales.
  </div>
  <h2>1.3 Categoría de datos tratados</h2>
  <div>BRACELIT, trata la siguiente categoría de datos:
    Identificativos: nombre, apellidos, dirección postal, dirección de correo electrónico, código postal, teléfono,
    población, etc..
    Datos transaccionales:pagos, ingresos, transferencias, adeudos.
    Datos de salud: información relacionada con sintomatologías, alergias, enfermedades, incapacidades médicas,
    discapacidades, aparatos ortopédicos, así como el estado físico o anímico de la persona.
  </div>
  <h2> 1.4 Destinatarios principales</h2>
  <div> Sus datos personales podrán ser comunicados por parte de BRACELIT a:
    Los trabajadores de BRACELIT, para que éstos puedan realizar correctamente las labores encomendadas para desarrollar
    los servicios contratados.
    Administraciones Públicas, fuerzas y cuerpos de seguridad en virtud de lo establecido en la normativa.
    Los bancos y entidades financieras para el cobro de los servicios ofrecidos.
    Prestadores de servicios para gestionar el tratamiento de los datos descritos en la presente política de privacidad,
    de acuerdo con la normativa vigente en protección de datos. Dichos proveedores no tratarán sus datos para
    finalidades propias que no hayan sido previamente informadas por BRACELIT.
  </div>
  <h2>1.5 Plazo de conservación</h2>
  <div>Sus datos se conservarán durante un periodo no superior a cinco años o bien el tiempo que sea necesario para el
    desarrollo de las finalidades mencionadas, de acuerdo a los plazos de prescripción derivadas de la relación jurídica
    que funda el tratamiento, en los términos previsto por la legislación civil, mercantil, o tributaria que resulte de
    aplicación.
    Los datos recabados para la formalización y ejecución del contrato de prestación de servicios serán conservados
    durante la relación contractual, así como durante el periodo necesario para la formulación, ejercicio o defensa de
    reclamaciones, como mínimo cinco años.
    Los datos tratados para el envío de comunicaciones comerciales serán conservados hasta que el interesado no revoque
    el consentimiento y/o ejercite sus derechos de oposición y/o supresión.
  </div>
  <h2>2. Comunicaciones comerciales</h2>
  En alguno de los formularios electrónicos se le solicitará su consentimiento expreso para poderle remitir
  comunicaciones comerciales por cualquier medio, incluido el correo electrónico o medios de comunicación electrónica
  equivalentes, de conformidad con lo dispuesto en el artículo 21 LSSI.
  <h2>3. Medidas de seguridad</h2>
  <div> BRACELIT adopta los niveles de seguridad requeridos por el Real Decreto 1720/2007 y el Reglamento General de
    Protección de Datos (UE) 2016/679, adecuados a la naturaleza de los datos que son objeto de tratamiento en cada
    momento. BRACELIT tratará los datos de forma absolutamente confidencial y guardando el preceptivo deber de secreto
    respecto de los mismos, de conformidad con lo previsto en la normativa de aplicación, adoptando al efecto las
    medidas de índole técnica y organizativas necesarias que garanticen la seguridad de sus datos y eviten su
    alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de
    los datos almacenados y los riesgos a que están expuestos. No obstante lo anterior, la seguridad técnica en un medio
    como Internet no es inexpugnable y pueden existir actuaciones dolosas de terceros, si bien BRACELIT pone todos los
    medios a su alcance para evitar dichas actuaciones.
  </div>
  <h2>4. Política de uso de Cookies</h2>
  <div> En cumplimiento de lo dispuesto en el artículo 22.2 LSSI, BRACELIT únicamente utilizará dispositivos de
    almacenamiento y recuperación de datos (‘Cookies’) cuando el usuario haya dado su consentimiento previo para ello de
    acuerdo con lo que se indica en la ventana emergente del navegador del usuario cuando accede por primera vez al
    Portal y en los demás términos y condiciones que se indican en la Política de Cookies de BRACELIT que todo usuario
    debe conocer.
    <h2>5. Derechos ARCO-OP</h2>
    <div> El Usuario podrá ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición (‘Derechos ARCO’),
      Portabilidad y, en su caso al Olvido dirigiéndose en los términos legalmente previstos, debiendo para ello dirigir
      una comunicación mediante correo postal al Dpto. de Protección de Datos y Privacidad de BRACELIT ubicado en Calle
      Puentezuelas, número 3, 2ºB, CP. 18002, Granada (España), o enviando un mensaje de correo electrónico a la
      siguiente dirección: rgpd@bracelit.es, indicando en ambos casos su nombre, apellidos y copia de su documento
      acreditativo de identidad.
    </div>
    <h2>6. Cambios en la política de privacidad</h2>
    <div> BRACELIT se reserva el derecho de modificar la presente Política de Privacidad a fin de adaptarlas a las
      novedades legislativas que fueran aplicables al tratamiento. En dichos supuestos, anunciará en el Sitio Web los
      cambios introducidos con una antelación razonable a la de su puesta en práctica.
    </div>
  </div>
</div>
<div *ngIf="data.type === 'purchasing' && lang === 'es'" id="purchasing-container">
  <h2 style="padding-top: 60px;">1. Vendedor ({{contactName}})</h2>
  <div>{{contactName}}, con CIF {{nif}}. Domicilio en {{address}}, C.P {{zipCode}}, {{city}}. {{register}}. Para contactar con el vendedor se deberá enviar un correo electrónico a la dirección: soporte@bracelit.es
  </div>
  <h2>2. Comprador</h2>
  <div>El usuario mayor de edad que se registre, consignando sus datos personales en el formulario de compra, una vez aceptadas las condiciones generales de compra.</div>
  <h2>3. Condiciones cancelación, devolución y reembolso</h2>
  <div>No se podrá solicitar la devolución de las compras realizadas salvo en los casos en los que el promotor cancele o cambie de fecha un evento. Bracelit SLU no se hace responsable del importe de las devoluciones más allá del que indique el promotor del evento. Los usuarios podrán solicitar la devolución en las condiciones mendionadas durante enviando un correo electrónico a la dirección soporte@bracelit.es en los 30 días naturales siguientes a la fecha de la compra indicando la cuenta desde la que se realizó la compra, la fecha y el importe de la misma. El reembolso se realizará en la cuenta con la que se en los tres días siguientes a la solicitud a la misma cuenta desde la que se realizó la compra.</div>
  <h2>4. Pago Seguro</h2>
  <div>Las transacciones comerciales son realizadas en un entorno de servidor seguro bajo protocolo SSL (Secure Socket Layer) y todas las comunicaciones se transmiten encriptadas bajo un cifrado de 128 bits, que asegura el mayor nivel de protección a las comunicaciones.</div>
  <h2>5. Privacidad y Protección de Datos</h2>
  <div>Bracelit trata sus datos de carácter personal según los establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/y la Ley Orgánica 3/2018.</div>
</div>
<div id="policy-container" *ngIf="data.type === 'privacity' && lang === 'en'">
      <h2 style="padding-top: 60px;">1. Processing of personal data</h2>
      <div>The personal data collected will be processed by the entity BRACELIT, S.L. (hereinafter BRACELIT), with CIF B19650704 and address in Granada, Calle San Juan de Dios, number 5, 2ºB, CP. 18001, Granada (Spain), in accordance with the contracted services, as Data Controller. Likewise, we inform you that BRACELIT complies with the conditions established by Regulation (EU) 2016/679 of the European Parliament and of the Council, of 27 April 2016.
        In accordance with the aforementioned legislation, BRACELIT has appointed a Data Protection Officer. Therefore, for any question in this matter, the client/user may contact the BRACELIT Data Protection Delegate by e-mail sent to the following address rgpd@bracelit.es
      <h3> - Purpose of the processing of personal data</h3>
      BRACELIT, will process the user’s data, manually and/or automatically, for the following purposes:

      To attend to and adequately manage the requests for information sent to us by users through our different communication channels.
      To manage the contracting of our payment and event management services, as well as the corresponding invoicing and delivery.
      Periodically send (by e-mail, post and/or SMS) communications, news and reports related to our services and activities.
      Respond to queries and doubts raised in the various contact forms.
      Properly manage the data provided in the personnel selection processes offered by the company.
      The elaboration of anonymous statistical profiles with respect to the data provided.
      To properly manage navigation through this website.
      Statistical analysis according to visits to the website and user behaviour on the website.
      To comply with the legally established obligations.
    </div>
    <h2>1.2 Legitimation</h2>
    <div>The legitimacy for the processing of the user’s data by BRACELIT in order to attend to and properly manage the requests is to be found in the consent of the interested party, requested in each case.

      On the other hand, the management of the contracting of services through the website, invoicing and shipping, is legitimised by the execution of the contract itself.

      The processing of the data provided through the “Contact” section is legitimised by theresolution of the query posed by the user.

      he processing of data for the purpose of sending electronic newsletters with news and reports related to our services and activities, commercial information, the preparation of anonymous statistical reports regarding the data provided for the use of the payment system contracted with BRACELIT, are based on the egitimate interest of the company to carry out such processing in accordance with current legislation.

      The consents obtained for the aforementioned purposes are independent, so the user may revoke one or more of them without affecting the others. Likewise, BRACELIT may use the user’s information to comply with the different legal obligations.
    </div>
    <h2>1.3 Category of data processed</h2>
    <div>BRACELIT processes the following category of data:

      Identifiers: name, surname, postal address, e-mail address, postal code, telephone number, town, etc…
      Transactional data: payments, receipts, transfers, debits.
      Health data: information related to symptomatologies, allergies, diseases, medical incapacities, disabilities, orthopaedic devices, as well as the physical or mental state of the person.
    </div>
    <h2> 1.4 Main addressees</h2>
    <div>Your personal data may be communicated by BRACELIT to:

      BRACELIT’s employees, so that they can correctly carry out the tasks entrusted to them in order to develop the contracted services.
      Public administrations, security forces and bodies by virtue of the provisions of the regulations.
      Banks and financial institutions for the collection of the services offered.
      Service providers to manage the processing of the data described in this privacy policy, in accordance with current data protection regulations. These providers will not process your data for their own purposes that have not been previously informed by BRACELIT.
    </div>
    <h2>1.5 Conservation period</h2>
    <div>Your data will be kept for a period not exceeding five years or the time necessary for the development of the aforementioned purposes, according to the statute of limitations arising from the legal relationship that founds the treatment, in the terms provided by the civil, commercial, or tax legislation that may be applicable.

      The data collected for the formalisation and execution of the contract for the provision of services will be kept during the contractual relationship, as well as for the period necessary for the formulation, exercise or defence of claims, at least five years.

      The data processed for the sending of commercial communications will be kept until the interested party revokes consent and/or exercises their rights of opposition and/or deletion.
    </div>
    <h2>2. Commercial communications</h2>
    In some of the electronic forms you will be asked for your express consent to be able to send you commercial communications by any means, including e-mail or equivalent means of electronic communication, in accordance with the provisions of article 21 LSSI.
    <h2>3. Security measures</h2>
    <div>BRACELIT adopts the security levels required by Royal Decree 1720/2007 and the General Data Protection Regulation (EU) 2016/679, appropriate to the nature of the data being processed at all times. BRACELIT shall treat the data in an absolutely confidential manner and shall keep the mandatory duty of secrecy with respect to the same, in accordance with the provisions of the applicable regulations, adopting for this purpose the necessary technical and organisational measures to ensure the security of your data and prevent its alteration, loss, unauthorised processing or access, taking into account the state of technology, the nature of the data stored and the risks to which they are exposed. Notwithstanding the above, technical security in a medium such as the Internet is not impregnable and there may be fraudulent actions by third parties, although BRACELIT makes every effort to avoid such actions.
    </div>
    <h2>4. Cookie Policy</h2>
    <div>In compliance with the provisions of article 22.2 LSSI, BRACELIT shall only use data storage and recovery devices (‘Cookies’) when the user has given prior consent to do so in accordance with what is indicated in the pop-up window of the user’s browser when accessing the Portal for the first time and under the other terms and conditions indicated in BRACELIT’s Cookies Policy, of which all users should be aware.
      <h2>5. ARCO-OP rights</h2>
      <div>The User may exercise his/her rights of Access, Rectification, Cancellation and Opposition (‘ARCO Rights’), Portability and, if applicable, the right to be Forgotten, by contacting the Data Protection and Privacy Department of BRACELIT located at Calle San Juan de Dios, number 5, 2ºB, CP. 18001, Granada (Spain), or by sending an e-mail message to the following address: rgpd@bracelit.es, indicating in both cases his/her name, surname and a copy of his/her identity document.
      </div>
      <h2>6. Changes to the privacy policy</h2>
      <div>BRACELIT reserves the right to modify this Privacy Policy in order to adapt it to any new legislation that may be applicable to the processing. In such cases, the changes introduced shall be announced on the Website reasonably in advance of their implementation.
      </div>
    </div>
</div>
<div *ngIf="data.type === 'purchasing' && lang === 'en'" id="purchasing-container">
  <h2 style="padding-top: 60px;">1. Seller ({{contactName}})</h2>
  <div>{{contactName}}, CIF {{nif}}. Address at {{address}}, C.P {{zipCode}}, {{city}}. {{register}}. To contact the seller should send an email to the address: soporte@bracelit.es
  </div>
  <h2>2. Buyer</h2>
  <div>Users of legal age who register by entering their personal data in the purchase form, once they have accepted the general conditions of purchase.</div>
  <h2>3. Cancellation, return and refund conditions</h2>
  <div>No refunds can be requested for purchases made except in cases where the promoter cancels or reschedules an event. Bracelit SLU is not responsible for the amount of refunds beyond the amount indicated by the promoter of the event. Users may request a refund under the conditions mentioned above by sending an email to the address soporte@bracelit.es within 30 calendar days from the date of purchase indicating the account from which the purchase was made, the date and the amount of the purchase. The refund will be made to the account used within three days of the request to the same account from which the purchase was made.</div>
  <h2>4. Secure payment</h2>
  <div>Business transactions are conducted in a secure server environment under SSL (Secure Socket Layer) protocol and all communications are transmitted encrypted under 128-bit encryption, which ensures the highest level of communication protection.</div>
  <h2>5. Privacy and Data Protection</h2>
  <div>Bracelit processes your personal data in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/ and Organic Law 3/2018.</div>
</div>
