import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * SnackbarService, helper to create material snackbars.
 */
@Injectable()
export class SnackBarService {
  /**
   * Constructor.
   * @param snackBar
   */
  constructor(private snackBar: MatSnackBar) {
  }

  /**
   * Opens a snackbar.
   * @param message
   * @param action
   */
  openSnackBar(message: string, action: string = ((localStorage.getItem('lang') === 'es') ? 'Cerrar' : 'Close')) {
    this.snackBar.open(message, action, {
      duration: 9000,
      panelClass: 'snackbar'
    });
  }
}
