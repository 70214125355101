import { BeeModel } from '@shared/abstract/bee-model';

import { Zone } from './zone';

/**
 * BillingData. An account can have multiple BillingData.
 */
export class BillingData extends BeeModel {
  /** The id of the account. */
  accountId: string;
  /** The id of the province. */
  provinceId: string;
  /** The id of the country. */
  countryId?: string;
  /** The name of the billing data. */
  name: string;
  /** The nif of the billing data. */
  nif: string;
  /** The city of the billing data. */
  city: string;
  /** The address of the billing data. */
  address: string;
  /** The zipCode of the billing data. */
  zipCode: string;
  /** The phone of the billing data. */
  phone?: string;
  /** The email of the billing data. */
  email?: string;
  /** Controls if the billing data charges to account. */
  chargeToAccount: boolean;
  /** The iban of the billing data. */
  iban?: string;
  /** The bic of the billing data. */
  bic?: string;
  /** The observations of the billing data. */
  observations?: string;

  /** The province of the billing data. */
  province: Zone;
  /** The country of the billing data. */
  country?: Zone;

  /**
   * Constructor.
   * @param data
   */
  constructor(data: object) {
    super(data);
    if (data.hasOwnProperty('province') && data['province']) {
      this.province = new Zone(data['province']);
    }
    if (data.hasOwnProperty('country') && data['country']) {
      this.country = new Zone(data['country']);
    }
  }
}
