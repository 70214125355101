import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BeeImagePreviewComponent } from './bee-image-preview.component';

@NgModule({
  declarations: [
    BeeImagePreviewComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BeeImagePreviewComponent
  ]
})
export class BeeImagePreviewModule {
}
