import { Injectable } from '@angular/core';
import { BracelitEvent } from '../models/bracelit/bracelit-event';

@Injectable({
  providedIn: 'root'
})
export class EventConfigurationService {

  public event: BracelitEvent;


  constructor() { }

  setEvent(event: BracelitEvent) {
    this.event = event;
  }
}
