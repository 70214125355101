import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * BracelitRating, shows rating in stars/icon and enables a clickable rating system if rateMode is enabled.
 */
@Component({
  selector: 'bracelit-rating',
  templateUrl: './bracelit-rating.component.html',
  styleUrls: ['./bracelit-rating.component.scss']
})
export class BracelitRatingComponent implements OnInit {
  /**
   * The current rating.
   * @type {number}
   */
  @Input() rating: number;
  /**
   * The max rating.
   * @type {number}
   */
  @Input() maxRating = 5;
  /**
   * Icon of the full rate, icons as <mat-icon svgIcon="star-half"></mat-icon>
   * @type {string}
   */
  @Input() fullIcon = 'star';
  /**
   * Icon of the half rate, icons as <mat-icon svgIcon="star-half"></mat-icon>
   * @type {string}
   */
  @Input() halfIcon = 'star-half';
  /**
   * Icon of the empty rate, icons as <mat-icon svgIcon="star-half"></mat-icon>
   * @type {string}
   */
  @Input() emptyIcon = 'star-outline';
  /**
   * Full icon class. (SCSS)
   * @type {string}
   */
  @Input() fullIconClass = 'lighter-tertiary-color';
  /**
   * Half icon class. (SCSS)
   * @type {string}
   */
  @Input() halfIconClass = 'lighter-tertiary-color';
  /**
   * Empty icon class. (SCSS)
   * @type {string}
   */
  @Input() emptyIconClass = 'gray-color';
  /**
   * Extra iconsClass.
   * @type {string}
   */
  @Input() iconsClass = '';
  /**
   * Icon size.
   * @type {number}
   */
  @Input() iconsSize = 16;
  /**
   * Enables half ratings.
   * @type {boolean}
   */
  @Input() halfRatingsEnabled = false;
  /**
   * Enables active rating.
   * @type {boolean}
   */
  @Input() rateMode = false;
  /**
   * Emit the rating when is changed.
   * @type {EventEmitter<number>}
   */
  @Output() readonly newRating: EventEmitter<number> = new EventEmitter();
  /**
   * Full icons array.
   */
  fullIcons = [];
  /**
   * Half icons enabled.
   * @type {boolean}
   */
  halfIcons: boolean;
  /**
   * Empty icons array.
   */
  emptyIcons = [];

  /**
   * OnInit lifecycle, calculates the rating.
   */
  ngOnInit() {
    this.calculate();
  }

  /**
   * Calculate and draw the rating.
   */
  calculate() {
    this.getFullStars();
    this.getHalfStar();
    this.getEmptyStars();
  }

  /**
   * Return the full stars.
   */
  getFullStars() {
    this.fullIcons = [];
    let fullStar = 0;
    while (fullStar < Math.floor(this.rating)) {
      this.fullIcons.push(1);
      fullStar++;
    }
  }

  /**
   * Returns the half star. (last)
   */
  getHalfStar() {
    if (this.halfRatingsEnabled) {
      this.halfIcons = (this.rating - Math.floor(this.rating)) > 0;
    }
  }

  /**
   * Returns the empty stars.
   */
  getEmptyStars() {
    let emptyStar = this.maxRating - this.fullIcons.length;
    if (this.halfIcons) {
      emptyStar--;
    }
    this.emptyIcons = [];
    for (let i = 0; i < emptyStar; i++) {
      this.emptyIcons.push(1);
    }
  }

  /**
   * One click to change punctuation, if half stars are enabled 2 clicks needed, one to half point, two to full star.
   * @param index
   */
  onClickFullStar(index: number) {
    if (this.rateMode) {
      // Not + 1 for allowing to rate with 0 stars
      this.rating = index;
      this.calculate();
    }
    this.newRating.emit(this.rating);
  }

  /**
   * Clicks an empty star, performing the rate.
   * @param index
   */
  onClickEmptyStar(index: number) {
    if (this.rateMode) {
      if (this.rateMode) {
        if (this.halfRatingsEnabled) {
          if (this.halfIcons) {
            this.rating = this.fullIcons.length + 1 + index;
          } else {
            this.rating = this.fullIcons.length + 0.5 + index;
          }
        } else {
          this.rating = this.fullIcons.length + index + 1;
        }
        this.calculate();
      }
    }
    this.newRating.emit(this.rating);
  }

  /**
   * Clicks half star, performing the rate.
   */
  onClickHalfStar() {
    if (this.rateMode) {
      this.rating = this.fullIcons.length + 1;
      this.calculate();
    }
    this.newRating.emit(this.rating);
  }
}
