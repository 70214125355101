import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { WristbandsService } from '@shared/services/wristbands.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-recharge-wristband',
  templateUrl: './recharge-wristband.component.html',
  styleUrls: ['./recharge-wristband.component.scss']
})

export class RechargeWristbandComponent implements OnInit {
  voucherLabelMinus: string;
  voucherLabelMayus: string;
  coinNameMayus: string;
  coinNameMinus: string;
  eventId: string;
  public hasVouchers;
  domain: string;
  constructor(public dialogRef: MatDialogRef<RechargeWristbandComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public wristbandsService: WristbandsService) {
    this.domain = environment.server;
  }

  ngOnInit(): void {
    this.voucherLabelMinus = this.data.voucherLabelMinus;
    this.voucherLabelMayus = this.data.voucherLabelMayus;
    this.coinNameMinus = this.data.coinName;
    this.coinNameMayus = this.data.coinName.toUpperCase();
    this.hasVouchers = this.data.hasVouchers;
    this.eventId = this.data.eventId;
  }

  onBuyBracels(): void {
    localStorage.removeItem('voucherPurchaseData');
    this.dialogRef.close('bracels');
  }

  onRechargeVouchers(): void {
    /*
    if (this.wristbandsService.selectedWallet.money > 0) {
      this.dialogRef.close('bonos');
    } else {
      this.dialogRef.close('bracels');
    }
    */
    localStorage.removeItem('voucherPurchaseData');
    if (this.voucherLabelMinus === 'bonos') {
      this.dialogRef.close('bonos');
    } else {
      this.dialogRef.close('tickets');
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
