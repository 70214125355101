import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { SeeQrComponent } from './see-qr.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SeeQrComponent],
  imports: [
    SharedModule,
    TranslateModule
  ]
})
export class SeeQrModule {
}