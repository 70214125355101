import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService } from '@shared/services/api.service';

import { AuthenticationService } from './authentication.service';
import { WristbandsService } from './wristbands.service';

@Injectable()

export class ShoppingCartService {

  // Get y set al localStorage de los bonos que se pueden comprar en el evento en el que estamos (Voucher Blueprints)
  // Con esos voucher blueprints y sus cantidades (empiezan en 0) rellenamos el carrito al abrirlo, componente add-vouchers
  // OJO al terminar la compra con éxito ponemos a 0 las cantidades del carrito
  // Variable que me diga los elementos "pendientes" de pagar para poner chip en el header (+1 por cada bono, +1 si hay bracels que comprar)
  private buyingVoucher: boolean;
  pay: boolean = false;

  constructor(private _wristband_service: WristbandsService,
              private httpClient: HttpClient,
              private apiService: ApiService,
              private authService: AuthenticationService) {
  }

  private _payment_succesful = false;
  public ds_order: any;
  public stripeMoney:number;
  public paymentInfo:any;
  public buyingProduct:boolean;

  get payment_succesful(): boolean {
    return this._payment_succesful;
  }

  set payment_succesful(value: boolean) {
    this._payment_succesful = value;
  }

  private _bracels = 0;

  get bracels(): number {
    return this._bracels;
  }

  // var de los voucher blueprints seleccionados y cuantos de cada uno

  set bracels(value: number) {
    this._bracels = value;
  }

  private _vouchers = {};

  get vouchers(): {} {
    return this._vouchers;
  }

  set vouchers(value: {}) {
    this._vouchers = value;
  }

  private _totalVouchers = 0;

  get totalVouchers(): number {
    return this._totalVouchers;
  }

  set totalVouchers(value: number) {
    this._totalVouchers = value;
  }

  private _totalToPay = 0; // euros

  get totalToPay(): number {
    return this._totalToPay;
  }

  set totalToPay(value: number) {
    this._totalToPay = value;
  }

  private _bracelsEuros = 1; // habrá que setearlo en función del evento!

  get bracelsEuros(): number {
    return this._bracelsEuros;
  }

  set bracelsEuros(value: number) {
    this._bracelsEuros = value;
  }

  async generateRedsysPayment(ds_order: any, ds_order_signature: any, walletId: string, eventId: string,buyingVouchers:boolean) {
    if (localStorage.getItem('ds_order') === 'confirmed') {
      localStorage.removeItem('ds_order');
      localStorage.removeItem('ds_order_signature');

      return false;
    }

    localStorage.setItem('ds_order', 'confirmed');
    // const _commissionPercent = localStorage.getItem('commissionPercent');

    const resp = await this.apiService.put(`redsys/redsys-pay/confirm`, {
      Ds_MerchantParameters: ds_order,
      Ds_Signature: ds_order_signature,
      eventId: eventId,
      walletId: walletId,
      rechargeWithNoFixedCharge:buyingVouchers
    }).toPromise();

    // If backend response = payment successful
    this._payment_succesful = true;
    // CARGAMOS LO COMPRADO:
    if (this._bracels > 0) {
      this.chargeBracelsInWallet();
    } else if (Object.keys(this._vouchers).length === 0) {
      this.chargeVouchersInWallet();
    }

    return resp;
  }

  async generateStripePayment(walletId: string, eventId: string, totalEuros: number,payId:string,buyingVoucher:boolean) {
    const resp = this.apiService.put(`stripe/stripeConfirm`, {
      eventId: eventId,
      walletId: walletId,
      import: totalEuros,
      payId:payId,
      buyingVoucher:buyingVoucher

    }).toPromise();

    // If backend response = payment successful
    this._payment_succesful = true;
    // CARGAMOS LO COMPRADO:
    if (this._bracels > 0) {
      this.chargeBracelsInWallet();
    } else if (Object.keys(this._vouchers).length === 0) {
      this.chargeVouchersInWallet();
    }

    return resp;
  }

  accessRedsysPayment(params: any) {
    this.httpClient.post('https://sis.redsys.es/sis/realizarPago', params, {
      headers: new HttpHeaders({
        'Content-type': 'application / x-www-form-urlencoded',
      }),
      observe: 'response'
    }).subscribe(_response => {
    }, err => {
      console.error(err);
    });
  }

  async requestRedsysPayment(wristbandId: string,moneyImport: number, eventId: string, userId: string, saveCardToken: boolean, walletId: string,payMethod:string,payToken:string) {
    const urlOK =  window.location.href.split('?')[0] + '/resultado-pago';
    const urlKO = window.location.href.split('?')[0] + '/cancelar/resultado-pago';
    const localVoucherPurchaseData = JSON.parse(localStorage.getItem('voucherPurchaseData'));
    const localVoucherPurchaseWristband = JSON.parse(localStorage.getItem('voucherPurchaseWristband'));
    const resp = this.apiService.post('redsys/redsys-pay/request', {
      eventId: eventId,
      import: moneyImport,
      payToken: payToken,
      urlWebPaySuccess: urlOK,
      urlWebPayFail: urlKO,
      userId: userId,
      walletId: walletId,
      rechargeWithNoFixedCharge: this.buyingVoucher,
      rechargeOrder: this.buyingProduct,
      saveCardToken: saveCardToken,
      payMet:payMethod,
      dataVoucher: localVoucherPurchaseData,
      wristbandId:wristbandId
    }).toPromise();
    localStorage.setItem('bracelsImport', this._bracels + '');

    return resp;
  }

  async signAppleRequest(validationUrl: string,domain:string) {
    
    const resp = this.apiService.post('redsys/redsys-pay/signAppleRequest', {
      validationUrl:validationUrl,
      domain:domain
    }).toPromise();

    return resp;
  }

  requestStripePayment(result:any,money:number){
    localStorage.setItem('bracelsImport', this._bracels + '');
    localStorage.setItem('stripeOrderId', result.token.id);

    return  this.apiService.post('stripe/stripe',
    {stripeToken: result.token.id,
      amount: money});
  }

  async requestRedsysVoucherPayment(wristbandId: string, moneyImport: number, data: any, eventId: string, userId: string, saveCardToken: boolean, walletId: string, payMethod:string, googleToken:string=null) {
    localStorage.setItem('voucherPurchaseBracels', JSON.stringify(moneyImport));
    localStorage.setItem('voucherPurchaseData', JSON.stringify(data));
    localStorage.setItem('voucherPurchaseWristband', JSON.stringify(wristbandId));
    this.buyingVoucher = true;
    const resp = await this.requestRedsysPayment(wristbandId,moneyImport, eventId, userId, saveCardToken, walletId, payMethod, googleToken);

    return resp;
  }

  async requestRedsysProductPayment(wristbandId: string, moneyImport: number, data: any, eventId: string, userId: string, saveCardToken: boolean, walletId: string,payMethod:string,googleToken:string=null) {
    localStorage.setItem('productPurchaseBracels', JSON.stringify(moneyImport));
    localStorage.setItem('productPurchaseData', JSON.stringify(data));
    localStorage.setItem('productPurchaseWristband', JSON.stringify(wristbandId));
    this.buyingProduct = true;
    const resp = await this.requestRedsysPayment(wristbandId,moneyImport, eventId, userId, saveCardToken, walletId,payMethod,googleToken);

    return resp;
  }

  requestStripeVoucherPayment(result: any, money: number, data: any,wristbandId: string,) {
    const resp = this.requestStripePayment(result, money)
    localStorage.setItem('voucherPurchaseBracels', JSON.stringify(money));
    localStorage.setItem('voucherPurchaseData', JSON.stringify(data));
    localStorage.setItem('voucherPurchaseWristband', JSON.stringify(wristbandId));

    return resp;
  }

  requestStripeProductsPayment(result: any, money: number, data: any,wristbandId: string,) {

    const resp = this.requestStripePayment(result, money)
    localStorage.setItem('productPurchaseBracels', JSON.stringify(money));
    localStorage.setItem('productPurchaseData', JSON.stringify(data));
    localStorage.setItem('productPurchaseWristband', JSON.stringify(wristbandId));

    return resp;
  }

  async cancelRedsysPayment(ds_order) {
    return this.apiService.put(`redsys/redsys-pay/cancel`, {ds_order}).toPromise();
  }

  async eventIncludeCommission(eventId) {
    return this.apiService.get(`clients/${this.authService.clientId}/events/${eventId}/include-commission`).toPromise();
  }
  
  async userHasCardToken() {
    return this.apiService.get(`clients/${this.authService.clientId}/checkCardToken`).toPromise();
  }
  
  async deleteCardToken() {
    return this.apiService.delete(`clients/${this.authService.clientId}/deleteCardToken`).toPromise();
  }

  /**
   *
   */
  chargeVouchersInWallet() {
    // POST PARA CREAR LOS VOUCHERS
    // PATCH PARA ASOCIAR LOS VOUCHERS AL WALLET
    this.resetValues();
  }

  /**
   *
   */
  chargeBracelsInWallet() {
    // POST PARA AUMENTAR LOS BRACELS DE LA WALLET
    // this.apiService.post();
    this.resetValues();
  }

  /**
   * Method callet when a payment results succesfull reseting the cart
   */
  resetValues() {
    this._payment_succesful = false;
    this._bracels = 0;
    this._vouchers = {};
    this._totalVouchers = 0;
  }

  setPayFlag(flag: boolean){
    this.pay = flag
  }

  getPayFlag(){
    return this.pay
  }

}
