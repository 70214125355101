import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, AuthenticationService, SnackBarService } from '@shared/services';
import { EventUser } from '@shared/models/bracelit/event-user';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  myForm: FormGroup;
  accountId: string;
  eventId: string;
  userId: string;
  loading = true;
  eventForm: any[] = [];
  eventUser: EventUser;
  message: string;
  eventName: string;
  checkboxValue: any;
  foto_ganadora: any;
  boton_deshabilitado = true;
  saved_results_photo: string;
  loadIframe = false;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<QuizComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.eventId = data.eventId;
    this.myForm = new FormGroup({});
}

  async ngOnInit() {
    if (this.eventId !== 'fce88cca-0a62-4081-b364-1f9e6bef9fcb') {
      this.accountId = JSON.parse(localStorage.getItem('accountId'));
      this.userId = this.authenticationService.user.id;
      const clientId = this.authenticationService.client.id;
      const url = `clients/${clientId}/events/${this.eventId}/event-user`;
      this.eventUser = await this.apiService.get(url).toPromise();
      if (this.eventUser.registerExtraFields !== null) {
        let extraFields = JSON.parse(this.eventUser.registerExtraFields);
        if (extraFields.photo !== undefined) {
          this.saved_results_photo = extraFields.photo
          this.checkedOnInit(this.saved_results_photo)
        } else {
          this.saved_results_photo = '';
        }
      } else {
        this.saved_results_photo = '';
      }
    }
  }

  checkedOnClick(event: any) {
    const checkboxes = document.getElementsByClassName('checkoption');
    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes.item(i) as HTMLInputElement;
      checkbox.checked = false;
    }
    const target = event.target as HTMLInputElement;
    target.checked = true;
    this.foto_ganadora = event.target.name
    this.boton_deshabilitado = false
  }

  checkedOnInit(selected_photo){
    const checkboxes = document.getElementsByClassName('checkoption');
    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes.item(i) as HTMLInputElement;
      if(checkbox.name === selected_photo) {
        checkbox.checked = true;
        this.boton_deshabilitado = false
      } else {
        checkbox.checked = false;
      }
    }
  }

  sendQuiz() {
    this.apiService
        .post(`accounts/${this.accountId}/events/${this.eventId}/users/${this.userId}/actionUpdateExtraFields`, {action: 'photo', form: this.foto_ganadora})
        .subscribe(_data => {
          this.snackBarService.openSnackBar('Respuesta enviada correctamente.');
          this.dialogRef.close();
        }, err => {
          console.error(err);
        });
  }

  iframeLoaded() {
    this.loadIframe = true;
  }

  // FESTIVAL DE LAS IDEAS
  onClose(): void {
    this.dialogRef.close();
  }
  
  goContinue() {
    this.dialogRef.close(true);
  }
}
