import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { InfoUserFormComponent } from './info-user-form.component';
import { WristbandsService } from '@app/shared/services/wristbands.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InfoUserFormComponent],
  imports: [
    SharedModule,
    TranslateModule,
    NgbModule
  ]
})
export class InfoUserFormModule {
}
