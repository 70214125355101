import { OverlayContainer } from '@angular/cdk/overlay';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

/**
 * DEFAULT_THEME InjectionToken, provides the default theme. Default is 'bee-blue-theme'.
 *
 * @example
 *
 * providers: [
 *    ThemeService,
 *    {provide: DEFAULT_THEME, useValue: 'bee-green-theme'},
 * ...
 * ]
 */
export const DEFAULT_THEME: InjectionToken<string> = new InjectionToken<string>('The default theme');

/**
 * ThemeService, changes the selected theme.
 */
@Injectable()
export class ThemeService {
  private default = true;

  /**
   * Constructor.
   * @param defaultTheme The default theme of the app
   * @param overlayContainer
   */
  constructor(private overlayContainer: OverlayContainer,
              @Optional() @Inject(DEFAULT_THEME) private defaultTheme: string) {
    (defaultTheme) ? this._selectedTheme = defaultTheme : this._selectedTheme = 'bracelit-theme';
  }

  /**
   * Selected theme. Default is 'bee-blue-theme'.
   * @type {string}
   */
  _selectedTheme: string;
  is_reading: boolean;

  /**
   * Gets the selected theme.
   * @returns {string}
   */
  get selectedTheme(): string {
    if (localStorage.getItem('accountTheme') !== null) {
      if (localStorage.getItem('accountTheme').split('"')[1] !== undefined) {
        this._selectedTheme = localStorage.getItem('accountTheme').split('"')[1];
      } else {
        this._selectedTheme = localStorage.getItem('accountTheme');
      }
      if (this._selectedTheme !== 'bracelit-theme') {
        this.default = false;
      }
    }
    this.overlayContainer.getContainerElement().classList.add(this._selectedTheme);

    return this._selectedTheme;
  }

  get checkDefault(): boolean {
    return this.default;
  }


  /**
   * Sets the selected theme, if account is available, updates the selected theme in the BD.
   * @param value
   */
  selectTheme(value: string) {
    this.overlayContainer.getContainerElement().classList.remove(this._selectedTheme);
    this._selectedTheme = value;
    this.overlayContainer.getContainerElement().classList.add(this._selectedTheme);
  }

  getIsReading() {
    return this.is_reading;
  }

  setIsReading(value: boolean) {
    this.is_reading = value;
  }
}