import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { subElementStateTrigger } from './animations';
import { BracelitSidebarElement } from './bracelit-sidebar-element';

/**
 * BracelitSidebarElementComponent, an element inside the BracelitSidebarComponent.
 */
@Component({
  selector: 'bracelit-sidebar-element',
  templateUrl: './bracelit-sidebar-element.component.html',
  styleUrls: ['./bracelit-sidebar-element.component.scss'],
  animations: [subElementStateTrigger],
})
export class BracelitSidebarElementComponent implements OnInit, OnChanges {
  @Input() sidebarElement: BracelitSidebarElement;
  @Input() fullWidth = false;
  @Input() subElementsActive = false;
  @Input() imageIcon = false;
  @Input() contentColor: string;
  @Input() subElementsNumber = 3;
  @Input() customElementClass: string;

  @Output() readonly headerClicked = new EventEmitter();
  @Output() readonly elementClickedCloseSidebar = new EventEmitter();

  subElementsHeight = 50;
  subElementsTotalHeight = 150;
  loaded = false;
  elementClasses = {};

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.sidebarElement.subElementsTotalHeight) {
      this.subElementsTotalHeight = this.sidebarElement.subElementsTotalHeight;
    } else {
      this.subElementsTotalHeight = 50 * this.subElementsNumber;
    }
    this.refreshSubElementsHeight();
    if (this.sidebarElement.imageIcon) {
      this.imageIcon = this.sidebarElement.imageIcon;
    }
    if (this.sidebarElement.imageIcon) {
      this.imageIcon = this.sidebarElement.imageIcon;
    }
    this.loaded = true;
    this.elementClasses = {
      disabled: this.sidebarElement.isDisabled
    };
    if (this.customElementClass) {
      this.elementClasses[this.customElementClass] = this.customElementClass;
    }
  }

  ngOnChanges() {
   this.refreshSubElementsHeight();
  }

  emitHeaderClicked(result: boolean) {
    if (!this.sidebarElement.isDisabled) {
      this.subElementsActive = result;
      this.headerClicked.emit(result);

      if (!this.subElementsActive) {
        this.subElementsHeight = 50;
      } else {
        this.subElementsNumber = this.sidebarElement.subElements.length;
        this.subElementsHeight = 50 * (this.subElementsNumber + 1);

        // Limiting the number of subelements to 4
        // The height (tittle  50px + subelements 4*50px + 30px to show that there is scroll if there are more than 4 subelements)
        if (this.subElementsHeight > this.subElementsTotalHeight) {
          this.subElementsHeight = 50 * this.subElementsNumber + 75;
        }
      }
    }
  }

  onClickElement(subElement: BracelitSidebarElement = null) {
    if (subElement) {
      if (subElement.collapseOnClick) {
        this.elementClickedCloseSidebar.emit();
      }
    } else {
      if (this.sidebarElement.collapseOnClick) {
        this.elementClickedCloseSidebar.emit();
      }
    }
  }

  refreshSubElementsHeight() {
    this.subElementsNumber = this.sidebarElement.subElements.length;
    if (this.subElementsActive && this.subElementsNumber > 1) {
      this.subElementsHeight = 50 * (this.subElementsNumber + 1);
    } else {
      this.subElementsHeight = 50;
    }
    // Apply the subElements total limit height
    if (this.subElementsHeight > this.subElementsTotalHeight) {
      this.subElementsHeight = 50 * this.subElementsNumber + 75;
    }

    this.subElementsNumber = this.sidebarElement.subElements.length;
    if (this.subElementsActive && this.subElementsNumber > 1) {
      this.subElementsHeight = 50 * (this.subElementsNumber + 1);
    } else {
      this.subElementsHeight = 50;
    }
    // Apply the subElements total limit height
    if (this.subElementsHeight > this.subElementsTotalHeight) {
      this.subElementsHeight = 50 * this.subElementsNumber + 75;
    }
  }
}
