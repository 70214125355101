import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxUploaderModule } from 'ngx-uploader';

import { MaterialModule } from '@shared/angular-material';

import { BeeDirectivesModule } from '@bee-components/common/bee-directives/bee-directives.module';
import { BracelitPreloaderModule } from '@bee-components/common/bracelit-preloader';

import { BeeCropperComponent } from './bee-cropper/bee-cropper.component';
import { BeeFileUpLoaderComponent } from './bee-file-uploader.component';

@NgModule({
  declarations: [
    BeeFileUpLoaderComponent,
    BeeCropperComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxUploaderModule,
    ImageCropperModule,
    BracelitPreloaderModule,
    BeeDirectivesModule,
    FlexLayoutModule
  ],
  exports: [
    BeeFileUpLoaderComponent,
    BeeCropperComponent,
    NgxUploaderModule,
    ImageCropperModule,
  ]
})
export class BeeFileUploaderModule {
}
