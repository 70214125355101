<div class="header-container" style="padding: 40px 0 15px 0 !important; background-color: #FFFFFF;">
  <div class="left-icon-container">
    <button (click)="onGoBack()" mat-button type="button" style="margin-top: 0px !important;">
      <mat-icon svgIcon="back" style="height: 38px; width: 38px;" class="marine-color"></mat-icon>
    </button>
  </div>
  <div class="central-container">
    <div *ngIf="lang === 'es'" class="bold-20px-center-marine">Aviso legal</div>
    <div *ngIf="lang === 'en'" class="bold-20px-center-marine">Legal notice</div>
  </div>
  <div class="right-icon-container" style="margin-right: 25px;">
  </div>
</div>
<bracelit-preloader *ngIf="loading" [absoluteCentered]="true"></bracelit-preloader>
<div *ngIf="!loading && lang === 'es'" id="policy-container">
    <h2 style="padding-top: 60px !important;">1. Información Legal y aceptación</h2>
    <div>
        Las presentes disposiciones regulan el uso del servicio del portal de Internet (en adelante, el ‘Portal’) que BRACELIT, S.L.U (en adelante, BRACELIT) pone a disposición de los usuarios de Internet.
        {{contactName}}, con domicilio social en {{address}}, CP. {{zipCode}}, {{city}}, y C.I.F. {{nif}}{{register}} Teléfono 611015551, Email: contacto@bracelit.es.
        El acceso a la página web es gratuito salvo en lo relativo al coste de la conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por los usuarios. Determinados servicios son exclusivos para nuestros clientes y su acceso se encuentra restringido. Los servicios de BRACELIT, con carácter general tienen el precio que se indique en la correspondiente propuesta de contratación y, en ningún caso se ofrecen o prestan a través del servicio del Portal.
        La utilización del Portal atribuye la condición de usuario del Portal (en adelante, el ‘Usuario’) e implica la aceptación de todas las condiciones incluidas en este Aviso Legal. La prestación del servicio del Portal tiene una duración limitada al momento en el que el Usuario se encuentre conectado al Portal o a alguno de los servicios que a través del mismo se facilitan. Por tanto, el Usuario debe leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar el Portal, ya que éste y sus condiciones de uso recogidas en el presente Aviso Legal pueden sufrir modificaciones.
        Algunos servicios del Portal accesibles para los usuarios de Internet o exclusivos para los clientes de BRACELIT, pueden estar sometidos a condiciones particulares, reglamentos e instrucciones que, en su caso, sustituyen, completan y/o modifican el presente Aviso Legal y que deberán ser aceptadas por el Usuario antes de iniciarse la prestación del servicio correspondiente.
        Para dar cumplimiento a lo establecido en la Ley 34/2002
        de 11 de Julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE), el uso de la información del portal www.bracelit.es implica la aceptación de las siguientes condiciones de uso.
    </div>
    <h2>2.	Utilización de la información contenida en el Portal</h2>
    <div>
        BRACELIT, facilita la consulta libre y gratuita de la información contenida en el Portal, permitiendo la copia y distribución de páginas siempre que se citen la fuente y la fecha en la que se ha realizado la copia, no se manipulen ni alteren los contenidos, no se use directamente con fines comerciales y no se utilice por entidades con el mismo objeto social.
        Los textos que se ofrecen en el Portal tienen carácter meramente informativo, es decir, carecen de validez jurídica. El acceso al Portal, así como el uso que pueda hacerse de la información que contiene, son de la exclusiva responsabilidad del usuario. BRACELIT no se responsabilizará de ninguna consecuencia, daño o perjuicio que pudieran derivarse del mismo.
    </div>
    <h2>3.	Calidad de la información</h2>
    <div>
        BRACELIT utilizará todos los recursos disponibles a su alcance para evitar errores y, en su caso, repararlos o actualizarlos lo antes posible, no pudiendo garantizar su inexistencia ni que el contenido de la información se encuentre permanentemente actualizado.
    </div>
    <h2>4.	Disponibilidad de la información</h2>
    <div>
        BRACELIT no puede garantizar plenamente el funcionamiento de la red de comunicaciones y por tanto no asume responsabilidad alguna sobre la disponibilidad de su servidor. El servidor podrá ser desconectado sin previo aviso. Se harán todos los esfuerzos para que el impacto por tareas de mantenimiento sea el mínimo posible. No obstante, BRACELIT no se responsabiliza de ningún daño o perjuicio sufrido por el usuario que se derive del acceso a este servidor o del uso de información o aplicaciones en él contenidos.
    </div>
    <h2>5.	Establecimientos de hiperenlaces</h2>
   <div>
    En el Portal se han incluido enlaces a páginas de sitios web de terceros (“links”), la mayor parte a páginas de Internet de otras Administraciones Públicas, que se han considerado de interés para los usuarios. No obstante, BRACELIT no asume ninguna responsabilidad derivada de la conexión o contenidos de los enlaces de terceros a los que se hace referencia en el Portal.
    </div>
    <h2>6. Enlaces al portal</h2>
    <div>
        El usuario de Internet que quiera introducir enlaces desde sus propias páginas web al Portal deberá cumplir con las condiciones que se detallan a continuación sin que el desconocimiento de las mismas evite las responsabilidades derivadas de la Ley:
        1.	El enlace únicamente vinculará con la home page o página principal del Portal pero no podrá reproducirla de ninguna forma (inline links, copia de los textos, gráficos, etc).
        2.	Quedará en todo caso prohibido, de acuerdo con la legislación aplicable y vigente en cada momento, establecer frames o marcos de cualquier tipo que envuelvan al Portal o permitan la visualización de los Contenidos a través de direcciones de Internet distintas a las del Portal y, en cualquier caso, cuando se visualicen conjuntamente con contenidos ajenos al Portal de forma que: (I) produzca, o pueda producir, error, confusión o engaño en los usuarios sobre la verdadera procedencia del servicio o Contenidos; (II) suponga un acto de comparación o imitación desleal; (III) sirva para aprovechar la reputación de la marca y prestigio de BRACELIT; o (IV) de cualquier otra forma resulte prohibido por la legislación vigente.
        3.	No se realizarán desde la página que introduce el enlace ningún tipo de manifestación falsa, inexacta o incorrecta sobre BRACELIT, sus socios, empleados, clientes o sobre la calidad de los servicios que presta.
        4.	En ningún caso, se expresará en la página donde se ubique el enlace que BRACELIT ha prestado su consentimiento para la inserción del enlace o que de otra forma patrocina, colabora, verifica o supervisa los servicios del remitente.
        5.	Está prohibida la utilización de cualquier marca denominativa, gráfica o mixta o cualquier otro signo distintivo de BRACELIT dentro de la página del remitente salvo en los casos permitidos por la ley o expresamente autorizados por BRACELIT y siempre que se permita, en estos casos, un enlace directo con el Portal en la forma establecida en esta cláusula
        6.	La página que establezca el enlace deberá cumplir fielmente con la ley y no podrá en ningún caso disponer o enlazar con contenidos propios o de terceros que: (I) sean ilícitos, nocivos o contrarios a la moral y a las buenas costumbres (pornográficos, violentos, racistas, etc.); (II) induzcan o puedan inducir en el Usuario la falsa concepción de que BRACELIT suscribe, respalda, se adhiere o de cualquier manera apoya, las ideas, manifestaciones o expresiones, lícitas o ilícitas, del remitente; (III) resulten inapropiados o no pertinentes con la actividad de BRACELIT en atención al lugar, contenidos y temática de la página web del remitente.
    </div>
    <h2>7.	Propiedad intelectual</h2>
    <div>
        Los derechos de propiedad intelectual de este Portal de Internet, su diseño gráfico y los códigos que contiene son titularidad de BRACELIT, a no ser que se indique una titularidad diferente. La reproducción, distribución, comercialización o transformación no autorizadas de estas obras constituye una infracción de los derechos de propiedad intelectual de BRACELIT. Igualmente, todas las marcas o signos distintivos de cualquier clase contenidos en el portal están protegidos por Ley.
        La utilización no autorizada de la información contenida en el Portal, así como los perjuicios ocasionados en los derechos de propiedad intelectual e industrial de BRACELIT pueden dar lugar al ejercicio de las acciones que legalmente correspondan y, si procede, a las responsabilidades que de dicho ejercicio se deriven.
    </div>
    <h2>8. Legislación</h2>
    <div>
        El presente Aviso Legal se rige en todos y cada uno de sus extremos por la ley española. BRACELIT y el usuario, con renuncia expresa a cualquier otro fuero, se someten al de los juzgados y tribunales de Granada, para cualquier controversia que pudiera derivarse del acceso a esta web.
    </div>
</div>
<div *ngIf="!loading && lang === 'en'" id="policy-container">
  <h2 style="padding-top: 60px !important;">1. Legal information and acceptance</h2>
  <div>
    These provisions regulate the use of the Internet portal service (hereinafter, the 'Portal') that BRACELIT, SLU (hereinafter, BRACELIT) makes available to Internet users.      BRACELIT, con domicilio social en Calle Puentezuelas, número 3, 2ºB, CP. 18002, Granada (España), y C.I.F. B19650704, está inscrita en el Registro Mercantil de Granada, Tomo 1640, Folio 195, Hoja GR-50633. Teléfono 611015551, Email: contacto@bracelit.es.
    {{contactName}}, with registered office at {{address}} CP. {{zipCode}}, {{city}}, and CIF {{nif}}{{register}} Telephone 611015551, Email: contacto@bracelit.es.
    Access to the website is free except for the cost of the connection through the telecommunications network provided by the access provider contracted by the users. Certain services are exclusive to our clients and their access is restricted. BRACELIT services, in general, have the price indicated in the corresponding contracting proposal and, in no case are they offered or provided through the Portal service.
    The use of the Portal attributes the condition of user of the Portal (hereinafter, the 'User') and implies the acceptance of all the conditions included in this Legal Notice. The provision of the Portal service has a limited duration at the moment in which the User is connected to the Portal or to any of the services that are provided through it. Therefore, the User must carefully read this Legal Notice on each of the occasions in which he intends to use the Portal, since it and its conditions of use contained in this Legal Notice may be modified.
    Some Portal services accessible to Internet users or exclusive to BRACELIT clients, may be subject to particular conditions, regulations and instructions that, where appropriate, replace, complete and/or modify this Legal Notice and that must be accepted. by the User before the provision of the corresponding service begins.
    To comply with the provisions of Law 34/2002 of July 11, on Services of the Information Society and Electronic Commerce (LSSICE), the use of the information on the www.bracelit.es portal implies acceptance of the following conditions of use.
    Bracelit does not use spamming techniques and will only process the data that the User and/or Organizers provide through the electronic forms enabled on any of the Wegow Technologies SL Websites or email messages together with the marking of the corresponding boxes. The treatment of personal data, as well as the sending of commercial communications made by electronic means, are in accordance with the data protection regulations in force at all times and with Law 34/2002, of July 11, on information services. Information Society and Electronic Commerce.
  </div>
  <h2>2.	Use of the information contained in the Portal</h2>
  <div>
    BRACELIT facilitates the free consultation of the information contained in the Portal, allowing the copying and distribution of pages as long as the source and the date on which the copy was made are cited, the contents are not manipulated or altered, no is used directly for commercial purposes and is not used by entities with the same corporate purpose.
    The texts offered on the Portal are merely informative, that is, they lack legal validity. Access to the Portal, as well as the use that may be made of the information it contains, are the sole responsibility of the user. BRACELIT will not be responsible for any consequence, damage or harm that may derive from it.
  </div>
  <h2>3.	Quality of the information</h2>
  <div>
    BRACELIT will use all available resources at its disposal to avoid errors and, where appropriate, repair or update them as soon as possible, and cannot guarantee their non-existence or that the content of the information is permanently updated.
    <h2>4.	Information availability</h2>
  <div>
    BRACELIT cannot fully guarantee the operation of the communications network and therefore does not assume any responsibility for the availability of its server. The server may be disconnected without prior notice. Every effort will be made to minimize the impact of maintenance tasks. However, BRACELIT is not responsible for any loss or damage suffered by the user that derives from access to this server or from the use of information or applications contained therein.
  </div>
  <h2>5.	Establishments of hyperlinks</h2>
 <div>
    The Portal has included links to third-party web pages (“links”), most of them to Internet pages of other Public Administrations, which have been considered of interest to users. However, BRACELIT does not assume any responsibility derived from the connection or contents of the links of third parties referred to in the Portal.
  </div>
  <h2>5.1. Links to the portal</h2>
  <div>
    The Internet user who wants to introduce links from their own web pages to the Portal must comply with the conditions that are detailed below without the ignorance of them avoiding the responsibilities derived from the Law:
      1.	The link will only link to the home page or main page of the Portal but may not reproduce it in any way (inline links, copy of the texts, graphics, etc).
      2.	In any case, it will be prohibited, in accordance with the applicable and current legislation at all times, to establish frames of any kind that surround the Portal or allow the display of the Contents through Internet addresses other than those of the Portal and, in In any case, when they are viewed together with content outside the Portal in such a way that: (I) it produces, or may cause, error, confusion or deception in users about the true origin of the service or Content; (II) involves an act of unfair comparison or imitation; (III) serves to take advantage of the reputation of the brand and prestige of BRACELIT; or (IV) in any other way is prohibited by current legislation.
      3.	No type of false, inaccurate or incorrect statement about BRACELIT, its partners, employees, customers or about the quality of the services it provides will be made from the page that introduces the link.
      4.	In no case will it be stated on the page where the link is located that BRACELIT has given its consent for the insertion of the link or that it sponsors, collaborates, verifies or supervises the sender's services in any other way.
      5.	The use of any denominative, graphic or mixed mark or any other distinctive sign of BRACELIT within the sender's page is prohibited except in the cases permitted by law or expressly authorized by BRACELIT and provided that, in these cases, a link is allowed. directly with the Portal in the manner established in this clause.
      6.	The page that establishes the link must faithfully comply with the law and may not in any case have or link to its own content or that of third parties that: (I) are illegal, harmful or contrary to morality and good customs (pornographic, violent, racists, etc.); (II) induce or may induce in the User the false conception that BRACELIT subscribes, endorses, adheres or in any way supports the ideas, statements or expressions, legal or illegal, of the sender; (III) are inappropriate or not relevant to the activity of BRACELIT in attention to the place, content and theme of the sender's website.
  </div>
  <h2>7.	Propiedad intelectual</h2>
  <div>
    The intellectual property rights of this Internet Portal, its graphic design and the codes it contains are owned by BRACELIT, unless a different ownership is indicated. The unauthorized reproduction, distribution, commercialization or transformation of these works constitutes an infringement of the intellectual property rights of BRACELIT. Likewise, all trademarks or distinctive signs of any kind contained in the portal are protected by law.
    The unauthorized use of the information contained in the Portal, as well as the damages caused to the intellectual and industrial property rights of BRACELIT may give rise to the exercise of the legally corresponding actions and, if applicable, to the responsibilities arising from said exercise. are derived.
  </div>
  <h2>8. Legislación</h2>
  <div>
    This Legal Notice is governed in each and every one of its extremes by Spanish law. BRACELIT and the user, expressly waiving any other jurisdiction, submit to the courts and tribunals of Granada, for any dispute that may arise from accessing this website.
  </div>
</div>