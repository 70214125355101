import { Injectable } from '@angular/core';

import { ApiService } from '@shared/services/api.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Wallet } from '../models/bracelit/wallet';

@Injectable({
  providedIn: 'root'
})
export class WalletsService {

  constructor(private apiService: ApiService,
              private authenticationService: AuthenticationService) {
  }

  async fetchWallets(wristbandId: string, wristbands) {
    const client_id = this.authenticationService.clientId;
    let wallets = [];
    if (client_id && client_id !== '') {
      //OPTIMIZACIÓN GALLEGO: AQUÍ BUSCAMOS LAS WALLETS CORRESPONDIENTE PARA NO TENER QUE LLAMAR AL BACK
      for (var i=0 ; i < wristbands.length ; i++)
      {
          if (wristbands[i].id == wristbandId) {
            wallets = wristbands[i].wallets;
          }
      }
      return wallets;
      // return this.apiService.get(`clients/${client_id}/wristbands/${wristbandId}/wallets/with-events`).toPromise(); //OPTIMIZACIÓN GALLEGO: HACIENDO LO ANTERIOR YA NO ES NECESARIO HACE ESTA LLAMADA AL BACKEND
    }

    return null;
  }

  async fetchWallet(walletId: string, wristbandId: string, wristbands) {
    const client_id = this.authenticationService.clientId;
    let wallet: Wallet;
    if (client_id && client_id !== '') {
      localStorage.setItem('selectedWalletId', walletId);

      //OPTIMIZACIÓN GALLEGO: AQUÍ BUSCAMOS LA WALLET CORRESPONDIENTE PARA NO TENER QUE LLAMAR AL BACK
      for (var i=0 ; i < wristbands.length ; i++)
      {
          if (wristbands[i].id == wristbandId) {
            for (var ii=0 ; ii < wristbands[i].wallets.length ; ii++) {
              if (wristbands[i].wallets[ii].id == walletId) {
                wallet = wristbands[i].wallets[ii];
              }
            }
          }
      }

      //OPTIMIZACIÓN GALLEGO: HACIENDO LO ANTERIOR YA NO ES NECESARIO HACE ESTA LLAMADA AL BACKEND
      /*return this.apiService.get(
        `clients/${client_id}/wristbands/${wristbandId}/wallets/${walletId}/with-events`
      ).toPromise();*/
      return wallet;
    }

    return null;
  }
}
