import { DatePipe } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import * as crypto from 'asymmetric-crypto';
import { Subscription } from 'rxjs';

import { EditEventUserComponent } from '@app/home/wristband-details/event-details/edit-event-user/edit-event-user.component';
import { InfoComponent } from '@app/home/wristband-details/event-details/info/info.component';

import { VoucherDetailsComponent } from '@shared/components/entry-components';
import { BracelitEvent } from '@shared/models/bracelit/bracelit-event';
import { Client } from '@shared/models/bracelit/client';
import { Voucher } from '@shared/models/bracelit/voucher';
import { Wristband } from '@shared/models/bracelit/wristband';
import { ApiService, AuthenticationService, SnackBarService, ThemeService } from '@shared/services';
import { DomainService } from '@shared/services/domain.service';
import { ShoppingCartService } from '@shared/services/shopping-cart.service';
import { VoucherBlueprintService } from '@shared/services/voucher-blueprint.service';
import { WristbandsService } from '@shared/services/wristbands.service';
import { EventConfigurationService } from '@shared/services/event-configuration.service';

import { environment } from '../../../../environments/environment';
import { LeadScannerComponent } from './lead-scanner/lead-scanner.component';
import { ShowContactsComponent } from './lead-scanner/show-contacts/show-contacts.component';
import { PaymentHistoryComponent } from './payments/payment-history/payment-history.component';
import { QrDialogComponent } from './qr-dialog/qr-dialog.component';
import { IframeDialogComponent } from './iframe-dialog/iframe-dialog.component'; // la taja
import { RechargeWristbandComponent } from './recharge-wristband/recharge-wristband.component';
import { ReadQrComponent } from './lead-scanner/read-qr/read-qr.component';
import { TranslateConfigService } from '@app/shared/services/translate-config.service';
import { MoneyReturnComponent } from './money-return/money-return.component';
import { QuestionaryComponent } from './questionary/questionary.component';
import { QuizComponent } from './quiz/quiz.component';
import { browserRefresh } from '../../../app.component';
import { ThisReceiver } from '@angular/compiler';
import { InfoDevComponent } from './info-dev/info-dev.component';
import { auto } from '@popperjs/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  eventId = '';
  payGateway: string;
  event: BracelitEvent;
  wristbandId: any;
  encryptedWrist: any;
  routerSubscription: Subscription;
  vouchers: Voucher[];
  rooms: any;
  loading = true;
  userHasToken: boolean;
  eventHasVouchers: boolean;
  tokenText: string;
  wristbandLabel = (localStorage.getItem('lang') === 'es') ? 'tu pulsera' : 'your wristband';
  voucherLabelMinus = (localStorage.getItem('lang') === 'es') ? 'bonos' : 'offers';
  voucherLabelMayus = (localStorage.getItem('lang') === 'es') ? 'BONOS' : 'OFFERS';
  // aLas = (localStorage.getItem('lang') === 'es') ? ' a las' : '';
  local = (localStorage.getItem('lang') === 'es') ? 'es-ES' : 'en-UK';
  coinName: string;
  public accountLogo: string;
  private userPublicKey = 'wUbW2Kok3dQKBIDySjYRh/dwvyi4KbJQdFid7pyj1Os=';
  private workerPublicKey = 'GoG97Q88PpxNc+BrbEWgupV3N+wO+riSSJIWz4UXtCM=';
  private secureQr: any;
  private checkQr = false;
  public leadScanner = false;
  public bidirectional = false;
  public onlineOrders = false;
  public productScanner = false;
  public obligatoryAssociation = false;
  private valor: string;
  selectedTheme: string;
  selectedBackground: string;
  background: string;
  public showTooltip1 = false;
  @ViewChild('tooltip1') tooltip1: MatTooltip;
  accountEvent: any;
  domain: string;
  checked: boolean;
  @ViewChild('control') elementView: MatButton;
  widthButton: number = 300;
  eventIsTicketing = false;
  errorReturnNumber: number;
  codeWrist: string;
  intervalReturnMoney: any;
  intervalReturnMoneyStarting: any;
  intervalReturnMoneyEnding: any;
  intervalReturnMoneySend: any;
  returnMoneyCommission: any
  dataWrist: any = {};
  allVouchersBP = [];
  flagMBA = false; // FIN FUN (MBA KIDS)
  myForm: FormGroup; // FIN FUN (MBA KIDS)
  restrictAge: boolean = false;
  iframeVisible: boolean = false; // La taja

  constructor(private router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private voucherBlueprintService: VoucherBlueprintService,
              private snackBar: SnackBarService,
              public dialog: MatDialog,
              public wristbandsService: WristbandsService,
              public authService: AuthenticationService,
              public themeService: ThemeService,
              public datepipe: DatePipe,
              private formBuilder: FormBuilder,
              private shoppingCartService: ShoppingCartService,
              private eventConfService: EventConfigurationService,
              private translateConfigService: TranslateConfigService,
              private domainService: DomainService) {
    if (this.domainService.event && !this.domainService.event.hasWristbands) {
      this.wristbandLabel = (localStorage.getItem('lang') === 'es') ? (!this.domainService.event.hasQR ? 'tu pulsera' : 'tu monedero') : (!this.domainService.event.hasQR ? 'your wristband' : 'your wallet');
      this.event = this.domainService.event;
    }
    this.domain = environment.server;

    this.myForm = this.formBuilder.group({});
    this.myForm.addControl('money', new FormControl(0, Validators.required)); // FIN FUN (MBA KIDS)
  }

  ngAfterViewChecked() {
    if (this.elementView !== undefined) {
      if (this.elementView._elementRef !== undefined) {
        this.widthButton = this.elementView._elementRef.nativeElement.offsetWidth;
      }
    }
    if((this.event !== undefined) && (this.event !== null)) {
      this.voucherLabelMinus = (localStorage.getItem('lang') === 'es') ? (!this.event.isTicketing ? 'bonos' : 'entradas') : (!this.event.isTicketing ? 'offers' : 'tickets');
      this.voucherLabelMayus = (localStorage.getItem('lang') === 'es') ? (!this.event.isTicketing ? 'bonos' : 'entradas') : (!this.event.isTicketing ? 'offers' : 'tickets');
      this.wristbandLabel = (localStorage.getItem('lang') === 'es') ? (!this.event.hasQR ? 'tu pulsera' : 'tu monedero') : (!this.event.hasQR ? 'your wristband' : 'your wallet');
    }
    this.local = (localStorage.getItem('lang') === 'es') ? 'es-ES' : 'en-UK';
    if (this.intervalReturnMoneyStarting != undefined && this.intervalReturnMoneyEnding != undefined && this.intervalReturnMoneyStarting != null && this.intervalReturnMoneyEnding != null) {
      this.intervalReturnMoneyStarting = new Date(this.event.intervalStartingDate).toLocaleString(this.local, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      this.intervalReturnMoneyEnding = new Date(this.event.intervalEndingDate).toLocaleString(this.local, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      const date = new Date(this.event.intervalEndingDate);
      this.intervalReturnMoneySend = new Date(date.setDate(date.getDate() + 1)).toLocaleString(this.local, {
        month: 'long',
        day: 'numeric',
      });
    }
  }

  async ngOnInit(): Promise<any> {
    await this.wristbandsService.fetchWristbands(!browserRefresh); //OPTIMIZACION GALLEGO: browserRefresh es true cuando la página se ha recargado dentro de este componente y false cuando llega desde otro componente
    if(this.wristbandsService.selectedWristband !== null) {
      this.checked = this.wristbandsService.selectedWristband['isActive'];
      const data = JSON.parse(this.wristbandsService.selectedWristband.data);
      if (data !== null) {
        if ((data.data !== null) && (data.data !== undefined)) {
          this.dataWrist = data.data;
        }
      }
    }
    this.accountLogo = localStorage.getItem('accountLogo');
    this.onCheckCardToken();
    this.route.paramMap.subscribe(async params => {
      const eventId = params.get('eventId');
      const wristbandId = params.get('wristbandId');
      const walletId = params.get('walletId');
      this.wristbandId = wristbandId;
      this.eventId = eventId;
      this.event = this.wristbandsService.selectedEvent;
      if(this.event !== null) {
        this.eventConfService.setEvent(this.event);
        localStorage.setItem('accountId', JSON.stringify(this.event.accountId));
        localStorage.setItem('accountTheme', JSON.stringify(this.event.selectedTheme));
        this.themeService.selectTheme(JSON.stringify(this.event.selectedTheme));
        this.getSelectedTheme();
        this.coinName = this.event.coinName;
        if(this.wristbandsService.selectedWristband !== null) {
          this.codeWrist = this.event.seeChinoCode ? (this.wristbandsService.selectedWristband.chinoCode !== null ? this.wristbandsService.selectedWristband.chinoCode.toUpperCase() : '') : (this.wristbandsService.selectedWristband.associatedQrId !== null ? this.wristbandsService.selectedWristband.associatedQrId : '');
        }
        if(this.event.accountFile !== undefined && this.event.accountFile !== null) {
          localStorage.setItem('accountLogo', this.event.accountFile);
          this.accountLogo = this.event.accountFile;
        } else {
          localStorage.setItem('accountLogo', '');
          this.accountLogo = '';
        }
        this.returnMoneyCommission = Number(this.event.returnMoneyCommission).toLocaleString("es-ES", {
          style: "currency",
          currency: "EUR",
        });
        if ((this.event.intervalStartingDate !== undefined) && (this.event.intervalEndingDate !== undefined) && (this.event.intervalStartingDate !== null) && (this.event.intervalEndingDate !== null)) {
          this.intervalReturnMoneyStarting = new Date(this.event.intervalStartingDate).toLocaleString(this.local, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });
          this.intervalReturnMoneyEnding = new Date(this.event.intervalEndingDate).toLocaleString(this.local, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });
        }
        this.payGateway = this.event.paymentGatewayOption;
        this.eventHasVouchers = this.event.hasVouchers;
        this.eventIsTicketing = this.event.isTicketing;
        this.secureQr = this.event.hasSecurity;
        this.leadScanner = this.event.hasLeadScanner;
        this.bidirectional = this.event.hasBidirectionalLeadScanner;
        this.onlineOrders = this.event.hasOnlineOrders;
        this.productScanner = this.event.productScanner;
        this.obligatoryAssociation = this.event.obligatoryAssociation;
        localStorage.setItem('paymentGateway', this.payGateway);
        if (this.event.hasQR) {
          this.wristbandLabel = (localStorage.getItem('lang') === 'es') ? 'tu monedero' : 'your wallet';
        }
        if (this.event.isTicketing) {
          this.voucherLabelMinus = (localStorage.getItem('lang') === 'es') ? 'entradas' : 'tickets';
          this.voucherLabelMayus = (localStorage.getItem('lang') === 'es') ? 'ENTRADAS' : 'TICKETS';
        }

        this.vouchers = await this.voucherBlueprintService.fetchClientVouchers(eventId, wristbandId, walletId);
        await this.checkExpiration(this.vouchers);
        this.loading = false;
      } else {
        await this.checkExpiration(this.vouchers);
        this.loading = false;
      }
    });
  }

  async checkExpiration(vouchers) {
    const vouchersCheck = [];
    const today = new Date();
    if(this.wristbandsService.selectedWristband) {
      const vouchersBlueprint = await this.voucherBlueprintService.fetchVoucherBlueprints(this.wristbandsService.selectedWristband['accountId'], this.eventId, this.wristbandId);
      vouchersBlueprint.forEach(voucherBP => {
        if (!voucherBP.preSaleOffer || (new Date(voucherBP.preSaleOffer.replace(/-/g, "/")) > today && new Date(voucherBP.preSaleOfferStart.replace(/-/g, "/")) < today)) {
          this.allVouchersBP.push(voucherBP);
        }
      });
      for (const voucher of vouchers) {
        for (const voucherBlueprint of vouchersBlueprint) {
          if (voucherBlueprint.id === voucher.voucherBlueprintId) {
            if (voucherBlueprint.expirationTime !== null) {
              const days = new Date(voucherBlueprint.expirationTime).getDate();
              const date = new Date(voucher.createdAt);
              date.setDate(date.getDate() + days);
              if (date > today) {
                vouchersCheck.push(voucher);
              }
            } else {
              vouchersCheck.push(voucher);
            }
          }
        }
      }
      this.vouchers = vouchersCheck;
      this.vouchers.forEach(element => {
        element.usesLeft = element.usesLeft/1;
        element.uses = element.uses/1;
      });
    } else {
      this.router.navigate([`home`]);
    }
  }

  getSelectedTheme() {
    if (localStorage.getItem('accountTheme').split('"')[1] !== undefined) {
      this.selectedTheme = localStorage.getItem('accountTheme').split('"')[1];
    } else {
      this.selectedTheme = localStorage.getItem('accountTheme');
    }
    this.selectedBackground = 'bg_whole_' + this.selectedTheme.split('-')[0];
    if ((this.selectedTheme === 'bracelit-theme') || (this.selectedTheme === 'bee-blue-theme')) {
      this.selectedBackground = 'bg_whole';
      localStorage.setItem('accountLogo', '');
    }
    localStorage.setItem('selectedBackground', this.selectedBackground);
  }

  openInfo(): void {
    const dialogRef = this.dialog.open(InfoComponent, {
      minHeight: 'auto',
      maxHeight: '65vh',
      width: '500px',
      minWidth: '320px',
      panelClass: 'custom-modal-padding',
      data: {
        info: this.event.info
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openInfoDev(): void {
    const dialogRef = this.dialog.open(InfoDevComponent, {
      minHeight: 'auto',
      maxHeight: '65vh',
      width: '500px',
      minWidth: '320px',
      panelClass: 'custom-modal-padding',
      data: {
        intervalReturnMoneyStarting: this.intervalReturnMoneyStarting,
        intervalReturnMoneyEnding: this.intervalReturnMoneyEnding,
        intervalReturnMoneySend: this.intervalReturnMoneySend,
        returnMoneyCommission: this.returnMoneyCommission,
        event: { id: this.event.id},
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  setPaymentGateway() {
    const storedGateway = localStorage.getItem('paymentGateway');
    if (this.payGateway !== storedGateway) {
      localStorage.setItem('paymentGateway', this.payGateway);
    }
  }
  toggle(id) {
    if (id === 1) {
      if (!this.showTooltip1) {
        this.tooltip1.show();
      } else {
        this.tooltip1.hide();
      }
      this.showTooltip1 = !this.showTooltip1;
    }
  }
  async onCheckCardToken() {
    this.userHasToken = await this.shoppingCartService.userHasCardToken();
    if (this.userHasToken) {
      this.tokenText = (localStorage.getItem('lang') === 'es') ? 'Eliminar método de pago configurado' : 'Remove selected payment method';
    } else {
      this.tokenText = (localStorage.getItem('lang') === 'es') ? 'No existe método de pago asociado' : 'No payment method found';
    }
  }
  async onDeleteCardToken() {
    let result;
    result = await this.shoppingCartService.deleteCardToken();
    this.snackBar.openSnackBar('');
    this.snackBar.openSnackBar((localStorage.getItem('lang') === 'es') ? 'Método de pago eliminado.' : 'Payment method deleted.');
  }

  openCouponDetails(voucher: Voucher): void {
    if(!this.event.isTicketing) {
      const dialogRef = this.dialog.open(VoucherDetailsComponent, {
        minHeight: '65vh',
        maxHeight: '65vh',
        width: '500px',
        minWidth: '320px',
        panelClass: 'custom-modal-padding-voucher',
        data: {
          voucher: voucher,
          eventId: this.eventId,
          event: this.voucherLabelMinus
        }
      });
    }
  }

  onRechargeWristband(): void {
    let f = 0;
    for (let wrist of JSON.parse(localStorage.getItem('wristbands'))) {
      if (this.route.params['_value'].wristbandId === wrist.id) {
        this.router.navigate([`carrito/bracels`], {relativeTo: this.route});
      } else {
        f += 1;
      }
    }
    if (f === JSON.parse(localStorage.getItem('wristbands')).length) {
      this.authService.logout('../qr-inicial');
    }
  }

  onBuyVouchers(): void {
    let f = 0;
    for (let wrist of JSON.parse(localStorage.getItem('wristbands'))) {
      if (this.route.params['_value'].wristbandId === wrist.id) {
        if(this.eventHasVouchers && !this.event.isTicketing) {
          this.router.navigate([`carrito/bonos`], {relativeTo: this.route});
        } else if (this.eventHasVouchers && this.event.isTicketing) {
          this.router.navigate([`carrito/tickets`], {relativeTo: this.route});
        }
      } else {
        f += 1;
      }
    }
    if (f === JSON.parse(localStorage.getItem('wristbands')).length) {
      this.authService.logout('../qr-inicial');
    }
  }

  onBuyOnlineOrders(): void {
    this.router.navigate([`pedidos`], {relativeTo: this.route});
  }

  onOpenHistory(): void {
    if (this.themeService.checkDefault === true) {
      const dialogRef = this.dialog.open(PaymentHistoryComponent, {
        minHeight: '65vh',
        maxHeight: '65vh',
        width: '500px',
        minWidth: '320px',
        panelClass: 'custom-modal-padding',
        data: {
          hasCapacity: this.event.hasCapacity,
          coinName : this.event.coinName,
          voucherLabelMinus: this.voucherLabelMinus,
          voucherLabelMayus: this.voucherLabelMayus,
          eventId : this.eventId,
          isTicketing: this.event.isTicketing
        }
      });

      dialogRef.afterClosed().subscribe(_result => {
      });
    } else {
      const dialogRef = this.dialog.open(PaymentHistoryComponent, {
        minHeight: '65vh',
        maxHeight: '65vh',
        width: '500px',
        minWidth: '320px',
        panelClass: 'custom-modal-padding',
        data: {
          coinName : this.event.coinName,
          voucherLabelMinus: this.voucherLabelMinus,
          voucherLabelMayus: this.voucherLabelMayus,
          eventId : this.eventId,
          hasCapacity: this.event.hasCapacity,
          isTicketing: this.event.isTicketing
        }
      });

      dialogRef.afterClosed().subscribe(_result => {
      });
    }
  }

  onLeadScanner(): void {
    this.wristbandsService.setEvent(this.event);
    this.wristbandsService.setRouteParams(this.route.params);
    localStorage.setItem('eventSelected', JSON.stringify(this.event));
    this.router.navigate([`lead-scanner`], {relativeTo: this.route});

    // if (this.themeService.checkDefault === true) {
    //   const dialogRef = this.dialog.open(LeadScannerComponent, {
    //     height: '100%',
    //     width: '100%',
    //     panelClass: ['full-screen-dialog'],
    //     data: {
    //       event : this.event,
    //       routeParams: this.route.params
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe(_result => {
    //   });
    // } else {
    //   const dialogRef = this.dialog.open(LeadScannerComponent, {
    //     height: '100%',
    //     width: '100%',
    //     panelClass: ['full-screen-dialog'],
    //     data: {
    //       event: this.event,
    //       routeParams: this.route.params
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe(_result => {
    //   });
    // }
  }

  onProductScanner(): void {
    if (this.themeService.checkDefault === true) {
      const dialogRef = this.dialog.open(ReadQrComponent, {
        height: '100%',
        width: '100%',
        panelClass: ['full-screen-dialog'],
        data: {
          event: this.event,
          productScanner: this.productScanner
        }
      });

      dialogRef.afterClosed().subscribe(_result => {
      });
    } else {
      const dialogRef = this.dialog.open(ReadQrComponent, {
        height: '100%',
        width: '100%',
        panelClass: ['full-screen-dialog', this.themeService.selectedBackground],
        data: {
          event: this.event,
          productScanner: this.productScanner
        }
      });

      dialogRef.afterClosed().subscribe(_result => {
      });
    }
  }

  buildArray(n): any[] {
/*    if (typeof n !== 'number') {
      n = parseFloat(n);
    }

    return Array(n);*/
    return Array(parseInt(n, 10));
  }

  onSeeQrCode(): void {
    if (this.secureQr) {
      const createdAt = new Date().toISOString();
      const userPrivateKey = 'GTeo3AX2tk78JwdQtEk6Is7LhoV7dQG42cju69zuKWLBRtbYqiTd1AoEgPJKNhGH93C/KLgpslB0WJ3unKPU6w==';
      // let latest_date =this.datepipe.transform(createdAt, 'yyyy-MM-dd H:mm');
      const data2encrypt = this.wristbandId + '==' + createdAt;
      this.encryptedWrist = crypto.encrypt(data2encrypt, this.workerPublicKey, userPrivateKey);
      this.valor = this.encryptedWrist.data  + '==' +  this.encryptedWrist.nonce;
    } else {
       this.valor = this.wristbandId;
    }
    const dialogRef = this.dialog.open(QrDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: {
        value: this.valor
      }
    });
  }

  onShowContacts() {
    if (this.themeService.checkDefault === true) {
        const dialogRef = this.dialog.open(ShowContactsComponent, {
          height: '100%',
          width: '100%',
          panelClass: ['full-screen-dialog'],
          data: {
            event: this.event,
            accountId:this.wristbandsService.selectedWristband['accountId']
          }
        });

        dialogRef.afterClosed().subscribe(result => {});
      } else {
        const dialogRef = this.dialog.open(ShowContactsComponent, {
          height: '100%',
          width: '100%',
          panelClass: ['full-screen-dialog', this.themeService.selectedBackground],
          data: {
            event: this.event,
            accountId:this.wristbandsService.selectedWristband['accountId']
          }
        });

        dialogRef.afterClosed().subscribe(_result => {});
    }
}

editEventUserForm(): void {
  this.dialog.open(EditEventUserComponent, {
    width: '500px',
    minWidth: '320px',
    maxHeight: '65vh',
    height: '65vh',
    data: {
      eventId: this.eventId,
      hasEditableData: this.event.hasEditableData,
      isMoneyReturned: this.event.isMoneyReturned,
      money: this.wristbandsService.selectedWallet.money,
      code: this.wristbandsService.selectedWristband.chinoCode
    }
  });
}

goToMoneyReturned(): void {
  if ((this.wristbandsService.selectedWallet.money > 0) && (this.wristbandsService.selectedWristband.label === 'associated' || this.wristbandsService.selectedWristband['label'] === 'changed' || this.event.hasQR)) {
    this.apiService.get(`events/${this.eventId}/checkIsMoneyReturned`).subscribe(data => {
      if(data['isMoneyReturned']) {
        this.dialog.open(MoneyReturnComponent, {
          minHeight: '50vh',
          maxHeight: '65vh',
          width: '500px',
          minWidth: '320px',
          data: {
            eventId: this.eventId,
            isMoneyReturned: this.event.isMoneyReturned,
            money: this.wristbandsService.selectedWallet.money,
            code1: this.wristbandsService.selectedWristband.chinoCode,
            code2: this.wristbandsService.selectedWristband.associatedQrId
          }
        });
      }
    },
    err => {
      console.error(err);
    });
  } else {
    // CHAPUZA OKTOBER FEST (test)
    if(this.eventId !== '58c17e73-db5f-4bfc-b901-981aaf63561a') {
      if (this.wristbandsService.selectedWallet.money <= 0) {
        this.errorReturnNumber = 1;
      } else if (this.wristbandsService.selectedWristband.label !== 'associated' && !this.event.hasQR) {
        this.errorReturnNumber = 2;
      }
    } else {
      this.dialog.open(MoneyReturnComponent, {
        minHeight: '50vh',
        maxHeight: '65vh',
        width: '500px',
        minWidth: '320px',
        panelClass: 'padding-without-bottom',
        data: {
          eventId: this.eventId,
          isMoneyReturned: this.event.isMoneyReturned,
          money: this.wristbandsService.selectedWallet.money,
          code1: this.wristbandsService.selectedWristband.chinoCode,
          code2: this.wristbandsService.selectedWristband.associatedQrId
        }
      });
    }
  }
}

goToQuestionary(): void {
  this.dialog.open(QuestionaryComponent, {
    maxWidth: '95vw',
    width: '95vw',
    maxHeight: '80vh',
    height: '80vh',
    data: {
      eventId: this.eventId,
    }
  });
}

goToQuiz(): void {
  this.dialog.open(QuizComponent, {
    maxWidth: '95vw',
    width: '95vw',
    maxHeight: '80vh',
    height: '80vh',
    data: {
      eventId: this.eventId,
    }
  });
}

  async activateOrDeactivate(e) {
    // if (confirm('¿Está seguro de que desea ' + (this.wristbandsService.selectedWristband['isActive'] ? 'desactivar' : 'activar') + ' su pulsera?')) {
    if (confirm(((localStorage.getItem('lang') === 'es') ? '¿Está seguro de que desea ' : 'Are you sure you want to ') + (this.wristbandsService.selectedWristband['isActive'] ? ((localStorage.getItem('lang') === 'es') ? 'desactivar' : 'desactivate') : ((localStorage.getItem('lang') === 'es') ? 'activar' : 'activate')) + ((localStorage.getItem('lang') === 'es') ? ' su pulsera?' : ' your wristband?'))) {
      this.apiService.patch(`accounts/${this.wristbandsService.selectedWristband['accountId']}/events/${this.eventId}/wristbands/${this.wristbandsService.selectedWristband['physicalId']}/activateOrDeactivate`, 'algo').subscribe(
        async data => {
          this.checked = !this.checked;
          this.wristbandsService.selectedWristband = data[0];
          await this.wristbandsService.fetchWristbands(false);
        });
    } else {
      e.source.checked = this.checked;
    }
  }

  onNavigateToNewWristband(): void {
    this.router.navigate([`/home/nueva-pulsera`], {relativeTo: this.route});
  }

  cancelVoucher(voucher, i) {
    let element = document.getElementsByClassName('vouId')[i] as HTMLElement;
    element.style.width = '100%';
    element.style.height = '40px';
    if (element.innerText === 'x') {
      element.innerText = 'Haga click para cancelar la reserva';
    } else {
      let f = 0;
      for (let wrist of JSON.parse(localStorage.getItem('wristbands'))) {
        if (this.route.params['_value'].wristbandId === wrist.id) {
          this.apiService.delete(`wristbands/${this.wristbandsService.selectedWristband['id']}/vouchers/${voucher}/cancelAccessVoucher`).subscribe(
            data => {
              if(data.status === 200) {
                this.vouchers.splice(i, 1);
                this.snackBar.openSnackBar('Reserva cancelada correctamente.')
              }
            },
            err => {
              console.error(err);
              this.snackBar.openSnackBar('No fue posible cancelar la reserva. Por favor, inténtelo de nuevo.')
            });
        } else {
          f += 1;
        }
      }
      if (f === JSON.parse(localStorage.getItem('wristbands')).length) {
        this.authService.logout('../qr-inicial');
      }
    }
  }

  ngOnDestroy(): void {
  }

  // CHAPUZA FIN FUN (MBA KIDS)
  changeFlagMBA() {
    this.flagMBA = !this.flagMBA;
  }

  changeMoneyMBA(value: number, save: boolean) {
    if(value === 0) {
      this.myForm.controls['money'].reset();
    } else {
      this.myForm.value.money = String(this.myForm.value.money * value);
      if(save) {
        this.loading = true;
        const money = Number(this.wristbandsService.selectedWallet.money) + Number(this.myForm.value.money);
        this.apiService.post(`wristbands/${this.wristbandsService.selectedWristband['id']}/updateWallet`, {money: money}).subscribe(
          async data => {
            if (data.status === 200) {
              this.flagMBA = false;
              this.myForm.controls['money'].reset();
              this.wristbandsService.selectedWallet.money = money;
              this.snackBar.openSnackBar(data.msg);
              this.loading = false;
            } else {
              this.snackBar.openSnackBar('Error');
              this.loading = false;
            }
          });
      }
    }
  }

  // -------------Chapuza Taja -------------//
  checkPrincipalMember() {
    if(this.dataWrist) {
      return (this.dataWrist.tipo === 'Socio principal');
    } else {
      return false;
    }
  }

  abrirCarta(): void {
    const url = 'https://drive.google.com/file/d/1mQYacQ5RdB6eKGwv5YOpLrz2E2LpPIjF/view?usp=sharing';
    window.open(url, '_blank');
  }

  redirectEditProfile(): void {
    const url = 'https://la-taja.web.app';
    window.open(url, '_blank');
  }

  showIframe(): void {
    this.dialog.open(IframeDialogComponent, {
      width: '90%',
      height: '80%'
    });
  }

  // FESTIVAL DE LAS IDEAS
  goToPopUp(): void {
    const dialogRef = this.dialog.open(QuizComponent, {
      maxWidth: '95vw',
      width: '95vw',
      maxHeight: '50vh',
      height: '50vh',
      data: {
        eventId: this.eventId,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.goToPay();
      }
    });
  }
  
  goToPay() {
    let f = 0;
    for (let wrist of JSON.parse(localStorage.getItem('wristbands'))) {
      if (this.route.params['_value'].wristbandId === wrist.id) {
        this.router.navigate([`carrito/bracels`], {relativeTo: this.route, queryParams: {pay: 'true'}});
      } else {
        f += 1;
      }
    }
    if (f === JSON.parse(localStorage.getItem('wristbands')).length) {
      this.authService.logout('../qr-inicial');
    }
  }
}
