import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  public activeLang = this.translateService.getBrowserLang() !== undefined ? ((this.translateService.getBrowserLang() === 'es' || this.translateService.getBrowserLang() === 'en') ? this.translateService.getBrowserLang() : 'en') : 'es';

  constructor(private translateService: TranslateService) {
    if(localStorage.getItem('lang')) {
      if(typeof localStorage.getItem('lang') === 'string') {
        this.translateService.setDefaultLang(localStorage.getItem('lang'));
      } else {
        this.translateService.setDefaultLang(JSON.parse(localStorage.getItem('lang')));
      }
    } else {
      this.translateService.setDefaultLang(this.activeLang);
      localStorage.setItem('lang', this.activeLang);
    }
}

  public changeLanguage(type: string) {
    this.activeLang = type;
    this.translateService.use(type)
    localStorage.setItem('lang', this.activeLang);
  }
}
