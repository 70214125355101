import { BeeModel } from '@shared/abstract/bee-model';

/**
 * Notification, belongs to an User.
 */
export class Notification extends BeeModel {
  /** The user of the notification. */
  userId: string;
  /** The title of the notification. */
  title: string;
  /** The content of the notification. */
  content?: string;
  /** The icon of the notification. */
  icon?: string;
  /** The badge of the notification. */
  badge?: string;
  /** The image of the notification. */
  image?: string;
  /** The text showed in the action (if exists) of the notification. */
  action?: string;
  /** The route of the action of the notification. */
  actionRoute?: string;
  /** Checks if the notifications is already read. */
  isRead: boolean;
  /** Checks if the notification was also sent by email. */
  isEmail: boolean;
  /** Checks if the notification was also sent by push. */
  isPush: boolean;
}
