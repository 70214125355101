import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from '@shared/models/bracelit/client';

import { EventUser } from '@shared/models/bracelit/event-user';
import { ApiService, AuthenticationService, SnackBarService } from '@shared/services';

@Component({
  selector: 'app-edit-event-user',
  templateUrl: './edit-event-user.component.html',
  styleUrls: ['./edit-event-user.component.scss']
})
export class EditEventUserComponent implements OnInit {
  eventId: string;
  loading = true;
  eventForm: any[] = [];
  eventUser: EventUser;
  myForm: FormGroup;
  message: string;
  eventName: string;
  messageReturned: string;
  headerText = (localStorage.getItem('lang') === 'es') ? 'Editar datos' : 'Edit data';
  messageText = (localStorage.getItem('lang') === 'es') ? 'Introduce los datos que deseas editar' : 'Enter the data you want to edit';

  constructor(private authenticationService: AuthenticationService,
              private apiService: ApiService,
              private snackBarService: SnackBarService,
              public dialogRef: MatDialogRef<EditEventUserComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.myForm = new FormGroup({});
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    localStorage.setItem('chinoCode', this.data.code);
    this.loadFormData();
  }

  async loadFormData() {
    this.eventId = this.data.eventId;
    const event = await this.apiService.get(`public/events/${this.eventId}`).toPromise();
    this.eventName = event.name;
    const clientId = this.authenticationService.client.id;
    const url = `clients/${clientId}/events/${this.eventId}/event-user`;
    this.eventUser = await this.apiService.get(url).toPromise();
    this.eventForm = JSON.parse(event.registerForm);
    if ((this.eventUser.registerExtraFields !== 'null') && (this.eventUser.registerExtraFields !== null) && this.eventUser.registerExtraFields !== undefined) {
      const formValues = JSON.parse(this.eventUser.registerExtraFields);
      const controls = {};
      for (const formData of this.eventForm) {
        if (formData.type === 'image') {
          let fileId = '';
          if (this.eventUser.files && this.eventUser.files.length) {
            fileId = this.eventUser.files[0].id;
          }
          const formControl = formData.required ? new FormControl(fileId, Validators.required) : new FormControl('');
          controls[formData.name] = new FormGroup({
            type: new FormControl('image'),
            fileId: formControl
          });
        } else {
            controls[formData.name] = formData.required ? new FormControl(formValues[formData.name], Validators.required)
            : new FormControl(formValues[formData.name]);
        }
      }
      this.myForm.addControl('extraFields', new FormGroup(controls));
    } else {
      if (this.eventForm !== null) {
        const controls = {};
        for (const formData of this.eventForm) {
          if (formData.type === 'image') {
            let fileId = '';
            if (this.eventUser.files && this.eventUser.files.length) {
              fileId = this.eventUser.files[0].id;
            }
            const formControl = formData.required ? new FormControl(fileId, Validators.required) : new FormControl('');
            controls[formData.name] = new FormGroup({
              type: new FormControl('image'),
              fileId: formControl
            });
          } else {
              controls[formData.name] = formData.required ? new FormControl('', Validators.required)
              : new FormControl('');
          }
        }
        this.myForm.addControl('extraFields', new FormGroup(controls));
      } else {
        const controls = {};
        controls['name'] = new FormControl(this.authenticationService.client.name, Validators.required);
        controls['surname'] = new FormControl(this.authenticationService.client.surname, Validators.required);
        //controls['nif'] = new FormControl(this.authenticationService.client.nif, Validators.required);
        //controls['address'] = new FormControl(this.authenticationService.client.address, Validators.required);
        controls['phone'] = new FormControl(this.authenticationService.client.phone, Validators.required);
        this.myForm.addControl('extraFields', new FormGroup(controls));
      }
    }
    this.loading = false;
  }

  async save() {
    if (this.myForm.valid) {
      this.loading = true;
      const clientId = this.authenticationService.client.id;
      let accountId = JSON.parse(localStorage.getItem('accountId'));
      if (this.eventForm !== null) {
        await this.apiService.post(`clients/${clientId}/events/${this.eventId}/event-user/update`, this.myForm.value).toPromise();
      } else {
        this.apiService.patch(`clients/${clientId}`, this.myForm.value.extraFields).subscribe(
          data => {
            this.snackBarService.openSnackBar((localStorage.getItem('lang') === 'es') ? 'Perfil actualizado con éxito.' : 'Profile successfully updated.');
            const clientUpdated: Client = this.authenticationService.client;
            clientUpdated.name = this.myForm.controls['extraFields']['controls']['name'].value;
            clientUpdated.surname = this.myForm.controls['extraFields']['controls']['surname'].value;
            //clientUpdated.nif = this.myForm.controls['extraFields']['controls']['nif'].value;
            //clientUpdated.address = this.myForm.controls['extraFields']['controls']['address'].value;
            clientUpdated.phone = this.myForm.controls['extraFields']['controls']['phone'].value;
            this.authenticationService.client = clientUpdated;
          });
      }
      this.loading = false;
      this.message = (localStorage.getItem('lang') === 'es') ? 'Se ha actualizado correctamente el perfil para el evento.' : 'Profile has been successfully updated.';
      this.dialogRef.close();
    }
  }

}
