import { BeeModel } from '@shared/abstract/bee-model';

export class BracelitEvent extends BeeModel {
  [x: string]: any;
  accountId: string;
  name: string;
  iconUrl: string;
  slug: string;
  startingDate: Date;
  endingDate: Date;
  attendees: number;
  isPrepaid: boolean;
  isMoneyReturned: boolean;
  hasRegistration: boolean;
  hasVouchers: boolean;
  hasExactPurchases: boolean;
  hasTicketsControl: boolean;
  hasOnlyVouchers: boolean;
  isPaymentGatewayEnabled: boolean;
  bookingAdditionalMinutes: number;
  bracelsConversion: number;
  hasPrecharge: boolean;
  preChargedMoney: number;
  includeCommission: boolean;
  hasBookings: boolean;
  hasPhotos: boolean;
  hasPurchases: boolean;
  registerForm: any;
  hasQR: boolean;
  hasWristbands: boolean;
  domain: string;
  isDailyStatsEmailingActive: boolean;
  isFinalStatsEmailingActive: boolean;
  pivot: any;
  statsReceivingEmails: any[] = [];
  hasInformation: any;
  info: any;
  isTicketing: any;
  paymentGatewayOption: string;
  coinName: string;
  wearablesManagement: boolean;
  hasEditableData: boolean;
  isNominativeWristband: boolean;
  hasCapacity:boolean;
  Credentials: any;
  returnMoneyCommission: any;
  hasSecurity: any;
  hasLeadScanner: boolean;
  hasOnlineOrders: boolean;
  productScanner: boolean;
  obligatoryAssociation: boolean;
  hasLeadScannerComment: any;
  hasPreCharge: boolean;
  commissionPercent: any;
  staticCommission: any;
  minimumRecharge: any;
  intervalStartingDate: any;
  intervalEndingDate: any;
  selectedTheme: any;
  isBizumEnabled: boolean;
  isCardTokenEnabled: boolean;
  isGooglePayEnabled: boolean;
  isApplePayEnabled: boolean;
  accountFile: any;

  constructor(data: any) {
    super(data);

    if (data.hasOwnProperty('statsReceivingEmails') && data['statsReceivingEmails']) {
      this.statsReceivingEmails = [];

      for (const statsReceivingEmail of data['statsReceivingEmails']) {
        this.statsReceivingEmails.push(statsReceivingEmail);
      }
    }
  }
}
