<qrcode [allowEmptyString]="allowEmptyString"
        [colorDark]="qrColorDark"
        [colorLight]="qrColorLight"
        [cssClass]="qrCssClass"
        [elementType]="qrElementType"
        [errorCorrectionLevel]="qrErrorCorrectionLevel"
        [margin]="qrMargin"
        [qrdata]="qrData"
        [scale]="qrScale"
        [width]="qrSize">
</qrcode>
