import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { LeadScannerComponent } from './lead-scanner.component';
import { ContactQrComponent } from './contact-qr/contact-qr.component';
import { TranslateModule } from '@ngx-translate/core';
import { LeadScannerRoutingModule } from './lead-scanner-routing.module';

@NgModule({
  declarations: [LeadScannerComponent, ContactQrComponent],
  imports: [
    SharedModule,
    TranslateModule,
    LeadScannerRoutingModule
  ]
})
export class LeadScannerModule {
}
