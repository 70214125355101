<bracelit-mobile-header (onClickLeftButton)="onClose()"
                   [header_central_text_class]="'light-18px-center-white'"
                   [left_icon]="'assets/icons/back.svg'"
                   [left_icon_height]="24"
                   [left_icon_width]="12"
                   [central_text]="headerText"
                   class="header-primary-background"
                   height="50">
</bracelit-mobile-header>

<!--
<div class="main-container" fxLayoutAlign="center center">
    <div class="payments-card-card-container" fxLayoutAlign="center center">
      <mat-card class="bracelit-card-2 white-opacity-background contacts-card" fxLayout="row wrap">
        <mat-tab-group [color]="'accent'" fxFlex="100">
          <mat-tab>
            <ng-template mat-tab-label>
              <span class="bold-16px-center-primary">CONTACTOS</span>
            </ng-template>
            <div *ngFor="let user of this.users" class="tab-content-container"
            (click)="onInfoUserForm(user)">
              <mat-label class="light-16px-left-primary">{{user.surname}}, {{ user.name }} </mat-label>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
</div> -->

<div class="main-container">
  <bracelit-preloader *ngIf="loading" [absoluteCentered]="true">
  </bracelit-preloader>
  <div *ngIf="!loading" class="payments-card" fxLayoutAlign="center center">
    <mat-card class="bracelit-card-2 white-opacity-background payments-card" fxLayout="row wrap">
      <mat-tab-group [color]="'accent'" fxFlex="100">
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="bold-16px-center-primary">{{ 'showContacts.contacts' | translate }}</span>
          </ng-template>
          <ng-template [ngIf]="users.length === 0">
            <h2 class="transaction-container primary-border-100" fxFlex="100" fxLayout="row" fxLayoutAlign="center center">{{ 'showContacts.haveNo' | translate }}</h2>
          </ng-template>
          <div *ngFor="let user of this.users"
               (click)="onInfoUserForm(user)"
               class="transaction-row"
               fxFlex="100">
            <div class="transaction-container primary-border-100" fxFlex="calc(100% - 36px)"
                 fxLayout="row wrap" fxLayoutAlign="center center">
                <div class="transaction-title bold-16px-left-primary" fxFlex="60">
                  {{user.name}} {{user.surname}}
                </div>
            </div>
          </div>
          <div class="tab-button" fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
          <button class="accent-background regular-16px-center-primary"
                  mat-raised-button
                  [class.disabled-button]="users.length === 0"
                  [disabled]="users.length === 0"
                  type="button"
                  (click)="onDownloadCSV()">
                  {{ 'showContacts.download' | translate }} CSV
          </button>
        </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
