import { NgModule } from '@angular/core';

import { ToggleSidebarService } from '@shared/services';
import { SharedModule } from '@shared/shared.module';

import { PrivacityPolicyComponent } from './privacity-policy.component';

@NgModule({
  imports: [SharedModule],
  declarations: [PrivacityPolicyComponent
  ],
  providers: [ToggleSidebarService]
})

export class PrivacityPolicyModule {
}
