import { Injectable } from '@angular/core';

@Injectable()
export class GlobalArraysService {
  /**
   * GLOBAL ARRAYS
   */
  notification_types: object[] = [
    {value: 'normal', name: 'Normal'},
    {value: 'general', name: 'General'}
  ];

  countries: object[] = [
    {value: 1, name: 'España'},
    {value: 2, name: 'Portugal'}
  ];

  zone_types: object[] = [
    {value: 'ccaa', name: 'Comunidad Autónoma'},
    {value: 'province', name: 'Provincia'},
    {value: 'country', name: 'País'}
  ];

  print_formats: object[] = [
    {value: 'A4', name: 'A4'},
    {value: 'A5', name: 'A5'},
  ];

  font_formats: object[] = [
    {value: 'roboto', name: 'Roboto'},
    {value: 'trajan', name: 'Trajan Pro'},
    {value: 'arial', name: 'Arial'},
    {value: 'times', name: 'Times New Roman'},
  ];

  export_group_types: object[] = [
    {value: 'default', name: 'Toda la información del grupo'},
    {value: 'rooming', name: 'Rooming'},
    {value: 'pullmantur', name: 'Pullmantur'},
  ];

  public getValue(value: any, object_array: object[], property = 'name'): any {
    for (const object of object_array) {
      if (object['value'] === value) {
        return object[property];
      }
    }

    return '';
  }
}
