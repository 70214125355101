import { BeeModel } from '@shared/abstract/bee-model';
import { Account } from '../account';
import { Wallet } from './wallet';

export class Wristband extends BeeModel {
  physicalId: string;
  accountId: string;
  account: Account;
  associatedQrId: string;
  eventWristbandImageUrl: string;
  chinoCode: string;
  data: any;
  label: string;
  showName: string;
  isInside: string;
  active: string;
  numberOfEvents: number;
  wallets: Wallet[] = [];
  underAge: boolean;

  constructor(data: any) {
    super(data);

    if (data.hasOwnProperty('wallets') && data['wallets']) {
      this.wallets = [];

      for (const wallet of data['wallets']) {
        this.wallets.push(new Wallet(wallet));
      }
    }
  }
}
