import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Product } from '@shared/models/bracelit/product';
import { Voucher } from '@shared/models/bracelit/voucher';
import { VoucherBlueprint } from '@shared/models/bracelit/voucher-blueprint';
import { VoucherBlueprintService } from '@shared/services/voucher-blueprint.service';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './voucher-details.component.html',
  styleUrls: ['./voucher-details.component.scss']
})
export class VoucherDetailsComponent implements OnInit {
  voucher: Voucher;
  voucherBlueprint: VoucherBlueprint;
  voucherBlueprint2 = [];
  products: Product[];
  voucherBlueprint_uses = [];
  voucher_used_uses = [];
  voucher_uses_left = [];
  gastado = 0;
  loading = true;
  voucherLabel = (localStorage.getItem('lang') === 'es') ? 'Bono' : 'Offer';
  tokensLabel1 = (localStorage.getItem('lang') === 'es') ? 'Fichas' : 'Tokens';
  tokensLabel2 = (localStorage.getItem('lang') === 'es') ? 'fichas' : 'tokens';
  tokenLabel2 = (localStorage.getItem('lang') === 'es') ? 'ficha' : 'token';
  leftLabel = (localStorage.getItem('lang') === 'es') ? 'Te quedan' : 'Left';
  backLabel = (localStorage.getItem('lang') === 'es') ? 'Volver' : 'Back';
  leftLabel2 = (localStorage.getItem('lang') === 'es') ? 'Has gastado' : 'You have spent';
  backLabel2 = (localStorage.getItem('lang') === 'es') ? 'Volver' : 'Back';
  messageLabel = (localStorage.getItem('lang') === 'es') ? 'Con estas fichas puedes comprar' : 'With these tokens you can purchase';
  messageMoneyExtra = (localStorage.getItem('lang') === 'es') ? 'Con este bono se recarga un extra de' : 'With this voucher an extra of';
  buyInterval = (localStorage.getItem('lang') === 'es') ? 'Intervalo de compra' : 'Buy interval';
  useInterval = (localStorage.getItem('lang') === 'es') ? 'Intervalo de uso' : 'Use interval';
  maximumToSellText = (localStorage.getItem('lang') === 'es') ? 'Unidades a la venta' : 'Units for sale';
  maximumTimesToRechargeText = (localStorage.getItem('lang') === 'es') ? 'Máximas unidades por persona' : 'Maximum units per person';
  start = (localStorage.getItem('lang') === 'es') ? 'Inicio' : 'Start';
  end = (localStorage.getItem('lang') === 'es') ? 'Fin' : 'End';
  voucher_products_prices = [];
  // dentro tiene un array por cada producto y cada array tantos elementos como usos tenga cada producto,
  // hacerlo cuando veamos cómo van los productos dentro de los voucher blueprints
  // luego en el html lo recorro con dos *ngFor

  constructor(public dialogRef: MatDialogRef<VoucherDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private voucherBlueprintService: VoucherBlueprintService) {
    this.init(data);
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  async init(data): Promise<any> {
    // va a venir ya el voucher formado, no va a hacer falta construirlo aquí ->  this.voucher = data.voucher
    this.voucher = data.voucher;
    this.voucherBlueprint = data.voucherBlueprint;
    if (this.voucherBlueprint === undefined) {
      this.voucherBlueprint2 = await this.voucherBlueprintService.fetchVoucherBlueprint(data.eventId);
      for (let i = 0; i < this.voucherBlueprint2.length; i++) {
        if (this.voucherBlueprint2[i].id === this.voucher.voucherBlueprintId) {
          this.voucherBlueprint = this.voucherBlueprint2[i];
        }
      }
    }
    if (data.event.isTicketing) {
      this.voucherLabel = (localStorage.getItem('lang') === 'es') ? 'Entrada' : 'Ticket';
    }
    // if (data.voucher) {
    //   if (data.voucher instanceof Voucher) {
    //     // el modelo voucher debería tener dentro el VoucherBlueprint no sólo el _voucherBlueprint_id
    //     this.voucher = data.voucher;
    //     // this.voucherBlueprint = data.voucher.voucherBlueprint
    //     for (let i = 0; i < this.voucher.usesLeft; i++) {
    //       this.voucher_uses_left.push(1);
    //     }
    //     for (let i = 0; i < this.voucherBlueprint.uses - this.voucher.usesLeft; i++) {
    //       this.voucher_used_uses.push(1);
    //     }
    //   }
    // }
    // if (data.voucherBlueprint) {
    //   if (data.voucherBlueprint instanceof VoucherBlueprint) {
    //     this.voucherBlueprint = data.voucherBlueprint;
    //   }
    // }
    // for (let i = 0; i < this.voucherBlueprint.uses; i++) {
    //   this.voucherBlueprint_uses.push(1);
    // }

    const voucherBlueprintId = this.voucherBlueprint ? this.voucherBlueprint.id : this.voucher.voucherBlueprintId;
    this.products = await this.voucherBlueprintService.fetchProducts(voucherBlueprintId);
    this.loading = false;

    this.gastado = this.voucher ? (this.voucherBlueprint.uses - this.voucher.usesLeft >= 0 ? this.voucherBlueprint.uses - this.voucher.usesLeft : 0) : 0;
  }

  logVoucher(): void {
    if (this.voucher) {
    }
    if (this.voucherBlueprint) {
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  buildArray(n: any): any[] {
    return Array(parseInt(n, 10));
  }
}
