// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/**git add -A
 * Development environment
 */
export const environment = {
  production: true,
  workerRegister: false,
  firebase: {
    apiKey: "AIzaSyCPROJh7dBbl6hY-Z4O60KWMyhTgfTSVZs",
    authDomain: "bracelit-app-test.firebaseapp.com",
    databaseURL: "https://bracelit-app-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bracelit-app-test",
    storageBucket: "bracelit-app-test.appspot.com",
    messagingSenderId: "535394758154",
    appId: "1:535394758154:web:d9f45ca57c4bc0f52739f3",
    measurementId: "G-WYBW0X2V6M"
  },
  protocol: 'https://',
  // protocol: 'http://',
  // server: 'localhost:8000/',
  // server:'api.v5.bracelit.es/',
  server: 'test.bracelit.es/',
  apiUrl: 'api/v1/',
  gpay: "PRODUCTION",
  redsysUrl:"https://sis-t.redsys.es:25443/sis/realizarPago",
  // redsysUrl:"https://sis.redsys.es/sis/realizarPago",
  wegowLogin: "https://www.wegow.com/api/oauth/authorize/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
