import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * BeeConfirmNavigation, shows a dialog to confirm the navigation.
 *
 * @example
 *
 * const dialogRef = this.matDialog.open(BeeConfirmNavigationComponent, {
 *  width: '500px',
 *  minWidth: '320px',
 *  data: {
 *      navigationWarningDescription: '¿Seguro que quieres salir del pedido?',
 *      stayButtonText: 'Cancelar',
 *      leaveButtonText: 'Descartar pedido'
 *  }
 * });
 *
 * dialogRef.afterClosed().pipe(delay(3000)).subscribe((data) => {
 *   if (!data.stay && !data.save) {
 *      this.router.navigate([this.backRoute], {relativeTo: this.route});
 *   }
 * });
 */
@Component({
  selector: 'bee-confirm-navigation',
  templateUrl: 'bee-confirm-navigation.component.html',
  styleUrls: ['./bee-confirm-navigation.component.scss'],
})
export class BeeConfirmNavigationComponent {
  /**
   * Warning description.
   * @type {string}
   */
  navigationWarningDescription = '¿Seguro que quieres salir sin guardar los cambios realizados?';
  /**
   * Save and leave button text.
   * @type {string}
   */
  saveAndLeaveButtonText = 'GUARDAR CAMBIOS';
  /**
   * Leave button text.
   * @type {string}
   */
  leaveButtonText = 'Descartar cambios';
  /**
   * Stay button text.
   * @type {string}
   */
  stayButtonText = 'Cancelar';

  /**
   * Constructor.
   * @param dialogRef
   * @param data The dialog injected data.
   */
  constructor(private dialogRef: MatDialogRef<BeeConfirmNavigationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data) {
      if (this.data.stayButtonText) {
        this.saveAndLeaveButtonText = data.stayButtonText;
      }
      if (this.data.leaveButtonText) {
        this.leaveButtonText = data.leaveButtonText;
      }
      if (this.data.navigationWarningDescription) {
        this.navigationWarningDescription = data.navigationWarningDescription;
      }
      if (this.data.saveAndLeaveButtonText) {
        this.saveAndLeaveButtonText = data.saveAndLeaveButtonText;
      }
    }
  }

  /**
   * Closes the dialog.
   */
  closeDialog(stay: boolean = true, save: boolean = false): void {
    this.dialogRef.close({stay: stay, save: save});
  }
}
