<div id="goTo" style="position: relative; overflow-y: scroll">
  <div fxLayout="row wrap" fxLayoutAlign="start start" style="padding: 0 24px;">
    <div *ngIf="this.intervalReturnMoneyStarting && this.intervalReturnMoneyEnding"
        style="color: #0F223E !important;
              letter-spacing: -0.34px !important; display: flex; flex-direction: column; width: 100%;">
        <div style="display: flex !important; align-items:center !important; justify-content:right !important;
        height:fit-content !important; width:100% !important; padding-right: 24px;"
        (click)="onClose()">
          <img [src]="'assets/icons/crossClose.svg'" style="cursor: pointer;">
        </div>
        <div class="voucher-title bold-16px-center-marine primary-border-100" fxFlex="100"
             fxLayoutAlign="center center" style="margin: 25px 0px; font-weight: bold !important; text-align: center !important;">
             {{ 'eventDetails.messageReturnMoneyTitle' | translate }}
        </div>
      <div style="padding-left: 24px; padding-bottom: 15px;">
        <ul style="margin: 0;"><li>{{ 'eventDetails.messageReturnMoney1' | translate }} {{intervalReturnMoneyStarting}} {{ 'eventDetails.messageReturnMoney2' | translate }} {{intervalReturnMoneyEnding}} {{ 'eventDetails.hours' | translate}}.</li></ul>
      </div>
      <div style="padding-bottom: 15px; padding-left: 24px;">
        <ul style="margin: 0;"><li>{{ 'eventDetails.messageReturnMoney3a' | translate }} {{intervalReturnMoneySend}} {{ 'eventDetails.messageReturnMoney3b' | translate }}</li></ul>
      </div>
      <div style="padding-bottom: 15px; padding-left: 24px;">
        <ul style="margin: 0;"><li>{{ 'eventDetails.message2' | translate }}</li></ul>
      </div>
      <div *ngIf="this.returnMoneyCommission" style="padding-bottom: 25px; padding-left: 24px;">
        <ul style="margin: 0;"><li>{{ 'eventDetails.messageReturnMoney4' | translate }} {{returnMoneyCommission}}.</li></ul>
      </div>
      <div style="text-align: center; padding-bottom: 10px;">
        {{ 'eventDetails.contact' | translate }} <a href="mailto:soporte@bracelit.es" style="font-weight: bold; color: #0F223E; text-decoration: none;">soporte@bracelit.es</a>
      </div>
    </div>
  </div>
</div>
