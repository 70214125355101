import { BeeModel } from '@shared/abstract/bee-model';

export class CreditMove extends BeeModel {
  eventId: string;
  walletId: string;
  voucherId: string;
  amount: number;
  accrued: number;
  uses: number;
  usesAccrued: number;
  name: any;

  constructor(data: any) {
    super(data);

    this.createdAt = new Date(this.createdAt);
    this.amount = parseFloat(this.amount + '');
  }

}
