/**
 * BracelitSidebarElement.
 */
export class BracelitSidebarElement {
  /** Name of the element. */
  name: string;
  /** Route of the element. */
  route: string;
  /** Icon of the element. */
  icon?: string;
  /** Checks if the icon is an image. */
  imageIcon = false;
  /** Sub elements */
  subElements: any[] = [];
  /** Subelement height */
  subElementsHeight = 50;
  /** Subelements total height before scrolling */
  subElementsTotalHeight = 50;
  /** Checks if the element is disabled. */
  isDisabled = false;
  /** Checks if the element is the last item. */
  isLastItem = false;
  /** Checks if the element has a border top. */
  borderTop = false;
  /** Checks if the element has a border bottom. */
  borderBottom = false;
  /** Checks has the order reversed. */
  reverseOrder = false;
  /** Checks if the element collapses the sidebar on click. */
  collapseOnClick = false;
  externalUrls = false;
}
