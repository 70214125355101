import { Injectable } from '@angular/core';

import { ApiService } from '@shared/services/api.service';
import { AuthenticationService } from '@shared/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherBlueprintService {

  constructor(private apiService: ApiService,
              private authenticationService: AuthenticationService) { }

  async fetchVoucherBlueprints(accountId: string, eventId: string, wristbandId: string) {
    const clientId = this.authenticationService.clientId;

    return this.apiService.get(`accounts/${accountId}/events/${eventId}/listEasyVoucherBlueprintUsers`).toPromise();
  }

  async fetchVoucherBlueprint(eventId: string) {
    let accountId = this.authenticationService.accountId;
    if (accountId.includes('"')) {
      accountId = accountId.split('"')[1];
    }

    return this.apiService.get(`accounts/${accountId}/events/${eventId}/listEasyVoucherBlueprintUsers`).toPromise();
  }

  async fetchProductsFromEvent(accountId: string, eventId: string) {

    return this.apiService.get(`accounts/${accountId}/events/${eventId}/listProducts`).toPromise();
  }

  async fetchProductsCategoriesFromEvent(accountId: string, eventId: string) {

    return this.apiService.get(`accounts/${accountId}/events/${eventId}/listProductCategories`).toPromise();
  }

  async purchaseVouchers(data: any, wristbandId: string, eventId: string) {
    const clientId = this.authenticationService.clientId;

/*    return this.apiService.post(
      `clients/${clientId}/events/${eventId}/wristbands/${wristbandId}/purchase-vouchers`,
      data).toPromise();*/
    return this.apiService.post(
      `clients/${clientId}/events/${eventId}/wristbands/${wristbandId}/purchase-vouchers-new`,
      data).toPromise();
  }

  async purchaseVouchersNew(data: any, wristbandId: string, eventId: string) {
    const clientId = this.authenticationService.clientId;

    /*    return this.apiService.post(
          `clients/${clientId}/events/${eventId}/wristbands/${wristbandId}/purchase-vouchers`,
          data).toPromise();*/
    return this.apiService.post(
      `clients/${clientId}/events/${eventId}/wristbands/${wristbandId}/purchase-vouchers-new-prerecharge`,data).toPromise();
    }


  async purchaseProducts(data: any, wristbandId: string, eventId: string) {
    let accountId = this.authenticationService.accountId;
    if (accountId.includes('"')) {
      accountId = accountId.split('"')[1];
    }

    return this.apiService.post(
      `accounts/${accountId}/events/${eventId}/wristbands/${wristbandId}/purchaseOnline`,
      data).toPromise();
  }

  async fetchClientVouchers(eventId: string, wristbandId: string, walletId: string) {
    const clientId = this.authenticationService.clientId;

    return this.apiService.get(`clients/${clientId}/events/${eventId}/wristbands/${wristbandId}/wallets/${walletId}/vouchers`).toPromise();
  }

  async fetchProducts(voucherBlueprintId: string) {
    const clientId = this.authenticationService.clientId;

    return this.apiService.get(`clients/${clientId}/voucher-blueprints/${voucherBlueprintId}/products`).toPromise();
  }
}
