<div class="payment-details-main-container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">

  <div style="display: flex !important; align-items:center !important; justify-content:right !important;
    height:fit-content !important; width:100% !important; padding:20px 20px 0px 20px"
    (click)="onGoBack()">
      <img [src]="'assets/icons/crossClose.svg'">
    </div>

  <div class=" bold-20px-center-primary" fxFlex="100"
       fxLayoutAlign="center center">
    <span> {{headTitle}} </span>
  </div>
  <div>
  <div class="payment-details-content" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
    <div class="payment-details-date light-14px-center-primary" fxFlex="100" fxLayoutAlign="center center">
      {{data.transaction.createdAt | date : 'dd-MM-yyyy HH:mm'}}
    </div>
    <div *ngIf="data.extraInfo !== undefined" class="payment-details-date light-14px-center-primary" fxFlex="100" fxLayoutAlign="center center">
      {{ 'payments.gg' | translate}} {{fullPay}}
    </div>
    <div *ngIf="data.extraInfo !== undefined" class="payment-details-date light-14px-center-primary" fxFlex="100" fxLayoutAlign="center center">
      {{ 'payments.method' | translate}} {{paymentMethod}}
    </div>

    <!--PAGO CON BONOS-->
    <!--<div class="payment-details-headers" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="light-16px-left-primary" fxFlex="50">Bono</div>-->
    <!--<div class="light-16px-right-primary" fxFlex="50">Fichas usadas</div>-->
    <!--</div>-->

    <!--<div class="payment-details-content-background primary-background-50" fxFlex="100" fxLayout="row wrap"-->
    <!--fxLayoutAlign="center start"-->
    <!--[ngStyle]="{'height.px' : payment.vouchers * 40 + 20}">-->
    <!--&lt;!&ndash;ng for por cada bono usado o recargado&ndash;&gt;-->
    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Happy Hour</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row" fxLayout="row wrap" fxLayoutGap="14px grid" fxLayoutAlign="end center">-->
    <!--&lt;!&ndash;ngFor icono por cada uso disponible&ndash;&gt;-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Plato de arroz</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row" fxLayout="row wrap" fxLayoutGap="14px grid" fxLayoutAlign="end center">-->
    <!--&lt;!&ndash;ngFor icono por cada uso disponible&ndash;&gt;-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->


    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Plato de arroz</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row" fxLayout="row wrap" fxLayoutGap="14px grid" fxLayoutAlign="end center">-->
    <!--&lt;!&ndash;ngFor icono por cada uso disponible&ndash;&gt;-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Plato de arroz</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row" fxLayout="row wrap" fxLayoutGap="14px grid" fxLayoutAlign="end center">-->
    <!--&lt;!&ndash;ngFor icono por cada uso disponible&ndash;&gt;-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Plato de arroz</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row" fxLayout="row wrap" fxLayoutGap="14px grid" fxLayoutAlign="end center">-->
    <!--&lt;!&ndash;ngFor icono por cada uso disponible&ndash;&gt;-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Plato de arroz</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row" fxLayout="row wrap" fxLayoutGap="14px grid" fxLayoutAlign="end center">-->
    <!--&lt;!&ndash;ngFor icono por cada uso disponible&ndash;&gt;-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--<img [src]="'assets/bracelit/voucher_use_full.svg'" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="regular-18px-center-primary close-dialog" fxFlex="100" fxLayoutAlign="center center"-->
    <!--(click)="onGoBack()">-->
    <!--Volver-->
    <!--</div>-->

    <!--</div>-->

    <!--PAGO CON BRACELS O COMPRA DE BRACELS-->

    <div [ngStyle]="{'height.px' : 40}" class="payment-details-content-background primary-background-50" fxFlex="100"
         fxLayout="row wrap"
         fxLayoutAlign="center start"
         style="background-color:white !important">
      <div class="payment-details-boucher " fxFlex="100" fxLayoutAlign="center center">
        <div *ngIf="coinName === 'bracels' || coinName === 'Bracels'" class="payment-details-boucher-quantity bold-400-18px-center-primary"
             fxFlex="100" fxLayoutAlign="center center">
          {{wordShop}}: {{infoQuantity}}
          <img style="width: 20px !important; height: 20px !important; display: flex; align-items:center; margin:0px !important" [src]="'assets/icons/moneda.svg'" alt="">
        </div>
        <div *ngIf="coinName !== 'bracels' && coinName !== 'Bracels'" class="payment-details-boucher-quantity bold-400-18px-center-primary"
             fxFlex="100" fxLayoutAlign="center center">
             {{ 'payments.quantity' | translate}} {{data.transaction.amount}} <img [src]="'https://' + domain + 'api/v1/files/' + eventId + '/downloadCoin'" alt="">
        </div>
      </div>
    </div>

    <div *ngIf="linesInfo" [ngStyle]="{'height.px' : 40}" class="payment-details-content-background primary-background-50" fxFlex="100"
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    style="background-color:white !important">
      <div *ngFor="let index of linesInfo"
        class="item primary-color"
        fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="index.unitPrice>0">
            {{ index.quantity.split('.',1) }} X {{ index.name }} {{ (index.unitPrice|currency:'EUR':'symbol':'1.2-2') + '/ud' }}
          </div>
          <div *ngIf="index.usesPrice>0">
            {{ index.quantity.split('.',1) }} X {{ index.name }} {{ index.usesPrice|currency:'B':'symbol':'1.2-2'  }}/ud
        </div>
    </div>
    </div>

    <!--RECARGA DE BONOS-->
    <!--<div class="payment-details-headers" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="light-16px-left-primary" fxFlex="50">Bono</div>-->
    <!--<div class="light-16px-right-primary" fxFlex="50">Cantidad</div>-->
    <!--</div>-->

    <!--<div class="payment-details-content-background primary-background-50" fxFlex="100" fxLayout="row wrap"-->
    <!--fxLayoutAlign="center start"-->
    <!--[ngStyle]="{'height.px' : payment.vouchers * 40 + thereIsScroll * 20}">-->
    <!--&lt;!&ndash;ng for por cada bono usado o recargado&ndash;&gt;-->
    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Happy Hour</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row bold-400-18px-left-primary" fxLayout="row wrap" fxLayoutGap="14px grid"-->
    <!--fxLayoutAlign="end center">-->
    <!--6-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="payment-details-boucher" fxFlex="100" fxLayoutAlign="center center">-->
    <!--<div class="payment-details-boucher-name bold-400-18px-left-primary" fxFlex="40">Plato de arroz</div>-->
    <!--&lt;!&ndash;<div class="payment-details-boucher-quantity bold-400-18px-left-primary">6</div>&ndash;&gt;-->
    <!--<div class="payment-details-boucher-tokens" fxFlex="60">-->
    <!--<div class="voucher-uses-row bold-400-18px-left-primary" fxLayout="row wrap" fxLayoutGap="14px grid"-->
    <!--fxLayoutAlign="end center">-->
    <!--4-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="regular-18px-center-primary close-dialog" fxFlex="100" fxLayoutAlign="center center"-->
    <!--(click)="onGoBack()">-->
    <!--Volver-->
    <!--</div>-->

    <!--</div>-->
  </div>
</div>
</div>
