<bracelit-mobile-header *ngIf="existsToken"
                   (onClickLeftButton)="onClose()"
                   [header_central_text_class]="'light-18px-center-white'"
                   [left_icon]="'assets/icons/back.svg'"
                   [left_icon_height]="24"
                   [left_icon_width]="12"
                   central_text="{{'scanQR.newCode' | translate}}"
                   class="header-primary-background"
                   height="50">
</bracelit-mobile-header>

<div [ngClass]="{'no-padding-bottom': existsToken, 'low-padding-top': !existsToken}"
     class="main-container primary-background"
     fxLayout="row wrap">
  <div *ngIf="!existsToken" class="main-text light-20px-center-white" fxFlex="100" fxLayoutAlign="center center">
    {{text}}
  </div>

  <div [ngClass]="{'full-height': existsToken}" class="qr-scan-main-container primary-background-800" fxFlex="100"
       fxLayoutAlign="center center">
    <bee-qr-scan [scanFrameHeightPx]="280"
                 [scanFrameWidthPx]="280"
                 [modalMode]="false"
                 (scanResult)="openUserInfo($event)">
    </bee-qr-scan>
  </div>
</div>
