/**
 * BeeModel helper, contains the default attributes of a model.
 *
 * @example
 *
 * export class Test extends BeeModel {
 *    name: string;
 * }
 */
export abstract class BeeModel {
  /** The id of the model. */
  id!: string;
  /** The creation date. */
  createdAt?: Date;
  /** The last update date. */
  updatedAt?: Date;
  /** The delete date. */
  deletedAt?: Date;
  /** The creator of the model. */
  createdBy?: string;
  /** The user/email which updated the model. */
  updatedBy?: string;
  /** The user/email which deleted the model. */
  deletedBy?: string;

  /**
   * Constructor, assign the params and then the dates.
   * @param data
   */
  constructor(data: object) {
    Object.assign(this, data);
    this.assignDates(data, 'createdAt', 'updatedAt', 'deletedAt');
  }

  /**
   * Helper to assign dates, if date is present in the data object, creates and assigns a new Date object.
   * @param data
   * @param dateNames
   */
  protected assignDates(data: object, ...dateNames: string[]) {
    if (data) {
      for (const dateName of dateNames) {
        if (data.hasOwnProperty(dateName) && this[dateName]) {
          // Old .replace(/-/g, '/')
          this[dateName] = new Date(data[dateName]);
        }
      }
    }
  }
}
