<div class="close-container">
    <span class="close-box">
        <mat-icon (click)="closeDialog()" svgIcon="close"></mat-icon>
    </span>
</div>
<div fxLayout="row wrap" fxLayoutAlign="space-around center" mat-dialog-content>
  <mat-icon class="info-icon" svgIcon="information"></mat-icon>
  <p fxFlex="100">{{ navigationWarningDescription }}</p>
</div>

<div class="button-container" fxLayout="row" fxLayoutAlign="space-between" mat-dialog-actions>
  <button (click)="closeDialog(false,false)" class="discard-changes-button" color="primary" id="leave-button"
          mat-button>
    {{ leaveButtonText }}
  </button>

  <button (click)="closeDialog(false, true)" color="primary" id="stay-button" mat-raised-button>
    {{ saveAndLeaveButtonText }}
  </button>
</div>
