import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { ConfigurationComponent } from '@app/home/configuration/configuration.component';
import { WristbandsService } from '@app/shared/services/wristbands.service';

import { BracelitEvent } from '@shared/models/bracelit/bracelit-event';
import { AuthenticationService, ThemeService } from '@shared/services';

import { ContactQrComponent } from './contact-qr/contact-qr.component';
import { ReadQrComponent } from './read-qr/read-qr.component';
import { SeeQrComponent } from './see-qr/see-qr.component';

import { InfoUserFormComponent } from './info-user-form/info-user-form.component';
import { DomainService } from '@app/shared/services/domain.service';
import { TranslateConfigService } from '@app/shared/services/translate-config.service';

@Component({
    selector: 'app-lead-scanner',
    templateUrl: './lead-scanner.component.html',
    styleUrls: ['./lead-scanner.component.scss']
  })
  export class LeadScannerComponent implements OnInit {
    eventId = '';
    event: BracelitEvent;
    userId: string;
    modalMode = false;
    vCard: string;
    existsToken = false;
    text = (localStorage.getItem('lang') === 'es') ? 'Escanea el código QR para añadir contacto' : 'Scan the QR code to add contact';
  walletId: any;

  constructor(
    public dialog: MatDialog,
    public themeService: ThemeService,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    public wristbandService: WristbandsService,
    private auth_service: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private domainService: DomainService,
    private translateConfigService: TranslateConfigService,
    // @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    // @Optional() public dialogRef: MatDialogRef<LeadScannerComponent>,
  ) {
    if (auth_service.token) {
      this.existsToken = true;
    }
  }

  ngOnInit() {
    this.event = JSON.parse(localStorage.getItem('eventSelected'));
    this.eventId = this.event.id;
    this.userId = this.auth_service.user.id;
    this.wristbandService.checkBidirectional(this.eventId);
    this.generateVCARD();
    }

    generateVCARD(){
      const name = this.auth_service.client.name;
      const surname = this.auth_service.client.surname;
      const address = this.auth_service.client.address;
      const email = this.auth_service.user.email;
      const tel = this.auth_service.client.phone;

      // this.vCard = `BEGIN:VCARD\?VERSION:4.0\?N:${surname};${name}\?EMAIL:${email}\?TEL;TYPE=work:${this.tel}\?END:VCARD`
      // "BEGIN:VCARD\r\nVERSION:2.1\r\nN:Satya;Dash;;;\r\nADR;DOM;PARCEL;HOME:;;****Mission Street;Cuttack City;Orissa;94014;INDIA.\r\nEMAIL;INTERNET:satya@domain.com\r\nTEL;CELL:22-122-4567\r\nTEL;CELL:133-156-3345\r\nEND:VCARD"
      this.vCard = `BEGIN:VCARD\r\nVERSION:4.0\r\nN:${surname};${name};;;\r\nEMAIL;INTERNET:${email}\r\nTEL;CELL:${tel}\r\nEND:VCARD`;
    }


    tabClick(tab) {
      if(tab.index === 1){
        this.cameraInit();
      };
    }

    cameraInit() {
      this.existsToken = false;
      if (this.auth_service.token) {
        this.existsToken = true;
      }
      this.themeService.setIsReading(false);
      this.eventId = this.event.id;
      if (!this.themeService.getIsReading()) {
        this.route.queryParamMap.subscribe((params) => {
          const qrCode = params.get('user_id');
          if (qrCode && (!this.themeService.getIsReading())) {
            this.openUserInfo(qrCode);
          }
        });
      }
    }

    openUserInfo(qrCode) {
      if ((!(this.themeService.getIsReading()))) {
        this.themeService.setIsReading(true);

        if ((this.themeService.checkDefault === true)) {

          const dialogRef = this.dialog.open(InfoUserFormComponent, {
            height: '100%',
            width: '100%',
            panelClass: ['full-screen-dialog'],
            data: {
              value: qrCode,
              eventId: this.eventId,
              // productScanner: this.productScanner
            }
          });

          dialogRef.afterClosed().subscribe(_result => {
          });
        } else {
          const dialogRef = this.dialog.open(InfoUserFormComponent, {
            height: '100%',
            width: '100%',
            panelClass: ['full-screen-dialog'],
            data: {
              value: qrCode,
              eventId: this.eventId,
              // productScanner: this.productScanner
            }
          });

          dialogRef.afterClosed().subscribe(_result => {
          });

        }
      }
    }

    onGoBack() {
      this._location.back();
      // this.router.navigate([`/home/pulseras/${this.data.routeParams.value['wristbandId']}/monederos/${this.data.routeParams.value['walletId']}/eventos/${this.data.routeParams.value['eventId']}`]);
      // this.router.navigate([], {relativeTo: this.route});
      // this.router.navigate(['/home']);
    }


    goToPreviousPage(): void {
      if (!this.modalMode) {
        this._location.back();
        window.scroll(0, 0);
      } else {
        // this.dialogRef.close();
      }
    }
    onClose(): void {
      // this.dialogRef.close();
    }
}
