<div class="cards-container" fxLayout="row wrap" fxLayoutAlign="start start">
  <mat-card (click)="onBuyBracels()" fxLayout="row wrap" fxLayoutAlign="start start" matRipple>
    <div class="main-logo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <img [src]="'assets/bracelit/new_logo_color.png'" alt="">
    </div>
    <div class="light-30px-center-primary" fxFlex="100" fxLayoutAlign="center center">COMPRA {{coinNameMayus}}</div>
    <div class="light-18px-center-primary" fxFlex="100" fxLayoutAlign="center center">Compra {{coinNameMinus}} para poder recargar
      {{voucherLabelMinus}} o pagar en caja
    </div>
  </mat-card>

  <div (click)="onClose()" class="fake-background"></div>

  <mat-card *ngIf="hasVouchers" (click)="onRechargeVouchers()" fxLayout="row wrap" fxLayoutAlign="start start" matRipple>
    <div class="icons-row bold-16px-center-primary" fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="30px">
      <img [src]="'assets/icons/drink.svg'" alt="">
      <img [src]="'assets/icons/food&drink.svg'" alt="">
      <img [src]="'assets/icons/food.svg'" alt="">
      <img [src]="'assets/icons/empty_ticket.svg'" alt="">
    </div>
    <div class="light-30px-center-primary" fxFlex="100" fxLayoutAlign="center center">RECARGA {{voucherLabelMayus}}</div>
    <div class="light-18px-center-primary" fxFlex="100" fxLayoutAlign="center center">Recarga {{voucherLabelMinus}} con ofertas usando
      tus {{coinNameMinus}}
    </div>
    <div class="final-bracels-row bold-16px-center-primary" fxFlex="100" fxLayout="row wrap"
         fxLayoutAlign="center center">
      Tienes {{wristbandsService.selectedWallet.money | number: '1.0-0'}} {{coinNameMinus}}
      <img [src]="'assets/bracelit/new_logo_color.png'" alt="">
      <span *ngIf="wristbandsService.selectedWallet.money < 1" fxFlex="100">
        Compra {{coinNameMinus}} para recargar
      </span>
    </div>
  </mat-card>
</div>
