import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@shared/angular-material';

import { BracelitSidebarElementComponent } from './bracelit-sidebar-element/bracelit-sidebar-element.component';
import { BracelitSidebarComponent } from './bracelit-sidebar.component';

@NgModule({
  declarations: [
    BracelitSidebarComponent,
    BracelitSidebarElementComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    HammerModule
  ],
  exports: [
    BracelitSidebarComponent,
    BracelitSidebarElementComponent
  ],
  providers: []
})
export class BracelitSidebarModule {
}
