<div class="container" fxLayout="row wrap" fxLayoutAlign="start center">
    <!--  <div fxFlex="100" id="close-container" fxLayout="row" fxFlexAlign="end center">-->
    <!--    <mat-icon svgIcon="close" (click)="close()"></mat-icon>-->
    <!--  </div>-->
      <h2 fxFlex="100">{{ 'leadScanner.generate' | translate }}</h2>
    
      <!-- <bracel-qr-generator [qrData]="myForm.value"></bracel-qr-generator> -->
      <!-- <qr-code [value]="vCard" [size]="150"></qr-code> -->
      <bee-qr-generator [qrData]="vCard"></bee-qr-generator>
    
      <button fxFlex="100" class="regular-18px-center-primary"
              mat-raised-button
              type="button"
              (click)="closeDialog()" >
              {{ 'leadScanner.close' | translate }}
      </button>
    </div>
    

