import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxUploaderModule } from 'ngx-uploader';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { BracelitPreloaderModule } from '@bee-components/common/bracelit-preloader';

import { MaterialModule } from '../../angular-material/material.module';
import { VoucherDetailsComponent } from './';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        NgxUploaderModule,
        VirtualScrollerModule,
        BracelitPreloaderModule
    ],
    declarations: [
        VoucherDetailsComponent
    ],
    exports: [
        VoucherDetailsComponent
    ],
    providers: []
})
export class BracelitEntryComponentsModule {
}
