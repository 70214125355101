<div class="close-container">
    <span class="close-box">
        <mat-icon (click)="closeDialog()" svgIcon="close"></mat-icon>
    </span>
</div>
<div fxLayout="row wrap" fxLayoutAlign="space-around center" mat-dialog-content>
  <div class="warning-container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-icon class="warning-icon" color="warn" svgIcon="alert"></mat-icon>
    <p><b>{{ data.deleteWarningText }}</b></p>
  </div>

  <p fxFlex="100">{{ data.deleteWarningDescription }}
    <span *ngIf="data.deleteModalObjectName">{{ data.deleteModalObjectName }}</span>
    <span *ngIf="data.multipleDeleteModalObjectName">{{ data.multipleDeleteModalObjectName }}</span>
  </p>

  <form *ngIf="data.deleteReasonEnabled" [formGroup]="deleteFormGroup" fxFlex="100">
    <bee-form-item [beeFormControl]="deleteFormGroup.controls['deleteReason']" [id]="'deleteReason'"
                   [label]="data.deleteReasonLabel">
    </bee-form-item>
  </form>
</div>

<div class="button-container" fxLayout="row" fxLayoutAlign="space-between" mat-dialog-actions>
  <button (click)="closeDialog()" color="primary" id="no-click-button" mat-button type="button">
    <mat-icon *ngIf="displayButtonIcons" svgIcon="arrow-left"></mat-icon>
    {{ data.backText }}
  </button>

  <button (click)="onConfirmDelete()" [mat-dialog-close]="deleteFormGroup.controls['deleteReason'].value" color="warn" id="confirm-delete-button" mat-button
          type="button">
    <mat-icon *ngIf="displayButtonIcons" svgIcon="delete"></mat-icon>
    {{ data.deleteButtonText }}
  </button>
</div>
