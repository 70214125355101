import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap,Router } from '@angular/router';
import { WristbandsService } from '@app/shared/services/wristbands.service';

import { ApiService, AuthenticationService, ThemeService, SnackBarService } from '@shared/services';

@Component({
  selector: 'app-info-user-form',
  templateUrl: './info-user-form.component.html',
  styleUrls: ['./info-user-form.component.scss']
})
export class InfoUserFormComponent implements OnInit {
userInfoId: any;
myForm: FormGroup;
walletId: string;
wristId:string;
name: string;
email: string;
surname: string;
phone: string;
userId: string;
eventId: string;
userPass: {ScannedUserid: any};
loading = true;
infoPass: {ScannedUserid: any, hasBidirectionalLeadScanner: boolean};
infoPass1: {ScannedUserid: any, hasLeadScannerComment: boolean, comment: string, calification: number};
texto = (localStorage.getItem('lang') === 'es') ? 'Usuario ya importado' : 'User already imported';
textoProduct = 'Cerveza ya calificada';
errormessage = false;
submitted = false;
hasComment = false;
bidirectional = false;
comment: string;
calification: number;
productScanner = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public wristbandService: WristbandsService,
    public matDialog: MatDialog,
    protected dialogRef: MatDialogRef<InfoUserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    public snackBarService: SnackBarService,
    public themeService: ThemeService,
    private auth_service: AuthenticationService
  ) {
     this.myForm = this.formBuilder.group({
      name: [this.name],
      surname: [this.surname],
      email: [this.email],
      phone: [this.phone],
      comment: [this.comment]
    });
  }

  ngOnInit(): void {
    let infoDone;
    this.userPass = {ScannedUserid : this.data.value};
    this.eventId = this.data.eventId;
    this.productScanner = this.data.productScanner;
    this.userId = this.auth_service.user.id;
    this.bidirectional = this.wristbandService.bidirectional;
    infoDone = this.saveUserInfo();
    this.hasComment = this.wristbandService.comment;
  }

  saveUserInfo() {
    this.infoPass = {ScannedUserid: this.userPass.ScannedUserid, hasBidirectionalLeadScanner: this.bidirectional};
    this.apiService.post(`events/${this.eventId}/users/${this.userId}/saveRegister`, this.infoPass).subscribe(data => {
      this.name = data[0]['name'];
      this.surname = data[0]['surname'];
      this.email = data[0]['email'];
      this.phone = data[0]['phone'];
      if(data[0]['calification']){
        this.calification = data[0]['calification'];
      }
      if(data[0]['comment']){
        this.comment = data[0]['comment'];
      }
      this.loading = false;
      },
        err => {
          console.error(err);
          // this.errormessage = err['error'];
          this.errormessage = true;
          this.loading = false;
          if(this.productScanner){
            this.matDialog.closeAll();
            this.snackBarService.openSnackBar((localStorage.getItem('lang') === 'es') ? 'QR no válido.' : 'Invalid QR.');
          }
            });
  }

  saveComment() {
    if (!this.calification){
      this.calification = 0;
    }
    if (!this.myForm.value.comment){
      this.myForm.value.comment = this.comment;
    }
    this.infoPass1 = {ScannedUserid: this.userPass.ScannedUserid, hasLeadScannerComment: this.hasComment, comment: this.myForm.value.comment, calification: this.calification };
    this.apiService.post(`events/${this.eventId}/users/${this.userId}/saveComment`, this.infoPass1).subscribe(data => {
      this.loading = false;
      this.matDialog.closeAll();
      if(this.productScanner){
        this.snackBarService.openSnackBar('Cerveza valorada con éxito.');
      }else{
        this.snackBarService.openSnackBar((localStorage.getItem('lang') === 'es') ? 'Usuario agregado con éxito.' : 'User successfully added.');
      }
      //this.onClose();

      //this.location.back();
      },
        err => {
          console.error(err);
          // this.errormessage = err['error'];
          this.errormessage = true;
          this.loading = false;
            });
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
