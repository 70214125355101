<div class="container">
  <script type="text/javascript"
    src="https://www.covermanager.com/js/iframeResizer.min.js">
  </script>
  <iframe
    id="lataja-caseta" title="Reservas"
    src="https://www.covermanager.com/reservation/module_restaurant/lataja-caseta/spanish"
    frameborder="0" height="550" width="100%" onload="iFrameResize();">
  </iframe>
  <button class="white-background primary-border regular-20px-center-marine"
      mat-raised-button
      type="button"
      style="box-shadow: none; border: 1px solid; width: 200px; margin-top: 30px;"
      (click)="close()" >
  {{ 'qrDialog.close' | translate }}
  </button>
</div>
