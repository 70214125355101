<div [ngClass]="iconsClass" class="icons-container" fxLayoutAlign="center center">
  <mat-icon (click)="onClickFullStar(fullIconIndex)" *ngFor="let icon of fullIcons; index as fullIconIndex"
            [ngClass]="fullIconClass" [ngStyle]="{'width.px': iconsSize, 'height.px': iconsSize}"
            [svgIcon]="fullIcon"></mat-icon>
  <mat-icon (click)="onClickHalfStar()" *ngIf="halfIcons"
            [ngClass]="halfIconClass" [ngStyle]="{'width.px': iconsSize, 'height.px': iconsSize}"
            [svgIcon]="halfIcon"></mat-icon>
  <mat-icon (click)="onClickEmptyStar(emptyIconIndex)" *ngFor="let icon of emptyIcons; index as emptyIconIndex"
            [ngClass]="emptyIconClass" [ngStyle]="{'width.px': iconsSize, 'height.px': iconsSize}"
            [svgIcon]="emptyIcon"></mat-icon>
</div>
