import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

/**
 * TitleMetaService.
 */
@Injectable()
export class TitleMetaService {

  /**
   * Constructor.
   * @param titleService
   * @param metaService
   */
  constructor(private titleService: Title,
              private metaService: Meta) {
  }

  /**
   * Sets the title for the page.
   * @param title
   */
  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  /**
   * Sets the metadata.
   * @param definition
   * @param selector
   */
  public setMeta(definition: MetaDefinition, selector: string = null) {
    if (selector && this.metaService.getTag(selector)) {
      this.metaService.updateTag(definition, selector);
    } else {
      this.metaService.addTag(definition);
    }
  }
}
