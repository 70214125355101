import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService, ThemeService } from '@shared/services';
import { DomainService } from '@shared/services/domain.service';

import { InfoUserFormComponent } from '../info-user-form/info-user-form.component';

@Component({
  selector: 'app-read-qr',
  templateUrl: './read-qr.component.html',
  styleUrls: ['./read-qr.component.scss'],
})
export class ReadQrComponent implements OnInit {
  existsToken = false;
  modalMode = false;
  text = 'Escanea el código QR para añadir contacto';
  eventId: string;
  productScanner = false;
  is_reading = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private _location: Location,
              private auth_service: AuthenticationService,
              private domainService: DomainService,
              public dialog: MatDialog,
              public themeService: ThemeService,
              @Optional() public dialogRef: MatDialogRef<ReadQrComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null
  ) {
    if (auth_service.token) {
      this.existsToken = true;
    }
    if (this.domainService.event && !this.domainService.event.hasWristbands) {
    }
  }

  ngOnInit(): void {
    this.themeService.setIsReading(false);
    this.eventId = this.data.event.id;
    this.productScanner = this.data.productScanner;
    if (!this.themeService.getIsReading()) {
      this.route.queryParamMap.subscribe((params) => {
        const qrCode = params.get('user_id');
        if (qrCode && (!this.themeService.getIsReading())) {
          this.openUserInfo(qrCode);
        }
      });
    }
  }

  openUserInfo(qrCode) {
    if ((!(this.themeService.getIsReading()))) {
      this.themeService.setIsReading(true);

      if ((this.themeService.checkDefault === true)) {

        const dialogRef = this.dialog.open(InfoUserFormComponent, {
          height: '100%',
          width: '100%',
          panelClass: ['full-screen-dialog'],
          data: {
            value: qrCode,
            eventId: this.eventId,
            productScanner: this.productScanner
          }
        });

        dialogRef.afterClosed().subscribe(_result => {
        });
      } else {
        const dialogRef = this.dialog.open(InfoUserFormComponent, {
          height: '100%',
          width: '100%',
          panelClass: ['full-screen-dialog', this.themeService.selectedBackground],
          data: {
            value: qrCode,
            eventId: this.eventId,
            productScanner: this.productScanner
          }
        });

        dialogRef.afterClosed().subscribe(_result => {
        });

      }
    }
  }

  goToPreviousPage(): void {
    if (!this.modalMode) {
      this._location.back();
      window.scroll(0, 0);
    } else {
      this.dialogRef.close();
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
