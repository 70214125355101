<aside>
  <div [id]="sidebarId" *ngIf="!widthsAsPercentages"
       class="sidebar"
       [ngClass]="{'image-icon': imageIcon,'top-0': fullHeight}"
       [ngStyle]="{
         'width.px' : displaySidebar ? expandedWidth : collapsedWidth,
         'max-width.px' : displaySidebar ? expandedWidth : collapsedWidth,
         'height' : headerHeight ? 'calc(100% - '+headerHeight+'px)' : '100%'}" style="z-index: 999;"
       (swipeleft)="onHideBackdrop()" (swiperight)="onShowBackdrop()">

    <ng-container [ngTemplateOutlet]="sidebarContent"></ng-container>
  </div>

  <div [id]="sidebarId" *ngIf="widthsAsPercentages"
       class="sidebar"
       [ngClass]="{'image-icon': imageIcon,'top-0': fullHeight}"
       [ngStyle]="{
         'width.%' : displaySidebar ? expandedWidth : collapsedWidth,
         'max-width.px' : displaySidebar ? expandedWidth : collapsedWidth,
         'height' : headerHeight ? 'calc(100% - '+headerHeight+'px)' : '100%'}" style="z-index: 999;"
       (swipeleft)="onHideBackdrop()" (swiperight)="onShowBackdrop()">

    <ng-container [ngTemplateOutlet]="sidebarContent"></ng-container>
  </div>

  <ng-template #sidebarContent>
    <div class="sidebar-container sidebar-background collapse-item"
         [ngClass]="{'primary-background': backgroundColor === 'primary'}"  style="max-height: 100vh; overflow-y: scroll; z-index: 999;">

      <!--<ng-content></ng-content>-->
      <div class="sidebar-header-and-list-container">
        <div [ngStyle]="{'min-width.px' : displaySidebar ? expandedWidth : collapsedWidth}">
          <ng-template class='sidebar-header' [ngTemplateOutlet]="sidebarHeader"></ng-template>
        </div>

        <ul [ngClass]="{'with-help-trigger': helpMessage}" style="margin: 7px 0;"
            [ngStyle]="{'min-width.px' : displaySidebar ? expandedWidth : collapsedWidth}">
          <bracelit-sidebar-element *ngFor="let sidebarElement of sidebarElements; let i = index"
                               [sidebarElement]="sidebarElement"
                               [subElementsActive]="elementClicked == i"
                               [fullWidth]="displaySidebar"
                               [imageIcon]="imageIcon"
                               [ngClass]="{'last-element': sidebarElement.isLastItem,
                             'active-nav-element': displaySidebar,
                             'non-active-nav-element': !displaySidebar}"
                               [customElementClass]="customElementClass"
                               (click)="onClickElement(i, sidebarElement)"
                               (headerClicked)="handleHeaderClicked($event)"
                               (elementClickedCloseSidebar)="onCollapseSidebar()">
          </bracelit-sidebar-element>
        </ul>
      </div>

      <div class="sidebar-no-options-content">
        <div *ngIf="sidebarExtraContent" class="sidebar-extra-content-container">
          <ng-template [ngTemplateOutlet]="sidebarExtraContent"></ng-template>
        </div>

        <div class="sidebar-header-and-list-container">
          <ul [ngClass]="{'with-help-trigger': helpMessage}" style="margin: 7px 0;"
              [ngStyle]="{'min-width.px' : displaySidebar ? expandedWidth : collapsedWidth}">
            <bracelit-sidebar-element *ngFor="let sidebarElement of sidebarElements2; let i = index"
                                 [sidebarElement]="sidebarElement"
                                 [subElementsActive]="elementClicked2 == i"
                                 [fullWidth]="displaySidebar2"
                                 [imageIcon]="imageIcon"
                                 [ngClass]="{'last-element': sidebarElement.isLastItem,
                               'active-nav-element': displaySidebar2,
                               'non-active-nav-element': !displaySidebar2}"
                                 [customElementClass]="customElementClass"
                                 (click)="onClickElement2(i, sidebarElement)"
                                 (headerClicked)="handleHeaderClicked($event)"
                                 (elementClickedCloseSidebar)="onCollapseSidebar2()">
            </bracelit-sidebar-element>
          </ul>
        </div>

        <div *ngIf="sidebarExtraContent2" class="sidebar-extra-content-container">
          <ng-template [ngTemplateOutlet]="sidebarExtraContent2"></ng-template>
        </div>

        <div *ngIf="helpMessage && displaySidebar" class="help-trigger" (click)="onHelp()">
          {{ helpMessage }}
        </div>

        <div *ngIf="collapseRow" class="collapse-item last-element" (click)="onCollapseSidebar()"
             [matTooltip]="displaySidebar ? collapseTooltip : expandTooltip"
             matTooltipPosition="right">
            <span class="collapse-icon">
                <mat-icon *ngIf="!displaySidebar" svgIcon="arrow-collapse-right"></mat-icon>
                <mat-icon *ngIf="displaySidebar" svgIcon="arrow-expand-left"></mat-icon>
            </span>
        </div>
      </div>
    </div>
  </ng-template>
</aside>

<div *ngIf="hideaway && sidebarSide === 'left'"
     [ngClass]="{'backdrop-expanded': displaySidebar, 'backdrop-hidden': !displaySidebar}"
     class="sidebar-backdrop dark-background"
     (click)="onHideBackdrop()"
     (swipeleft)="onHideBackdrop()" (swiperight)="onShowBackdrop()">
</div>

<div *ngIf="hideaway && sidebarSide === 'right'"
     [ngClass]="{'backdrop-expanded': displaySidebar, 'backdrop-hidden': !displaySidebar}"
     class="sidebar-backdrop dark-background"
     (click)="onHideBackdrop()"
     (swipeleft)="onShowBackdrop()" (swiperight)="onHideBackdrop()">
</div>

<div *ngIf="hideawayButton" class="sidebar-collapse-button" (click)="onCollapseSidebar()">
  <!--<img src="assets/icons/menu.svg" alt="">-->
  <ng-template [ngTemplateOutlet]="sidebarHideawayButton"></ng-template>
</div>
