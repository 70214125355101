import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { Bracelit404Module } from '@app/shared/bee-components/bracelit-404';
import { BracelitEmailModule } from '@app/shared/bee-components/bracelit-email';
import { BeeFormModule } from '@bee-components/bee-form';
import { BracelitMobileHeaderComponent } from '@bee-components/bracelit-mobile-header/bracelit-mobile-header.component';
import { BracelitRatingModule } from '@app/shared/bee-components/bracelit-rating';
import { BracelitSidebarModule } from '@bee-components/bracelit-sidebar';
import { BeeConfirmDeleteModule } from '@bee-components/common/bee-confirm-delete';
import { BeeConfirmNavigationModule } from '@bee-components/common/bee-confirm-navigation';
import { BeeDirectivesModule } from '@bee-components/common/bee-directives/bee-directives.module';
import { BeeFileUploaderModule } from '@bee-components/common/bee-file-uploader';
import { BeeImagePreviewModule } from '@bee-components/common/bee-image-preview';
import { BeePipesModule } from '@bee-components/common/bee-pipes';
import { BracelitPreloaderModule } from '@bee-components/common/bracelit-preloader';
import { BeeQrModule } from '@bee-components/common/bee-qr';

import { MaterialModule } from './angular-material';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ApiService, SnackBarService } from './services';
import { ShoppingCartService } from '@shared/services';
import { WristbandsService } from './services/wristbands.service';
import { SignComponent } from './components/sign/sign.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
  declarations: [
    BracelitMobileHeaderComponent,
    ConfirmationDialogComponent,
    SignComponent,
    NotificationsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxChartsModule,
    MaterialModule,
    VirtualScrollerModule,
    Bracelit404Module,
    BracelitEmailModule,
    BeeFormModule,
    BracelitRatingModule,
    BeeConfirmDeleteModule,
    BeeConfirmNavigationModule,
    BeeFileUploaderModule,
    BeeImagePreviewModule,
    BracelitPreloaderModule,
    BeeQrModule,
    BeePipesModule,
    BeeDirectivesModule,
    BracelitSidebarModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    NgxChartsModule,
    MaterialModule,
    VirtualScrollerModule,
    Bracelit404Module,
    BracelitEmailModule,
    BeeFormModule,
    BracelitRatingModule,
    BeeConfirmDeleteModule,
    BeeConfirmNavigationModule,
    BeeFileUploaderModule,
    BeeImagePreviewModule,
    BracelitPreloaderModule,
    BeeQrModule,
    BeePipesModule,
    BeeDirectivesModule,
    BracelitSidebarModule,
    BracelitMobileHeaderComponent,
    SignComponent,
  ],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ApiService,
        DatePipe,
        SnackBarService,
        WristbandsService,
        ShoppingCartService
      ]
    };
  }
}
