import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WristbandsService } from '@app/shared/services/wristbands.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public notifications = [];
  public events = [];
  public titleText = (localStorage.getItem('lang') === 'es') ? 'Notificaciones' : 'Notifications';
  public messageText = (localStorage.getItem('lang') === 'es') ? 'No hay notificaciones recibidas' : 'No notifications received';
  public typeText = (localStorage.getItem('lang') === 'es') ? 'Tipo:' : 'Type:';
  public loading = true;

  constructor(public dialogRef: MatDialogRef<NotificationsComponent>,
              public datePipe: DatePipe,
              public matDialog: MatDialog,
              private wristbandsService: WristbandsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                dialogRef.disableClose = true;
              }

  ngOnInit(): void {
    this.events = this.data.events;
    this.wristbandsService.getNotifications().subscribe(data => {
      let x = 0;
      let y = 0;
      this.notifications = data.sort((a, b) => {
        x = (a.createdAt);
        y = (b.createdAt);

        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      });
      for (const event of this.events) {
        for (const notification of this.notifications) {
          if (notification.eventId === event.id) {
            notification.eventName = event.name;
          }
        }
      }
      this.loading = false;
      },
      err => {
        console.error(err);
      });    
  }

  onClose(): void {
    this.dialogRef.close();
  }

  expandNotification(id: any){
    var elemento = document.getElementById(id)
    if(elemento.style.webkitLineClamp !== undefined && elemento.style.webkitLineClamp !== null && elemento.style.webkitLineClamp !== ''){
      elemento.style.webkitLineClamp = null
    } else {
      elemento.style.webkitLineClamp = "3"
    }
  }
}
