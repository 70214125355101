import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Animation for the submit trigger. Disabled => Enabled animation.
 */
export const submitStateTrigger = trigger('submitState', [
  state('valid', style({
    opacity: 1
  })),
  state('invalid', style({
    opacity: 0.6
  })),
  transition('invalid => valid', [
    style({
      opacity: 1
    }),
    animate('300ms ease-out', style({
      transform: 'scale(1.15)',
      backgroundColor: 'green'
    })),
    animate('300ms ease-out')
  ]),
  transition('valid => invalid', [
    animate('300ms ease-out')
  ])
]);
