import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Client } from '@shared/models/bracelit/client';

import { environment } from '../../../environments/environment';
import { User } from '../models/user';

@Injectable()
export class AuthenticationService {
  headers: HttpHeaders;
  private protocol = '';
  private server = '';
  private apiUrl = '';

  constructor(private router: Router,
              private httpClient: HttpClient) {
    this.protocol = environment.protocol;
    this.server = environment.server;
    this.apiUrl = environment.apiUrl;
    this._apiRoute = this.protocol + this.server + this.apiUrl;

    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Authorization', '');

    const currentToken = JSON.parse(localStorage.getItem('token'));
    if (currentToken) {
      this._token = currentToken;
      if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined') {
        this._user = new User(JSON.parse(localStorage.getItem('user')));
      }
      if (localStorage.getItem('clientId') && localStorage.getItem('clientId') !== 'undefined') {
        this._clientId = JSON.parse(localStorage.getItem('clientId'));
      }
      if (localStorage.getItem('client') && localStorage.getItem('client') !== 'undefined') {
        this._client = new Client(JSON.parse(localStorage.getItem('client')));
      }
      if (this.getAccountTheme() && this.getAccountTheme() !== 'undefined') {
        this._accountTheme = JSON.parse(this.getAccountTheme());
      }
      if (localStorage.getItem('redirectUrl') && localStorage.getItem('redirectUrl') !== 'undefined') {
        this._redirectUrl = JSON.parse(localStorage.getItem('redirectUrl'));
      }
    }
  }

  private _token: string;

  get token(): string {
    if (this._token) {
      return this._token;
    } else {
      return '';
    }
  }

  set token(value: string) {
    this._token = value;
    localStorage.setItem('token', JSON.stringify(this.token));
  }

  private _redirectUrl: string;

  get redirectUrl(): string {
    if (this._redirectUrl) {
      return this._redirectUrl;
    } else {
      return '';
    }
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
    localStorage.setItem('redirectUrl', JSON.stringify(this._redirectUrl));
  }

  private _user: User;

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
    this._user$.next(this._user);
    localStorage.setItem('user', JSON.stringify(this._user));
  }

  private _user$: Subject<User> = new Subject<User>();

  get user$(): Subject<User> {
    return this._user$;
  }

  private _accountTheme: string;

  get accountTheme(): string {
    return this._accountTheme;
  }

  set accountTheme(value: string) {
    this._accountTheme = value;
    localStorage.setItem('accountTheme', JSON.stringify(this._accountTheme));

    this.setAccountTheme(this._accountTheme);
  }

  private _clientId: string;

  get clientId(): string {
    return this._clientId;
  }

  set clientId(value: string) {
    this._clientId = value;
    localStorage.setItem('clientId', JSON.stringify(this._clientId));
  }

  private _client: Client;

  get client(): Client {
    return this._client;
  }

  set client(value: Client) {
    this._client = value;
    localStorage.setItem('client', JSON.stringify(this._client));
  }

  private _apiRoute: string = this.protocol + this.server + this.apiUrl;

  get apiRoute(): string {
    return this._apiRoute;
  }

  set apiRoute(value: string) {
    this._apiRoute = value;
    this._apiRoute$.next(value);
  }

  private _apiRoute$ = new Subject<string>();

  get apiRoute$(): Subject<string> {
    return this._apiRoute$;
  }

  login(data: any): Observable<boolean> {
    if (this.token) {
      this.resetUser();
    }

    return this.authenticate(this._apiRoute + 'basicLogin', data);
  }

  loginWegow(data: any) {
    if (this.token) {
      this.resetUser();
    }

    this.token = 'Bearer ' + data['token'];
    if (data['User']) {
      this.user = new User(data['User']);
    }
    if (data['Client']) {
      this.client = new Client(data['Client']);
      this.clientId = this.client.id;
    }
    
    return true;
  }

  loginGoogle(data: any){
    if (this.token) {
      this.resetUser();
    }

    this.token = 'Bearer ' + data['token'];
    if (data['User']) {
      this.user = new User(data['User']);
    }
    if (data['Client']) {
      this.client = new Client(data['Client']);
      this.clientId = this.client.id;
    }
    
    return true;
  }

  logout(redirectRoute: string = '/'): void {
    this.resetUser();
    this.apiRoute = this.protocol + this.server + this.apiUrl;
    if (redirectRoute) {
      this.router.navigate([redirectRoute]);
    }
  }

  private resetUser() {
    this._token = null;
    this.user = null;
    this._clientId = null;
    this.client = null;
    this._accountTheme = null;
    localStorage.removeItem('physical_id');
    localStorage.removeItem('paymentGateway');
    localStorage.removeItem('chinoCode');
    localStorage.removeItem('accountId');
    localStorage.removeItem('voucherPurchaseData');
    localStorage.removeItem('productPurchaseData');
    localStorage.removeItem('voucherPurchaseWristband');
    localStorage.removeItem('voucherPurchaseBracels');
    localStorage.removeItem('selectedWalletId');
    localStorage.removeItem('wristbands');
    localStorage.removeItem('selectedWristbandId');
    localStorage.removeItem('selectedEventId');
    localStorage.removeItem('user');
    localStorage.removeItem('client');
    localStorage.removeItem('clientId');
    localStorage.removeItem('eventSelected');
    localStorage.removeItem('associatedQrId');
    localStorage.removeItem('bracelsImport');
    localStorage.removeItem('ds_order');
    localStorage.removeItem('ds_order_signature');
    localStorage.removeItem('stripeOrderId');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('accountTheme');
    localStorage.removeItem('accountLogo');
    localStorage.removeItem('token');
    localStorage.removeItem('account');
    localStorage.removeItem('lang');
  }

  private authenticate(route: string, data: any): Observable<boolean> {
    return this.httpClient.post(route, data)
      .pipe(
        map((response) => {
          if (response['token']) {
            this.token = 'Bearer ' + response['token'];
            if (response['user']) {
              this.user = new User(response['user']);
            }
            if (response['client']) {
              this.client = new Client(response['client']);
              this.clientId = this.client.id;
            }
            
            return true;
          } else {
            return false;
          }
        }),
        catchError((error: any) => this.handleError(error))
      );
  }

  authenticateRegister(response: any): boolean {
    if (response['token']) {
      this.token = 'Bearer ' + response['token'];
      if (response['User']) {
        this.user = new User(response['User']);
      }
      if (response['Client']) {
        this.client = new Client(response['Client']);
        this.clientId = this.client.id;
      }
      return true;
    } else {
      return false;
    }
  }

  private handleError(err: HttpErrorResponse | any) {
    if (err.status === 500 && !err.error.error) {
      return throwError('Ha ocurrido un error en el servidor.');
    }

    return throwError(err.error.error);
  }

  getAccountTheme(): string {
    return this._accountTheme;
  }

  setAccountTheme(value: string) {
    this._accountTheme = value;
  }
}
