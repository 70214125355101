import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { QRCodeModule } from 'angular2-qrcode';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AppCustomPreloader } from '@shared/preloader/app-custom-preloader';
import {
  AuthenticationService,
  PwaService,
  TitleMetaService,
  ToggleSidebarService
} from '@shared/services';
import { DomainService } from '@shared/services/domain.service';
import { DEFAULT_THEME, ThemeService } from '@shared/services/theme.service';
import { SharedModule } from '@shared/shared.module';

import { Bracelit404Module } from '@app/shared/bee-components/bracelit-404/bracelit-404.module';
import { BEE_FORM_ITEM_DEFAULT_APPEARANCE } from '@bee-components/common/bee-forms/bee-form-item-default-appearance';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InfoDevModule } from './home/wristband-details/event-details/info-dev/info-dev.module';
import { InfoUserFormModule } from './home/wristband-details/event-details/lead-scanner/info-user-form/info-user-form.module';
import { LeadScannerModule} from './home/wristband-details/event-details/lead-scanner/lead-scanner.module'
import { SeeQrModule} from './home/wristband-details/event-details/lead-scanner/see-qr/see-qr.module';
import { ShowContactsModule} from './home/wristband-details/event-details/lead-scanner/show-contacts/show-contacts.module';
import { ShowInfoUserModule} from './home/wristband-details/event-details/lead-scanner/show-contacts/show-info-user/show-info-user.module';
import { EventDetailsModule } from './home/wristband-details/event-details/event-details.module';
import { PrivacityPolicyModule } from './auth/privacity-policy/privacity-policy.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateConfigService } from './shared/services/translate-config.service';
import { LegalNoticeModule } from './auth/legal-notice/legal-notice.module';
import { Router } from "@angular/router";

registerLocaleData(localeEs);

export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * AppModule, main module of the app.
 */
@NgModule({
  declarations: [
    AppComponent,
    // NewWristbandComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.workerRegister}),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    HammerModule,
    Bracelit404Module,
    InfoUserFormModule,
    InfoDevModule,
    SeeQrModule,
    LeadScannerModule,
    ShowContactsModule,
    LegalNoticeModule,
    ShowInfoUserModule,
    EventDetailsModule,
    PrivacityPolicyModule,
    QRCodeModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (rootLoaderFactory),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthenticationService,
    PwaService,
    TitleMetaService,
    ToggleSidebarService,
    AppCustomPreloader,
    ThemeService,
    NgxImageCompressService,
    TranslateConfigService,
    DomainService,
    {provide: DEFAULT_THEME, useValue: 'bracelit-theme'},
    {provide: BEE_FORM_ITEM_DEFAULT_APPEARANCE, useValue: 'standard'},
    {provide: LOCALE_ID, useValue: 'es'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
