import { Component, Input, OnInit } from '@angular/core';

/**
 * BeeQrGeneratorComponent.
 */
@Component({
  selector: 'bee-qr-generator',
  templateUrl: './bee-qr-generator.component.html',
  styleUrls: ['./bee-qr-generator.component.scss'],
})
export class BeeQrGeneratorComponent implements OnInit {

  @Input() qrSize = 256;
  @Input() qrMargin = 4;
  @Input() qrScale = 4;
  @Input() qrErrorCorrectionLevel: 'L' | 'M' | 'Q' | 'H' = 'M';
  @Input() qrData: string;
  @Input() qrCssClass: string;
  @Input() qrElementType: 'url' | 'img' | 'canvas' | 'svg' = 'canvas';
  @Input() qrColorLight = '#ffffff';
  @Input() qrColorDark = '#000000';
  @Input() allowEmptyString = false;

  constructor() {
  }

  ngOnInit() {
  }
}
