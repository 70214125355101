<div [ngClass]="{'with-margin': withMargin}" class="theme-selector-container">
  <div [matMenuTriggerFor]="themeMenu" class="theme-selector" matTooltip={{tooltipText}}>
        <span class="theme-selector-icon">
            <mat-icon svgIcon="pencil"></mat-icon>
        </span>
  </div>
  <span *ngIf="text" class="theme-selector-text">{{ text }}</span>
</div>

<mat-menu #themeMenu="matMenu" [overlapTrigger]="false" class="theme-picker-menu">
  <mat-grid-list cols="5">
    <mat-grid-tile *ngFor="let theme of themes">
      <div (click)="selectTheme(theme);$event.stopPropagation()" mat-menu-item [style.background]="theme.primary" class="theme-picker-primary">
        <div [ngClass]="{'swatch-border': theme.primary == '#FFFFFF'}" class="theme-picker-swatch">
          <mat-icon *ngIf="themeService.selectedTheme == theme.theme" class="theme-chosen-icon">
            done
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-menu>
