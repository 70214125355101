<bee-form (closeModalContainer)="closeDialog()"
          (postSubmitFunction)="onPostSubmit($event)"
          (preSubmitFunction)="onPreSubmit()"
          [apiRoute]="apiRoute"
          [beeFormGroup]="myForm"
          [disabledClauses]="emailCandidates.length === 0"
          [insideModalTitle]="title"
          [insideModal]="true"
          [saveButtonText]="'Enviar'"
          errorMessage="Error al crear el mensaje, inténtalo de nuevo"
          toastMessage="Mensaje enviado con éxito">

  <div class="message-candidates" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
    <div *ngIf="emailCandidates.length > 1" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
      <span>{{ emailCandidates.length }} Destinatarios:</span>

      <div (click)="onShowCandidates()" *ngIf="!showCandidates"
           class="show-candidates-container primary-color" fxLayout="row" fxLayoutAlign="start center">
        <span>Mostrar</span>
        <mat-icon [svgIcon]="'chevron-down'"></mat-icon>
      </div>
      <div (click)="onShowCandidates()" *ngIf="showCandidates"
           class="show-candidates-container primary-color" fxLayout="row" fxLayoutAlign="start center">
        <span>Ocultar</span>
        <mat-icon [svgIcon]="'chevron-up'"></mat-icon>
      </div>
    </div>
    <span *ngIf="emailCandidates.length == 1">Destinatario:</span>
    <mat-chip-list>
      <ng-container *ngIf="nameCandidates.length >= 1 && showCandidates || nameCandidates.length == 1">
        <mat-chip (removed)="onRemoveNameCandidate(candidate, i)" *ngFor="let candidate of nameCandidates; index as i" [removable]="nameCandidates.length > 1"
                  class="email-chip" selected="true">
          {{ candidate }}
          <mat-icon *ngIf="nameCandidates.length > 1" class="primary-color" matChipRemove svgIcon="close"></mat-icon>
        </mat-chip>
      </ng-container>

      <ng-container *ngIf="!hideEmails && (emailCandidates.length == 1 || showCandidates)">
        <mat-chip (removed)="onRemoveCandidate(candidate)" *ngFor="let candidate of emailCandidates" [removable]="emailCandidates.length > 1"
                  class="email-chip" selected="true">
          {{ candidate }}
          <mat-icon *ngIf="emailCandidates.length > 1" class="primary-color" matChipRemove svgIcon="close"></mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  </div>

  <bee-form-item [beeFormControl]="myForm.controls['subject']" [id]="'subject'" [label]="'Asunto'"
                 [placeholder]="'Escribe aquí tu mensaje'"
                 [textAreaMinRows]="12"
                 fxFlex="100"></bee-form-item>

  <bee-form-item [beeFormControl]="myForm.controls['content']" [id]="'content'" [label]="'Contenido'" [placeholder]="'Escribe aquí tu mensaje'"
                 [textAreaMinRows]="12"
                 [type]="'textarea'"
                 fxFlex="100"></bee-form-item>

  <bee-file-uploader [allowedFileFormats]="['*']"
                     [beeFormControl]="myForm.controls['documents']"
                     [category]="'document'"
                     [dropBoxText]="'Arrastrar documento aquí'"
                     [inputText]="'AGREGAR DOCUMENTO'"
                     [title]="''">
  </bee-file-uploader>

  <div class="files-tip" fxFlex="100">
    {{ filesTip }}
  </div>

  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
    <bee-form-item [beeFormControl]="myForm.controls['isEmail']" [helpTooltip]="isEmailTooltip" [id]="'content'" [label]="'Enviar también por email'"
                   [type]="'checkbox'"
                   fxFlex="50"
                   fxFlex.lt-md="100"></bee-form-item>

    <div *ngIf="emailCandidates.length === 0" class="warn-color no-candidates-tip" fxFlex="50"
         fxFlex.lt-md="100" fxLayout="row" fxLayoutAlign="end center">
      <mat-icon svgIcon="alert-circle-outline"></mat-icon>
      <span>{{ noCandidatesTip }}</span>
    </div>
  </div>
</bee-form>
