import { Component, Input } from '@angular/core';

import { ThemeService } from '@shared/services';

/**
 * BeeThemeSelectorComponent, button with a menu of the different themes to select.
 */
@Component({
  selector: 'bee-theme-selector',
  templateUrl: './bee-theme-selector.component.html',
  styleUrls: ['./bee-theme-selector.component.scss']
})
export class BeeThemeSelectorComponent {
  /**
   * Tooltip in the theme selector button.
   * @type {string}
   */
  @Input() tooltipText = 'Selecciona un tema';
  /**
   * Additional text, info.
   * @type {string}
   */
  @Input() text = '';
  /**
   * Apply/Not apply the margin. (SCSS)
   * @type {boolean}
   */
  @Input() withMargin = true;

  /**
   * The array of themes
   */
  themes = [
    {
      primary: '#E53935',
      theme: '"bee-red-theme"'
    },
    {
      primary: '#D81B60',
      theme: '"bee-pink-theme"'
    },
    {
      primary: '#8e24aa',
      theme: '"bee-purple-theme"'
    },
    {
      primary: '#573c87',
      theme: 'bee-deep-purple-theme'
    },
    {
      primary: '#0A6C2D',
      theme: 'bee-deep-green-theme'
    },
    {
      primary: '#43A047',
      theme: 'bee-green-theme'
    },
    {
      primary: '#00897B',
      theme: 'bee-turquoise-theme'
    },
    {
      primary: '#3897A6',
      theme: 'bee-titeam-theme'
    },
    {
      primary: '#01ACC1',
      theme: 'bee-cyan-theme'
    },
    {
      primary: '#1E88E5',
      theme: 'bee-blue-theme'
    },
    {
      primary: '#3948AB',
      theme: 'bee-deep-blue-theme'
    },
    {
      primary: '#FDD835',
      theme: 'bee-yellow-theme'
    },
    {
      primary: '#CF9307',
      theme: 'bee-amber-theme'
    },
    {
      primary: '#D17603',
      theme: 'bee-orange-theme'
    },
    {
      primary: '#BC3308',
      theme: 'bee-deep-orange-theme'
    },
    {
      primary: '#1E1E1E',
      theme: 'bee-black-theme'
    },
    {
      primary: '#4E4E4E',
      theme: 'bee-deep-grey-theme'
    },
    {
      primary: '#546E7A',
      theme: 'bee-blue-grey-theme'
    },
    {
      primary: '#757575',
      theme: 'bee-grey-theme'
    },
    {
      primary: '#6D4C41',
      theme: 'bee-brown-theme'
    },
  ];

  /**
   * Constructor.
   * @param themeService The themeService instance.
   */
  constructor(public themeService: ThemeService) {
  }

  /**
   * Select a theme in the themeService and applies it.
   * @param theme
   */
  selectTheme(theme) {
    // this.themeService._selectedTheme = theme.theme;
    localStorage.setItem('accountTheme', theme.theme);
  }
}
