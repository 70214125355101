import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

/**
 * AppCustomPreloader, preload all components/modules with data: {preload: true}
 */
@Injectable()
export class AppCustomPreloader implements PreloadingStrategy {
  /**
   * Preload a route
   * @param route
   * @param load
   * @returns {Observable<null>}
   */
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
}
