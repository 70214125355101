import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/angular-material';

import { BeeSelectorComponent } from './bee-selector.component';

@NgModule({
  declarations: [
    BeeSelectorComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    BeeSelectorComponent
  ]
})
export class BeeSelectorModule {
}
