import { BeeModel } from '@shared/abstract/bee-model';

/**
 * Class Zone, represents provinces, countries and ccaas.
 */
export class Zone extends BeeModel {
  /** The name of the zone. */
  name: string;
  /** The zipCode pattern, like 18. */
  zipCodePattern: string;
  /** The type of the zone. Can be 'province', 'country' or 'ccaa' */
  type: string;
  /** Parent zone */
  parentZone: Zone;
  /** Indicates if the zone has been selected by the user or not. */
  selected = false;

  /**
   * Constructor.
   * @param data
   */
  constructor(data: object) {
    super(data);
    if (data.hasOwnProperty('parentZone') && data['parentZone']) {
      this.parentZone = new Zone(data['parentZone']);
    }
  }
}
