<bracelit-mobile-header></bracelit-mobile-header>
<div class="header-container" style="background-color: white; margin-top: 50px">
  <div class="left-icon-container">
    <button mat-button type="button" (click)="onGoBack()">
      <mat-icon svgIcon="back" style="height: 38px; width: 38px; color: #0f223e !important; fill: #0f223e !important;" class="primary-color"></mat-icon>
    </button>
  </div>
  <div class="central-container">
    <div class="bold-20px-center-primary" style="color: #0f223e !important; fill: #0f223e !important;">Lead Scanner</div>
  </div>
  <div class="right-icon-container" style="margin-right: 25px;">
  </div>
</div>
<mat-tab-group style="padding-top: 100px !important; height: calc(100% - 100px) !important;" mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="500ms" (selectedTabChange)="tabClick($event)">
  <mat-tab label="{{ 'leadScanner.myCode' | translate }}" style="height: 100% !important;">
    <ng-template  matTabContent >
      <div class="event-details-container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
        <mat-card class="bracelit-card white-opacity-background" style="margin: 40px 0 25px 0 !important; cursor: pointer;">
          <mat-card-header class="card-header" style="padding-top: 15px !important ;padding-bottom: 15px !important; background-color: #0F223E !important;">
            {{ 'leadScanner.share' | translate }}
          </mat-card-header>
          <div class="bracelit-card-content">
            <div style="color: white; cursor: pointer; width: 100%;  height: 50%; text-align: center;"
              fxLayoutAlign="center center">
              <bee-qr-generator [qrData]="userId"></bee-qr-generator>
            </div>
          </div>
        </mat-card>

        <div class="light-14px-center-primary" fxFlex="100" fxLayoutAlign="center center" style="padding: 5px 40px; color:#A5ABB5 !important; margin-bottom: 40px">
          {{ 'leadScanner.shareMessageCode1' | translate }} {{event.name}} {{ 'leadScanner.shareMessageCode2' | translate }}
        </div>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'leadScanner.scanCode' | translate }}" style="height: 100% !important;">
    <ng-template  matTabContent class="primary-background" style="height: 100% !important;">
      <div [ngClass]="{'no-padding-bottom': existsToken, 'low-padding-top': !existsToken}" class="main-container" fxLayout="row wrap" style="padding-top: 0px !important">
        <div *ngIf="!existsToken" class="main-text light-20px-center-white" fxFlex="100" fxLayoutAlign="center center">
          {{text}}
        </div>

        <div [ngClass]="{'full-height': existsToken}" class="qr-scan-main-container" fxFlex="100" fxLayoutAlign="center center">
          <bee-qr-scan [scanFrameHeightPx]="280"
                      [scanFrameWidthPx]="280"
                      [modalMode]="false"
                      (scanResult)="openUserInfo($event)">
          </bee-qr-scan>
        </div>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'leadScanner.generate' | translate }}">
    <ng-template  matTabContent >
      <div class="event-details-container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
        <mat-card class="bracelit-card white-opacity-background" style="margin: 40px 0 25px 0 !important; cursor: pointer;">
          <mat-card-header class="card-header" style="padding-top: 15px !important ;padding-bottom: 15px !important; background-color: #0F223E !important;">
            {{ 'leadScanner.generate' | translate }}
          </mat-card-header>
          <div class="bracelit-card-content">
            <div style="color: white; cursor: pointer; width: 100%;  height: 50%; text-align: center;"
              fxLayoutAlign="center center">
              <bee-qr-generator [qrData]="vCard"></bee-qr-generator>
            </div>
          </div>
        </mat-card>

        <div class="light-14px-center-primary" fxFlex="100" fxLayoutAlign="center center" style="padding: 5px 40px; color:#A5ABB5 !important; margin-bottom: 40px">
          {{ 'leadScanner.shareContact' | translate }}
        </div>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
