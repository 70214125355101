import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BeeBackgroundImageDirective } from '@bee-components/common/bee-directives/bee-background-image.directive';
import { BeeCanvasDrawDirective } from '@bee-components/common/bee-directives/bee-canvas-draw.directive';
import { BeeDecimalsDirective } from '@bee-components/common/bee-directives/bee-decimals-directive';
import { BeeLongPressDirective } from '@bee-components/common/bee-directives/bee-long-press.directive';
import { BeePositiveNumberDirective } from '@bee-components/common/bee-directives/bee-positive-number.directive';

@NgModule({
  declarations: [
    BeeBackgroundImageDirective,
    BeeDecimalsDirective,
    BeeLongPressDirective,
    BeePositiveNumberDirective,
    BeeCanvasDrawDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BeeBackgroundImageDirective,
    BeeDecimalsDirective,
    BeeLongPressDirective,
    BeePositiveNumberDirective,
    BeeCanvasDrawDirective
  ]
})
export class BeeDirectivesModule {
}
