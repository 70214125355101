<bracelit-preloader *ngIf="loading" [absoluteCentered]="true"></bracelit-preloader>


<div fxLayout="row wrap" *ngIf="this.data.isMoneyReturned">
  <div style="display: flex !important; align-items:center !important; justify-content:right !important;
    height:fit-content !important; width:100% !important;"
    (click)="dialogRef.close()">
    <img [src]="'assets/icons/crossClose.svg'" style="cursor: pointer;">
  </div>
  <div class="bold-16px-center-marine"
    style="display: flex !important; align-items:center !important; justify-content:center !important;
      height:fit-content !important; width:100% !important; margin: 15px 0 -10px 0;">
    {{request}}
  </div>
  <form [formGroup]="myForm" *ngIf="!loading" class="login-form-container light-32px-center-white" style="display: flex; justify-content: center; flex-flow:column; width: 100%; max-width: 500px !important; margin-top: 50px"
    (ngSubmit)="save()">
    <div formGroupName="extraFields">
      <div class="primary-border" style="display: flex; justify-content: center; align-items: center; margin-bottom: 8px; width: 100%; max-width: 500px; height: 38px; border-radius: 3px; border: 1px solid;">
        <input class="light-16px-left-marine" style="width: 100%; height: 90%; border: none; background-color: rgba(240, 248, 255, 0); outline: none; padding: 0 10px;" id="event-form-name" placeholder={{nameText}} type="text" formControlName='name'>
      </div>
      <div style="display: flex; justify-content: right; align-items: center; margin-bottom: 8px; width: 100%; height: 16px; font-size: 12px; color: #e74737">
        {{errorName}}
      </div>

      <div class="primary-border" style="display: flex; justify-content: center; align-items: center; margin-bottom: 8px; width: 100%; max-width: 500px; height: 38px; border-radius: 3px; border: 1px solid;">
        <input class="light-16px-left-marine" style="width: 100%; height: 90%; border: none; background-color: rgba(240, 248, 255, 0); outline: none; padding: 0 10px;" id="event-form-surname" placeholder={{surnameText}} type="text" formControlName='surname'>
      </div>
      <div style="display: flex; justify-content: right; align-items: center; margin-bottom: 8px; width: 100%; height: 16px; font-size: 12px; color: #e74737">
        {{errorSurname}}
      </div>

      <div class="primary-border" style="display: flex; justify-content: center; align-items: center; margin-bottom: 8px; width: 100%; max-width: 500px; height: 38px; border-radius: 3px; border: 1px solid;">
        <input class="light-16px-left-marine" style="width: 100%; height: 90%; border: none; background-color: rgba(240, 248, 255, 0); outline: none; padding: 0 10px;" id="event-form-iban" placeholder={{ibanText}} type="text" formControlName='iban'>
      </div>
      <div style="display: flex; justify-content: right; align-items: center; margin-bottom: 8px; width: 100%; height: 16px; font-size: 12px; color: #e74737">
        {{errorIban}}
      </div>

      <div *ngIf="!hasQR" class="primary-border" style="display: flex; justify-content: center; align-items: center; margin-bottom: 8px; width: 100%; max-width: 500px; height: 38px; border-radius: 3px; border: 1px solid;">
        <input class="light-16px-left-marine" style="width: 100%; height: 90%; border: none; background-color: rgba(240, 248, 255, 0); outline: none; padding: 0 10px;" id="event-form-code" placeholder={{codeText}} type="text" formControlName='code'>
      </div>
      <div *ngIf="!hasQR" style="display: flex; justify-content: right; align-items: center; margin-bottom: 8px; width: 100%; height: 16px; font-size: 12px; color: #e74737">
        {{errorCode}}
      </div>
    </div>

    <button [color]="'primary'"
    [id]="'forgot-pass-submit-button'"
    [ngClass]="{'disabled-submit': !this.myForm.valid}"
    [disabled]="!this.myForm.valid"
    class="primary-background bold-20px-center-white"
    style="box-shadow: none; width: 100% !important;  max-width: 500px !important;"
    mat-raised-button>
      {{ 'login.sendPassword' | translate }}
    </button>
  </form>
</div>
