<ng-container *ngIf="loaded">
  <li *ngIf="sidebarElement.subElements.length <= 1 || !fullWidth" [routerLink]="sidebarElement.route"
      [id]="sidebarElement.route + '-sidebar-element'"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{exact: false}"
      class="single-element"
      [ngClass]="elementClasses"
      matTooltip="{{sidebarElement.name}}"
      matTooltipPosition="right"
      [matTooltipDisabled]="fullWidth"
      (click)="onClickElement()">
    <span class="border-fill-small"></span>
    <mat-icon *ngIf="sidebarElement.icon && !imageIcon" [svgIcon]="sidebarElement.icon" class="primary-color"></mat-icon>
    <img *ngIf="sidebarElement.icon && imageIcon" class="sidebar-icon" src="{{sidebarElement.icon}}"
         alt="{{sidebarElement.name}}" loading="lazy">
    <span *ngIf="fullWidth" class="element-text marine-color">{{ sidebarElement.name }}</span>
  </li>

  <li *ngIf="sidebarElement.subElements.length > 1 && fullWidth"
      [id]="sidebarElement.route + '-sidebar-element'"
      class="sub-elements"
      [ngStyle]="{'height.px': subElementsHeight}">
    <div class="sub-elements-header" (click)="emitHeaderClicked(!subElementsActive)"
         [ngClass]="{'disabled': sidebarElement.isDisabled}">
      <span class="border-fill-small"></span>
      <mat-icon *ngIf="sidebarElement.icon && !imageIcon" [svgIcon]="sidebarElement.icon"  class="primary-color"></mat-icon>
      <img *ngIf="sidebarElement.icon && imageIcon" class="sidebar-icon" src="{{sidebarElement.icon}}"
           alt="{{sidebarElement.name}}" loading="lazy">
      <span class="element-text marine-color">{{ sidebarElement.name }}</span>
      <span class="right-aligned">
                <mat-icon svgIcon="chevron-down" [ngClass]="{'sub-elements-active': subElementsActive}"></mat-icon>
            </span>
    </div>
    <div *ngIf="subElementsActive" class="sub-elements-body" @subElementState (click)="emitHeaderClicked(!subElementsActive)"
         fxFlex="100">
      <ul *ngIf="!sidebarElement.externalUrls">
        <li *ngFor="let subElement of sidebarElement.subElements"
            [id]="subElement['route'] + '-sub-element-sidebar-element'"
            [routerLink]="subElement['route']"
            class="single-element"
            [ngClass]="elementClasses"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: false}" (click)="onClickElement(subElement)">
          <span class="border-fill-small"></span>
          <span class="dot">·</span>
          <span class="element-text" class="marine-color">{{ subElement['name'] }}</span>
        </li>
      </ul>
      <ul *ngIf="sidebarElement.externalUrls">
        <li *ngFor="let subElement of sidebarElement.subElements"
            [id]="subElement['route'] + '-sub-element-sidebar-element'"
            class="single-element"
            [ngClass]="elementClasses"
            (click)="onClickElement(subElement)">
          <a [href]="subElement['route']" target="_blank" style="width: 100%;">
            <span class="border-fill-small"></span>
            <span class="dot">·</span>
            <span class="element-text" class="marine-color">{{ subElement['name'] }}</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
</ng-container>
