<div [ngClass]="{'help-enabled': helpTooltip,
     'mat-checkbox-margin': (type === 'checkbox' && checkboxMargin || type === 'slide' && checkboxMargin),
      'right-align': rightAlign}" [ngSwitch]="type"
     class="form-item">

  <ng-container *ngSwitchCase="'slider'">
    <div class="form-item-container">
      <label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></label>
      <mat-slider [color]="'primary'" [formControl]="beeFormControl" [id]="id" [max]="max" [min]="min"
                  [step]="step"
                  [thumbLabel]="true"
                  [tickInterval]="tickInterval">
      </mat-slider>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'slide'">
    <mat-slide-toggle [color]="'primary'" [formControl]="beeFormControl" [id]="id">
      <span *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></span>
    </mat-slide-toggle>
  </ng-container>

  <ng-container *ngSwitchCase="'checkbox'">
    <mat-checkbox (change)="onChange()" [color]="'primary'" [disableRipple]="!checkboxRipple" [formControl]="beeFormControl" [id]="id"
                  [name]="name">
      <span *ngIf="label" style="color: #0f223e !important">{{ label }}<span *ngIf="requiredSign && isRequired"></span></span>
    </mat-checkbox>
  </ng-container>

  <ng-container *ngSwitchCase="'boxCheckbox'">
    <div (click)="onBoxCheckBox()" [ngClass]="{'active-box-checkbox': beeFormControl.value}" class="box-checkbox">
      <span *ngIf="label">{{ label }}</span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'radio'">
    <div class="form-item-container">
      <label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></label>
      <mat-radio-group (change)="onChange()" [formControl]="beeFormControl" [id]="id" [name]="name"
                       [ngClass]="{'radio-space-between': radioSpaceBetween}">
        <div (click)="setPreviousRadioValue()" *ngFor="let option of options"
             [ngClass]="{'radio-container-with-image':option.activeIcon || option.inactiveIcon,
                 'inactive': beeFormControl.value != option.value}"
             class="radio-container">
          <div class="vertical-aligned">
            <img *ngIf="option.activeIcon && beeFormControl.value == option.value"
                 alt="option.value" src="{{option.activeIcon}}">
            <img (click)="beeFormControl.setValue(option.value)"
                 *ngIf="option.inactiveIcon && beeFormControl.value != option.value" alt="option.value" src="{{option.inactiveIcon}}">
            <mat-radio-button [color]="'primary'" [ngClass]="{'hidden': option.hideRadio}"
                              [value]="option.value">
              {{ option.name }}
            </mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'boxRadio'">
    <div class="box-radio-container">
      <label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></label>
      <div (click)="onBoxRadio(option.value)" *ngFor="let option of options" [ngClass]="{'active-box-radio': beeFormControl.value == option.value}"
           class="box-radio">
        <span>{{ option.name }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'select'">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></mat-label>
      <mat-select (selectionChange)="onChange()" [formControl]="beeFormControl" [id]="id"
                  placeholder={{placeholder}}>
        <mat-option *ngFor="let option of options" [value]="option.name">
          {{ option.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'select-multiple'">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></mat-label>
      <mat-select (selectionChange)="changed(max)" [formControl]="beeFormControl" [id]="id"
                  placeholder={{placeholder}} multiple>
        <mat-option *ngFor="let option of options" [value]="option.name" style="font-size: 12px !important;">
          {{ option.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'textarea'">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></mat-label>
      <textarea (change)="onChange()" [cdkAutosizeMinRows]="textAreaMinRows"
                [formControl]="beeFormControl" [id]="id" [name]="name"
                cdkTextareaAutosize
                matInput
                placeholder={{placeholder}}>
      </textarea>
      <mat-icon *ngIf="icon" matSuffix svgIcon={{icon}}></mat-icon>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <!--  <ng-container *ngSwitchCase="'rich'">-->
  <!--    <div class="form-item-container">-->
  <!--      <label [ngClass]="{'error': beeFormControl.invalid && beeFormControl.dirty}" [attr.for]="id">{{ label }}<span-->
  <!--          *ngIf="requiredSign && isRequired"></span></label>-->
  <!--      <quill-editor #editor [id]="id" class="quill-editor-container" [placeholder]="placeholder"-->
  <!--                    [formControl]="beeFormControl">-->
  <!--        <div quill-editor-toolbar>-->
  <!--                <span class="ql-formats">-->
  <!--                    <button class="ql-bold" [title]="'Negrita'"></button>-->
  <!--                    <button class="ql-italic" [title]="'Cursiva'"></button>-->
  <!--                    <button class="ql-underline" [title]="'Subrayado'"></button>-->
  <!--                  &lt;!&ndash;<button class="ql-strike" [title]="'Tachado'"></button>&ndash;&gt;-->
  <!--                    <button class="ql-blockquote" [title]="'Cita'"></button>-->
  <!--                </span>-->
  <!--          <span class="ql-formats">-->
  <!--                    <button class="ql-list" value="ordered" [title]="'Lista ordenada'"></button>-->
  <!--                    <button class="ql-list" value="bullet" [title]="'Lista'"></button>-->
  <!--                </span>-->
  <!--          <span class="ql-formats">-->
  <!--                    <button class="ql-indent" value="-1" [title]="'Disminuir sangría'"></button>-->
  <!--                    <button class="ql-indent" value="+1" [title]="'Aumentar sangría'"></button>-->
  <!--                </span>-->
  <!--          <span class="ql-formats">-->
  <!--                    <select class="ql-color" [title]="'Color de letra'"></select>-->
  <!--                    <select class="ql-background" [title]="'Color de fondo'"></select>-->
  <!--                </span>-->
  <!--          <span class="ql-formats">-->
  <!--                     <select class="ql-size" [title]="'Tamaño de letra'">-->
  <!--                        <option value="small">Pequeña</option>-->
  <!--                        <option selected></option>-->
  <!--                        <option value="large">Grande</option>-->
  <!--                        <option value="huge">Enorme</option>-->
  <!--                      </select>-->
  <!--                </span>-->
  <!--          <span class="ql-formats">-->
  <!--                     <select class="ql-header" [title]="'Tipos cabecera'">-->
  <!--                        <option value="1">Cabecera 1</option>-->
  <!--                        <option value="2">Cabecera 2</option>-->
  <!--                        <option value="3">Cabecera 3</option>-->
  <!--                        <option value="4">Cabecera 4</option>-->
  <!--                        <option value="5">Cabecera 5</option>-->
  <!--                        <option value="6">Cabecera 6</option>-->
  <!--                        <option selected></option>-->
  <!--                      </select>-->
  <!--                </span>-->
  <!--          <span class="ql-formats">-->
  <!--                  <select class="ql-align" [title]="'Alineamiento'">-->
  <!--                    <option selected></option>-->
  <!--                    <option value="center"></option>-->
  <!--                    <option value="right"></option>-->
  <!--                    <option value="justify"></option>-->
  <!--                  </select>-->
  <!--                </span>-->
  <!--          <span class="ql-formats">-->
  <!--                    <button class="ql-clean" [title]="'Limpiar formato'"></button>-->
  <!--                </span>-->
  <!--        </div>-->
  <!--      </quill-editor>-->
  <!--      <span class="rich-error error"-->
  <!--            *ngIf="beeFormControl.invalid && beeFormControl.dirty">{{ errorMessage(beeFormControl) }}</span>-->
  <!--    </div>-->
  <!--  </ng-container>-->

  <ng-container *ngSwitchCase="'password'">
    <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></mat-label>
      <input (change)="onChange()" [formControl]="beeFormControl"
             [id]="id" [name]="name" [placeholder]="placeholder"
             [type]="hide ? 'password' : 'text'"
             autocomplete="current-password"
             matInput>
      <button (click)="hide = !hide"
              *ngIf="passwordCanBeSeen" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
              mat-icon-button
              matSuffix type="button" style="height: auto !important;">
        <mat-icon [svgIcon]="hide ? 'eye-hidden-2023' : 'eye-show-2023'"></mat-icon>
      </button>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'date'">
    <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></mat-label>
      <!--<mat-icon *ngIf="icon" [svgIcon]="icon" matSuffix></mat-icon>-->
      <input (change)="onChange()" [formControl]="beeFormControl"
             [id]="id" [name]="name"
             [placeholder]="placeholder"
             type="date"
             matInput>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngSwitchDefault>
    <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"></span></mat-label>
      <!--<mat-icon *ngIf="icon" [svgIcon]="icon" matSuffix></mat-icon>-->
      <input (change)="onChange()" [beeDecimals]="decimals" [beePositiveNumber]="(min >= 0 && type === 'number')" [formControl]="beeFormControl" [id]="id"
             [max]="max" [min]="min" [name]="name"
             [placeholder]="placeholder"
             [step]="step"
             [type]="type"
             matInput
             [disabled]="isDisabled"
             autocomplete="off">
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <span *ngIf="helpTooltip" [ngClass]="{'help-section-with-padding': (type !== 'checkbox' && appearance !== 'standard')}"
        class="help-section">
        <mat-icon [matTooltip]="helpTooltip" [svgIcon]="helpIcon" matTooltipPosition="above"></mat-icon>
    </span>
</div>
