import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Spanish paginator range label
 * @param page
 * @param pageSize
 * @param length
 */
const spanishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 ${(localStorage.getItem('lang') === 'es') ? 'de' : 'of'} ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} ${(localStorage.getItem('lang') === 'es') ? 'de' : 'of'} ${length}`;
};

/**
 * Spanish paginator intl
 * @returns {MatPaginatorIntl}
 */
export function getSpanishPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = (localStorage.getItem('lang') === 'es') ? 'Elementos por página:' : 'Elements per page';
  paginatorIntl.nextPageLabel = (localStorage.getItem('lang') === 'es') ? 'Siguiente página' : 'Next page';
  paginatorIntl.previousPageLabel = (localStorage.getItem('lang') === 'es') ? 'Página anterior' : 'Previus page';
  paginatorIntl.firstPageLabel = (localStorage.getItem('lang') === 'es') ? 'Primera página' : 'First page';
  paginatorIntl.lastPageLabel = (localStorage.getItem('lang') === 'es') ? 'Última página' : 'Last page';
  paginatorIntl.getRangeLabel = spanishRangeLabel;

  return paginatorIntl;
}
