import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BracelitEvent } from '@shared/models/bracelit/bracelit-event';
import { CreditMove } from '@shared/models/bracelit/credit-move';
import { ApiService, ThemeService } from '@shared/services';
import { WristbandsService } from '@shared/services/wristbands.service';

import { PaymentDetailsComponent } from '../payment-details/payment-details.component';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  wristbandId: string;
  eventId: string;
  walletId: string;
  creditMoves = [];
  linesInfo: any[];
  attachInfo: any;
  loading = true;
  event: BracelitEvent;
  coinName: string;
  hasCapacity: boolean;
  headerText = (localStorage.getItem('lang') === 'es') ? 'Histórico' : 'History';
  payText = (localStorage.getItem('lang') === 'es') ? 'Pagar con ' : 'Pay with ';
  rechargeText = (localStorage.getItem('lang') === 'es') ? 'Recarga de ' : 'Top up with ';
  balanceText = (localStorage.getItem('lang') === 'es') ? 'Saldo' : 'Balance';
  vouchersText = (localStorage.getItem('lang') === 'es') ? 'Bonos' : 'Offers';

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              private wristbandsService: WristbandsService,
              public dialogRef: MatDialogRef<PaymentHistoryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public themeService: ThemeService) {
                dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.coinName = this.data.coinName !== '' ? this.data.coinName[0].toUpperCase() + this.data.coinName.slice(1) : '';
    this.eventId = this.data.eventId;
    this.hasCapacity = this.data.hasCapacity;
    if (this.data.isTicketing) {
      this.vouchersText = (localStorage.getItem('lang') === 'es') ? 'Entradas' : 'Tickets';
    }
    if (!this.hasCapacity){
      this.loadCreditMoves();
    } else {
      this.loadCreditMovesAccounts();
    }
  }

  async loadCreditMoves() {
    this.loading = true;
    this.apiService.get(`accounts/${JSON.parse(localStorage.getItem('accountId'))}/events/${this.wristbandsService.selectedEvent.id}/wristbands/${this.wristbandsService.selectedWristband.id}/historicWristband`)
      .subscribe(data => {
        this.creditMoves = data;
        this.creditMoves.forEach(element => {
          if(element.amount){element.amount = element.amount/1} 
          if(element.uses){element.uses = element.uses/1}
          if(element.paymentMethod !== null) {
            switch (element.paymentMethod) {
              case 'C':
                element.paymentMethod = ((localStorage.getItem('lang') === 'es') ? 'TPV online' : 'Online TPV');
                return // ((localStorage.getItem('lang') === 'es') ? 'Tarjeta' : 'Credit card');
              case 'tpv':
                element.paymentMethod = ((localStorage.getItem('lang') === 'es') ? 'TPV físico' : 'Physical TPV');
                return // ((localStorage.getItem('lang') === 'es') ? 'Efectivo' : 'Cash');
              case 'z':
                element.paymentMethod = 'Bizum'
                return
              case 'Google':
                element.paymentMethod = 'Google Pay'
                return;
              case 'GPay':
                element.paymentMethod = 'Google Pay'
                return;
              case 'Apple':
                element.paymentMethod = 'Apple Pay'
                return;
              case 'cash':
                element.paymentMethod = ((localStorage.getItem('lang') === 'es') ? 'Efectivo' : 'Cash');
                return;
            }
          } else {
            if(!element.createdBy.toLowerCase().includes('ecosegundos')) {
              element.paymentMethod = ((localStorage.getItem('lang') === 'es') ? 'Efectivo' : 'Cash');
            } else {
              element.recycling = 'Vaso';
            }
          }
        });
        this.creditMoves.sort((a,b) => {
          if(a.created>b.created){
            return -1;
          } else {
            return 1;
          }
        });
        this.loading = false;
      });
  }

  async loadLinesInfo(saleOrderId) {
    this.linesInfo = await this.wristbandsService.onlineOrderInfo(
      this.wristbandsService.selectedEvent.id, saleOrderId);
  }

  async loadCreditMovesAccounts() {
    this.creditMoves = await this.wristbandsService.creditMovementsAccounts(
      this.wristbandsService.selectedEvent.id, this.wristbandsService.selectedWallet.id);
      this.creditMoves.forEach(element => {
        if(element.amount){element.amount = element.amount/1} 
        if(element.uses){element.uses = element.uses/1} 
      });
      this.loading = false;
  }

  absoluteValue(value: number) {
    return Math.abs(value);
  }
  checkAction(creditMove){
    if(creditMove.creditableId && creditMove.creditableType === 'App\\SaleOrder' && creditMove.createdBy && creditMove.createdBy.includes('@')){
      let ref = creditMove.creditableId.substring(0,5).toUpperCase();
      return ((localStorage.getItem('lang') === 'es') ? 'Pedido ' : 'Order ') + ref
    } else if (creditMove.creditableType === 'App\\Return') {
      return ((localStorage.getItem('lang') === 'es') ? 'Devolución de ' : 'Return with ') + this.coinName
    } else{
      return ((localStorage.getItem('lang') === 'es') ? 'Pago con ' : 'Pay with ') + this.coinName
    }
  }

  onGoBack() {
    this.dialogRef.close();
  }

}
