<div class="mobile-header-container {{header_background_class}}" [ngStyle]="{'height.px': height}" style="z-index: 9999;">
  <div class="left-icon-container" [ngStyle]="{'margin-left.px': sidebar_margin_left}">
    <button mat-button type="button" (click)="onCollapseSidebar($event)">
      <mat-icon svgIcon="menu2023" style="color: #0f223e !important; fill: #0f223e !important"></mat-icon>
    </button>
  </div>
  <div class="central-container" (click)="onNavigateHome($event)">
    <img [src]="accountLogo" alt="" [ngStyle]="{'max-width.px': central_icon_with, 'max-height.px': central_icon_height}">
  </div>
  <div class="right-icon-container" [ngStyle]="{'margin-right.px': sidebar_margin_right}">
    <button mat-button type="button" (click)="openNotifications()"
            [matBadge]="sidebar_notifications_number" matBadgePosition="before" matBadgeColor="accent"
            [matBadgeHidden]="!checkNotifications()">
      <mat-icon svgIcon="notifications2023" style="color: #0f223e !important; fill: #0f223e !important"></mat-icon>
    </button>
  </div>
</div>
<bracelit-sidebar [abilities]="abilities"
  [abilities2]="abilities2"
  [backgroundColor]="'white'"
  [collapseRow]="false"
  [swippable]="true"
  [collapseTooltip]="collapse_tooltip"
  [displaySidebar]="display_sidebar"
  [expandTooltip]="expand_tooltip"
  [headerHeight]="0"
  [collapsedWidth]="0"
  [expandedWidth]="320"
  [fullHeight]="true"
  [hideaway]="true"
  [hideawayButton]="false"
  [widthsAsPercentages]="false"
  (collapse)="onCollapseSidebar($event)"
  (clickSidebarElement)="onSidebarElement($event)">

  <ng-template #sidebarHeader let-item>
    <div class="sidebar-header" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
      <button mat-button type="button" class="sidebar-button regular-16px-left-marine sidebar-bottom-border"
      (click)="onNavigateConfiguration('account')">
      <mat-icon svgIcon="account_new" class="primary-color" style="margin-right: 0 !important;"></mat-icon>
      <span class="button-text-marine sidebar-text">{{ this.langJson.myAccount }}</span>
      </button>
    </div>
  </ng-template>
  <ng-template #sidebarExtraContent let-item>
    <button mat-button type="button" class="sidebar-button regular-16px-left-marine sidebar-top-border"
    (click)="associateWristband()">
    <mat-icon svgIcon="scan" class="custom-icon2 primary-color"></mat-icon>
    <!-- CHAPUZA LA TAJA -->
      <span *ngIf="!domainService.event || domainService.event.domain !== 'lataja'" class="button-text-marine sidebar-text">{{ this.langJson.code.associateWristband }}</span>
      <span *ngIf="domainService.event && domainService.event.domain === 'lataja'" class="button-text-marine sidebar-text">Asociar pulsera</span>
    </button>
    <button mat-button type="button" class="sidebar-button regular-16px-left-dark-gray sidebar-top-border"
    (click)="goToMoreEvents()">
    <mat-icon svgIcon="calendar" class="custom-icon2 primary-color"></mat-icon>
    <span class="button-text-marine sidebar-text" [ngClass]="{'button-text-hidden' : !display_sidebar}">{{ this.langJson.moreEventsText }}</span>
    </button>
    <button mat-button type="button" class="sidebar-button regular-16px-left-dark-gray sidebar-top-border sidebar-bottom-border"
    (click)="onNavigateConfiguration('settings')">
    <mat-icon svgIcon="settings_white" class="custom-icon2 primary-color"></mat-icon>
    <span class="button-text-marine sidebar-text">{{ this.langJson.settingsText }}</span>
    </button>
  </ng-template>
  <ng-template #sidebarExtraContent2 let-item>
    <button mat-button type="button" class="sidebar-button regular-16px-left-dark-gray sidebar-top-border"
      (click)="closeSession()">
      <mat-icon svgIcon="shutdown_white" class="custom-icon2 primary-color"></mat-icon>
      <span class="button-text-marine sidebar-text" [ngClass]="{'button-text-hidden' : !display_sidebar}">{{ this.langJson.logOut }}</span>
    </button>
  </ng-template>
</bracelit-sidebar>

