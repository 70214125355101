<div class="close-container">
    <span class="close-box">
        <mat-icon (click)="closeDialog()" svgIcon="close"></mat-icon>
    </span>
</div>

<mat-dialog-content>
  <h1 mat-dialog-title>{{title}}</h1>

  <button (click)="rotateLeft()" mat-icon-button
          matTooltip="Rotar a la izquierda" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'rotate-left'"></mat-icon>
  </button>
  <button (click)="rotateRight()" mat-icon-button
          matTooltip="Rotar a la derecha" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'rotate-right'"></mat-icon>
  </button>
  <!--
  <mat-form-field appearance="outline">
    <mat-label>Rotación</mat-label>
    <input matInput type="number" [(ngModel)]="rotation" placeholder="Rotación" (input)="updateRotation()"
           (keyup)="updateRotation()">
  </mat-form-field>
  -->
  <button (click)="flipHorizontal()" mat-icon-button
          matTooltip="Voltear horizontal" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'flip-horizontal'"></mat-icon>
  </button>
  <button (click)="flipVertical()" mat-icon-button
          matTooltip="Voltear vertical" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'flip-vertical'"></mat-icon>
  </button>

  <button (click)="zoomOut()" mat-icon-button
          matTooltip="Zoom -" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'magnify-minus'"></mat-icon>
  </button>
  <button (click)="zoomIn()" mat-icon-button
          matTooltip="Zoom +" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'magnify-plus'"></mat-icon>
  </button>

  <button (click)="toggleContainWithinAspectRatio()" mat-icon-button
          matTooltip="Contener en relación de aspecto" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'aspect-ratio'"></mat-icon>
  </button>
  <button (click)="resetImage()" mat-icon-button
          matTooltip="Reset" matTooltipPosition="below"
          type="button">
    <mat-icon [svgIcon]="'autorenew'"></mat-icon>
  </button>

  <div id="image-cropper-container">
    <image-cropper
        (cropperReady)="cropperReady($event)"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
        [alignImage]="'center'"
        [aspectRatio]="aspectRatio"
        [canvasRotation]="canvasRotation"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [format]="format"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="maintainAspectRatio"
        [onlyScaleDown]="true"
        [roundCropper]="roundCropper"
        [style.display]="showCropper ? null : 'none'"
        [transform]="transform">
    </image-cropper>
  </div>

  <h2 class="subtitle">{{previewText}}</h2>
  <div id="image-preview-container">
    <img *ngIf="croppedImage" [src]="croppedImage"
         [style.border-radius]="roundCropper ? '100%' : 'none'"
         [style.border]="croppedImage ? '1px solid black' : 'none'"
         alt="cropped-image"/>
  </div>
  <span *ngIf="croppedFile?.size > maxFileSize" id="max-file-size-tip">{{maxFileSizeTip}}</span>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="confirmFile()" [disabled]="croppedFile?.size > maxFileSize" color="primary"
          mat-raised-button
          type="button">Aceptar
  </button>
</mat-dialog-actions>
