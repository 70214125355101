import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * BeePositiveNumber.
 * Only allows to write positive numbers.
 */
@Directive({
  selector: '[beePositiveNumber]'
})
export class BeePositiveNumberDirective implements OnInit {
  /**
   * Checks if activate the directive.
   * @type {boolean}
   */
  @Input() beePositiveNumber = true;

  /**
   * Constructor.
   * @param el
   */
  constructor(private el: ElementRef) {
  }

  /**
   * OnKeyDown event.
   * @param event
   */
  static onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', '.', ','].indexOf(e.key) !== -1 ||
      // Allow: Ctrl+A
      (e.key === 'a' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.key === 'c' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.key === 'v' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.key === 'x' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+Z
      (e.key === 'z' && (e.ctrlKey || e.metaKey)) ||
      // Allow arrows
      (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') ||
      // Allow home and end
      (e.key === 'Home' || e.key === 'End')) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.key !== '0' && e.key !== '1' && e.key !== '2' && e.key !== '3' && e.key !== '4' &&
      e.key !== '5' && e.key !== '6' && e.key !== '7' && e.key !== '8' && e.key !== '9'))) {
      e.preventDefault();
    }
  }

  /**
   * OnInit lifecycle, adds the keydown event listener.
   */
  ngOnInit(): void {
    if (this.beePositiveNumber) {
      this.el.nativeElement.addEventListener('keydown', BeePositiveNumberDirective.onKeyDown.bind(this));
    }
  }
}
