import { Component, Input } from '@angular/core';

/**
 * BracelitPreloader, shows a Material spinner.
 *
 * @example
 *
 * <bracelit-preloader [diameter]="30" [absoluteCentered]="true"></bracelit-preloader>
 */
@Component({
  selector: 'bracelit-preloader',
  templateUrl: './bracelit-preloader.component.html',
  styleUrls: ['./bracelit-preloader.component.scss'],
})
export class BracelitPreloaderComponent {
  /**
   * Diameter of the spinner.
   * @type {number}
   */
  @Input() diameter = 60;
  /**
   * Loading text.
   * @type {string}
   */
  @Input() loadingText: string = null;
  /**
   * Checks if the spinner is centered in position absolute. (SCSS)
   * @type {boolean}
   */
  @Input() absoluteCentered = false;
  /**
   * Checks if the spinner is displayed as horizontal. (SCSS)
   * @type {boolean}
   */
  @Input() horizontalDisplay = false;
}
