import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { InfoDevComponent } from './info-dev.component';

@NgModule({
    declarations: [
      InfoDevComponent
    ],
    imports: [
        TranslateModule,
        SharedModule,
    ]
})
export class InfoDevModule {
}
