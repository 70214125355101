import { Injectable } from '@angular/core';
import { BracelitEvent } from '@shared/models/bracelit/bracelit-event';
import { ApiService } from '@shared/services/api.service';

@Injectable()
export class DomainService {
  domain: string;
  event: BracelitEvent;

  constructor(private apiService: ApiService) {}

  checkDomain(): Promise<any> {
    return new Promise((resolve) => {
      const host = window.location.host;
      const parts = host.split('.');

      if (parts.length > 1) {
        this.domain = this.getDomain(parts);
      }

      // this.domain = 'elfspain';
      if (this.domain && this.domain !== 'app') {
        this.apiService.get(`public/domains/${this.domain}`).subscribe(
          response => {
            console.log('Datos obtenidos correctamente');
            if (response && response.event) {
              this.event = new BracelitEvent(response.event);
            }
            resolve(null);
          },
          error => {
            console.error('❌ Error en la API de dominio:', error);
            this.apiService.get(`public/domains/${this.domain}`).subscribe(
              response => {
                console.log('Datos obtenidos correctamente');
                if (response && response.event) {
                  this.event = new BracelitEvent(response.event);
                }
                resolve(null);
              },
              error => {
                console.error('❌ Error en la API de dominio:', error);
                resolve(null);
              }
            );
          }
        );
      } else {
        resolve(null);
      }
    });
  }

  getDomain(urlParts: string[]): string {
    let domain = '';
    for (let i = 0; i < urlParts.length - 1; i++) {
      if (urlParts[i] === 'bracelit') {
        break;
      }
      if (i > 0) {
        domain += '.';
      }
      domain += urlParts[i];
    }
    const calculatedDomain = domain || 'app';
    return calculatedDomain;
  }
}
