import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { saveAs } from 'file-saver';

import { WristbandsService } from '@app/shared/services/wristbands.service';

import { ApiService, AuthenticationService, ThemeService } from '@shared/services';

import { ShowInfoUserComponent } from './show-info-user/show-info-user.component';

@Component({
  selector: 'app-show-contacts',
  templateUrl: './show-contacts.component.html',
  styleUrls: ['./show-contacts.component.scss']
})
export class ShowContactsComponent implements OnInit {
  userInfoId: any;
  myForm: FormGroup;
  name: string;
  email: string;
  surname: string;
  phone: string;
  comment: string;
  userId: string;
  eventId: string;
  accountId: string;
  users: any[];
  filename: string;
  blobUrl: string;
  userPass: {ScannedUserid: any};
  loading = true;
  modalMode = false;
  panelClass: any;
  headerText = (localStorage.getItem('lang') === 'es') ? 'Lista de Contactos' : 'Contact List';

  constructor(private apiService: ApiService,
              private formBuilder: FormBuilder,
              private auth_service: AuthenticationService,
              private wrist_service: WristbandsService,
              public themeService: ThemeService,
              public dialog: MatDialog,
              private _location: Location,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Optional() public dialogRef: MatDialogRef<ShowContactsComponent>,
  ) {
    this.accountId = data.accountId
    this.myForm = this.formBuilder.group({
      name: [this.name],
      surname: [this.surname],
      email: [this.email],
      phone: [this.phone],
      comment: [this.comment]
    });
    const currentDate = new Date();
    this.filename = `LeadScannerContacts_${currentDate.toISOString()}.csv`;
   }

  ngOnInit(): void {
    this.eventId = this.data.event.id;
    this.userId = this.auth_service.user.id;
    // this.showInfoUsers();
    this.apiService.get(`events/${this.eventId}/users/${this.userId}/showScannedInfo`).subscribe(data => {
        this.users = data;
        this.loading = false;
      },
      err => {
        console.error(err);
      });
  }

  onInfoUserForm(user) {
    if (this.themeService.checkDefault === true) {
      this.panelClass = 'bracelit-background-dialog';
    } else {
      this.panelClass = this.themeService.selectedBackground;
    }
    const dialogRef = this.dialog.open(ShowInfoUserComponent, {
      height: '100%',
      width: '100%',
      panelClass: ['full-screen-dialog', this.panelClass],
      data: {
        user: user
      }
    });

  }
  goToPreviousPage(): void {
    if (!this.modalMode) {
      this._location.back();
      window.scroll(0, 0);
    } else {
      this.dialogRef.close();
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }

  onDownloadCSV() {
    this.wrist_service.downloadFile(this.accountId, this.eventId, this.userId).subscribe((response: any) => {
      const blob: any = new Blob([response], { type: 'text/csv; charset=utf-8' });
      saveAs(blob, this.filename);
    }), error => console.error('Ha ocurrido un error descargando el archivo', error),
          () => console.info('Archivo descargado con exito');
   }
}
