import { BeeModel } from '@shared/abstract/bee-model';

export class Client extends BeeModel {
  userId: string;
  name: string;
  surname: string;
  nif: string;
  address: string;
  phone: string;
  work: string;
  wristbands = [];

  constructor(data: any) {
    super(data);
  }
}
