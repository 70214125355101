import { Injectable } from '@angular/core';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from '@shared/services/api.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { DomainService } from '@shared/services/domain.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  public userToken: string;
  private TokenPass: { token: string };
  clientId: string;
  userId: string;
  accountId: string;
  projectId = 'bracelit-app-test';

  constructor(private angularFireMessaging: AngularFireMessaging,
              private apiService: ApiService,
              private authService: AuthenticationService,
              private domainService: DomainService,
              private AngularFireDatabase: AngularFireDatabaseModule) {
  }

  Inicialize() {
    this.clientId = this.authService.clientId;
    if (this.authService.user != undefined) {
      this.userId = this.authService.user.id;
    }
    // this.accountId = this.authService.ac;
  }

  requestPermission(): string  {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.userToken = token;
        if (this.userToken) {
          this.saveDeviceUserToken(this.userToken);
        }
        // this.apiService
          // .post('users/' + this.userId + '/storeToken', this.TokenPass)
          // .subscribe(response2 => {
          //   },
          //   err => {
          //     console.error(err);
          //   });
      },
      (err) => {
        console.error('Permiso para notificar no concedido', err);
      });

    return this.userToken;

  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      });
  }

  getToken(): string {
    this.angularFireMessaging.getToken.subscribe(
      (currentToken) => {
        this.userToken = currentToken;
        if (this.userToken) {
          this.saveDeviceUserToken(this.userToken);
        }
        // this.changeValue(currentToken);

        // this.TokenPass = {token: this.userToken};
      },
      (err) => {
        console.error('An error occurred while retrieving token. ', err);
      });

    return this.userToken;
  }

  listen() {
    this.angularFireMessaging.messages
      .subscribe((message) => {
      });
  }

   saveDeviceUserToken(userToken) {
    this.userToken = userToken;
    this.TokenPass = {token: this.userToken};
    this.apiService
        .post('users/' + this.userId + '/storeToken', this.TokenPass)
        .subscribe(() => {
        },
          err => {
            console.error(err); // Consigue el token  y lo guarda en BBDD
          });
  }

}
