<bracelit-mobile-header (onClickLeftButton)="onClose()"
                   [header_central_text_class]="'light-18px-center-white'"
                   [left_icon]="'assets/icons/back.svg'"
                   [left_icon_height]="24"
                   [left_icon_width]="12"
                   central_text="{{ 'showContacts.info' | translate }}"
                   class="header-primary-background"
                   height="50">
</bracelit-mobile-header>

<div class="main-container">
  <bracelit-preloader *ngIf="loading" [absoluteCentered]="true">
  </bracelit-preloader>
  <form [formGroup]="myForm" class="cart-container" fxLayout="row wrap">
    <div class="payments-card-container" fxLayoutAlign="center center">
      <mat-card class="bracelit-card-2 white-opacity-background payments-card" fxLayout="row wrap">
        <mat-tab-group [color]="'accent'" fxFlex="100">
          <mat-tab>
            <ng-template mat-tab-label>
              <span class="bold-16px-center-primary">{{ 'showContacts.contact' | translate }}</span>
            </ng-template>
            <bracelit-preloader *ngIf="loading" [absoluteCentered]="true">
            </bracelit-preloader>
            <div *ngIf="!loading" class="tab-content-container"
            fxFlex="100" fxLayout="column wrap" fxLayoutAlign="center start">
                <div id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.name' | translate }}: </mat-label>
                  <label>{{this.name}}</label>
                </div>

                <div id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.surname' | translate }}: </mat-label>
                  <label>{{this.surname}}</label>
                </div>

                <div id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.email' | translate }}: </mat-label>
                  <label>{{this.email}}</label>
                </div>

                <div id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.phone' | translate }}: </mat-label>
<!--                  <label>{{this.phone}}</label>-->
                  <label><a href="tel:{{this.phone}}">{{this.phone}}</a></label>
                </div>
                <div id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.comments' | translate }}: </mat-label>
                  <label>{{this.comment}}</label>
                </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </form>
</div>

