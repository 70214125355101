import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WristbandsService } from '@shared/services/wristbands.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  payment = {vouchers: 0};
  thereIsScroll = 0;
  coinName: string;
  eventId: string;
  paymentMethod: string;
  headTitle: string;
  wordShop: string;
  infoQuantity: any;
  fullPay: any;
  linesInfo:any[];
  linesInfoStatus:any[];
  domain: string;
  constructor(public dialogRef: MatDialogRef<PaymentDetailsComponent>,
              private wristbandsService: WristbandsService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.domain = environment.server;
  }

  async ngOnInit() {
    this.payment.vouchers = 0;
    if (this.payment.vouchers > 5) {
      this.thereIsScroll = 1;
    }
    if(this.data.transaction.createdBy && this.data.transaction.createdBy.includes('@') && this.data.transaction.creditableType === 'App\\SaleOrder'){
      this.linesInfoStatus = await this.wristbandsService.onlineOrderInfo(this.wristbandsService.selectedEvent.id, this.data.transaction.creditableId);
    }
    this.coinName = this.data.coinName;
    this.eventId = this.data.eventId;
    this.parseMethods();
    this.selectShoppingType();
  }
  parseMethods() {
    if (this.data.extraInfo) {
      this.fullPay = (Math.round(this.data.extraInfo.amount * 100) / 100).toFixed(2);
      switch (this.data.extraInfo.paymentMethod) {
        case 'C':
          this.paymentMethod = ((localStorage.getItem('lang') === 'es') ? 'Tarjeta' : 'Credit card');
          break;
        case 'cash':
          this.paymentMethod = ((localStorage.getItem('lang') === 'es') ? 'Efectivo' : 'Cash');
          break;
        case 'z':
          this.paymentMethod = 'Bizum';
          break;
      }
    }
  }

  parseStatus(status){
    if(status == 'pending'){
      const estado = ((localStorage.getItem('lang') === 'es') ? 'Pendiente' : 'Pending');
      return estado;
    }else if(status == 'approve'){
      const estado = ((localStorage.getItem('lang') === 'es') ? 'Aceptado' : 'Approved');
      return estado;
    }else if(status == 'ready'){
      const estado = ((localStorage.getItem('lang') === 'es') ? 'Preparado' : 'Prepared');
      return estado;
    }else if(status == 'finished'){
      const estado = ((localStorage.getItem('lang') === 'es') ? 'Recogido' : 'Finished');
      return estado;
    }

  }

  selectShoppingType() {
    if (this.data.transaction.voucherId) {
      if (this.data.transaction.uses < 0) {
        this.headTitle = ((localStorage.getItem('lang') === 'es') ? 'Pagar con ' : 'Pay with ') + this.data.bonusType;
      } else {
        this.headTitle = ((localStorage.getItem('lang') === 'es') ? 'Recarga de ' : 'Top up with ') + this.data.bonusType;
      }
      this.wordShop = (localStorage.getItem('lang') === 'es') ? 'Usos' : 'Uses';
      this.infoQuantity = (Math.round(this.data.transaction.uses * 100) / 100).toFixed(2);
    } else {
      if (this.data.transaction.amount < 0) {
        if(this.data.transaction.createdBy.includes('@') && this.data.transaction.creditableType === 'App\\SaleOrder'){
          let status = Object.keys(this.linesInfoStatus)
          this.headTitle = ((localStorage.getItem('lang') === 'es') ? 'Pedido en estado: ' : 'Order status: ')+this.parseStatus(status);
          this.linesInfo = this.linesInfoStatus[status[0]];
        } else if (this.data.transaction.creditableType === 'App\\Return') {
          this.headTitle = ((localStorage.getItem('lang') === 'es') ? 'Devolución de ' : 'Return with ') + this.coinName;
        } else{
          this.headTitle = ((localStorage.getItem('lang') === 'es') ? 'Pago de ' : 'Pay with ') + this.coinName;
        }
      }else{
        this.headTitle = ((localStorage.getItem('lang') === 'es') ? 'Recarga de ' : 'Top up with ') + this.coinName;
      }
      this.wordShop = (localStorage.getItem('lang') === 'es') ? 'Cantidad' : 'Quantity';
      this.infoQuantity = this.data.transaction.amount;
    }
  }

  onGoBack() {
    this.dialogRef.close();
  }
}
