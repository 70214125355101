import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { User } from '@app/shared/models';

import { ThemeService } from '@shared/services';

@Component({
  selector: 'app-show-info-user',
  templateUrl: './show-info-user.component.html',
  styleUrls: ['./show-info-user.component.scss']
})
export class ShowInfoUserComponent implements OnInit {
  userInfoId: any;
  myForm: FormGroup;
  name: string;
  email: string;
  surname: string;
  phone: string;
  comment: string;
  user: User;
  loading = true;

  constructor(public dialog: MatDialog,
              protected dialogRef: MatDialogRef<ShowInfoUserComponent>,
              private formBuilder: FormBuilder,
              private location: Location,
              public themeService: ThemeService,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = this.data.user;
    this.name = this.user.name;
    this.surname = this.user.surname;
    this.email = this.user.email;
    this.phone = this.user.phone;
    this.comment = this.user.comment;
    this.myForm = this.formBuilder.group({
      name: [this.name],
      surname: [this.surname],
      email: [this.email],
      phone: [this.phone],
      comment: [this.comment]
    });
    this.loading = false;
  }

  ngOnInit(): void {

  }

  onClose(): void {
    this.dialogRef.close();
  }
}
