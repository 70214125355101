<div style="position: relative; padding: 0px 40px; overflow-y: scroll;">
  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <ng-template [ngIf]="info !== null">
        <div style="display: flex !important; align-items:center !important; justify-content:right !important;
        height:fit-content !important; width:100% !important;"
        (click)="onClose()">
          <img [src]="'assets/icons/crossClose.svg'" style="cursor: pointer; margin-right: -20px;">
        </div>
        <div class="voucher-title bold-16px-center-marine primary-border-100" fxFlex="100"
             fxLayoutAlign="center center" style="margin: 20px 0px">
          {{info.subject}}
        </div>
        
      </ng-template>
    </div>
    <div style="display: flex; flex-direction: column; width: 100%;">
      <div class="voucher-text-row voucher-text-margin light-18px-center-marine" fxFlex="100"
      fxLayoutAlign="center center" style="width: 100%; margin: 20px 0px; color: #0F223E !important; font-size: 14px !important;">
        {{info.bodytext}}
      </div>
      <!--    <div class="voucher-text-row" fxFlex="100" fxLayoutAlign="center center"
          style="font-size: 24px; font-weight: bold; color: #ffe7ac; text-align: center; letter-spacing: -0.43px;"
          (click)="onClose()">
        {{info.subjectButton}}
      </div>-->
      <div *ngFor="let infoButton of info.subjectsButtons" fxFlex="100" fxLayout="row" fxLayoutAlign="center center" style="width: 100%; padding: 15px">
        <!-- <button *ngIf="infoButton.subjectButton !== undefined && infoButton.subjectButton !== null && infoButton.url !== undefined && infoButton.url !== null" class="accent-background regular-16px-center-primary">
          <a class="voucher-text-row" fxFlex="100" fxLayoutAlign="center center"
            style="font-size: 24px; font-weight: bold; color: #185a7d; text-align: center; letter-spacing: -0.43px;"
            href="{{infoButton.url}}"  target="_blank">
            {{infoButton.subjectButton}}
          </a>
        </button> -->
        <button *ngIf="infoButton.subjectButton !== undefined && infoButton.subjectButton !== null && infoButton.url !== undefined && infoButton.url !== null"
         mat-raised-button type="button" class="white-background regular-20px-center-marine" id="control2" #control
        style="box-shadow: none !important; width: 320px !important; border: 1px solid #0F223E;">
          <a class="voucher-text-row" fxFlex="100" fxLayoutAlign="center center"
            style="font-size: 20px; font-weight: bold; color: #0F223E; text-align: center; letter-spacing: -0.43px;"
            href="{{infoButton.url}}"  target="_blank">
            {{infoButton.subjectButton}}
          </a>
        </button>
      </div>
    </div>
    
  </div>
</div>
