import { BeeModel } from '@shared/abstract/bee-model';

import { Role } from './role';
import { SavitarFile } from './savitar-file';

/**
 * User of the app.
 */
export class User extends BeeModel {
  /** The id of the account. */
  accountId?: string;
  /** The id of the role. */
  roleId: string;
  /** The name of the user. */
  name?: string;
  /** The surname of the user. */
  surname?: string;
  /** The nif of the user. */
  nif?: string;
  /** The surname of the user. */
  birthDate?: Date;
  /** The nif of the user. */
  phone?: string;
  /** The email of the user. */
  email: string;
  /** The password of the user. */
  comment: string;
  
  password?: string;
  /** Checks if is the first time the user logs in. */
  isFirstLogin: boolean;
  /** Checks if the user can receive notifications. */
  canReceiveNotifications: boolean;
  /** Checks if the user cant receive notifications. */
  cantReceiveNotifications: boolean;
  /** Checks if the user can receive emails. */
  canReceiveEmails: boolean;
  /** Checks if the user cant receive emails. */
  cantReceiveEmails: boolean;
  /** Checks if the user has confirmed his/her email. */
  emailConfirmed: boolean;
  /** Checks if the user is activePolygon and can log in. */
  isActive: boolean;
  /** The socialNickname of the user. */
  socialNickname?: string;
  /** The socialAvatar of the user. */
  socialAvatar?: string;
  /** The observations of the user. */
  observations?: string;
  /** The language of the user. */
  language?: string;

  /** The role of the user. */
  role: Role;
  /** The files of the user. */
  files?: SavitarFile[] = [];
  /** The profile image of the user. */
  profileImage?: SavitarFile[] = [];

  /**
   * Constructor.
   * @param data
   */
  constructor(data: object) {
    super(data);

    this.cantReceiveEmails = !this.canReceiveEmails;
    this.cantReceiveNotifications = !this.canReceiveNotifications;
    this.assignDates(data, 'birthDate');

    if (data.hasOwnProperty('role') && data['role']) {
      this.role = new Role(data['role']);
    }
    if (data.hasOwnProperty('files') && data['files']) {
      this.files = [];
      this.profileImage = [];
      for (const file of data['files']) {
        this.files.push(new SavitarFile(file));
        if (file.category === 'profileImage') {
          this.profileImage.push(new SavitarFile(file));
        }
      }
    }
  }
}
