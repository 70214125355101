import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  public info: any;

  constructor(public dialogRef: MatDialogRef<InfoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                dialogRef.disableClose = true;
              }

  ngOnInit(): void {
    this.info = JSON.parse(this.data.info);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
