<div *ngIf="!productScanner">
<bracelit-mobile-header
    central_text="{{ 'showContacts.info' | translate }}"
    [header_central_text_class]="'light-20px-center-white'"
    [left_back_icon]="'assets/icons/back.svg'"
    (onClickLeftButton)="onClose()"
    height="50">
</bracelit-mobile-header>
</div>
<div *ngIf="productScanner">
  <bracelit-mobile-header
      [central_text]="'Información de la cerveza'"
      [header_central_text_class]="'light-20px-center-white'"
      [left_back_icon]="'assets/icons/back.svg'"
      (onClickLeftButton)="onClose()"
      height="50">
  </bracelit-mobile-header>
  </div>

<div class="main-container">
  <bracelit-preloader *ngIf="loading" [absoluteCentered]="true">
  </bracelit-preloader>
  <form [formGroup]="myForm" class="cart-container" fxLayout="row wrap">
    <div class="payments-card-container" fxLayoutAlign="center center">
      <mat-card class="bracelit-card-2 white-opacity-background payments-card" fxLayout="row wrap">
        <mat-tab-group [color]="'accent'" fxFlex="100">
          <mat-tab>
            <ng-template mat-tab-label>
              <span *ngIf="!productScanner" class="bold-16px-center-primary">{{ 'showContacts.contact' | translate }}</span>
              <span *ngIf="productScanner" class="bold-16px-center-primary">CERVEZA</span>
            </ng-template>
            <div *ngIf="!errormessage" class="tab-content-container"
            fxFlex="100" fxLayout="column wrap" fxLayoutAlign="center start">
              <div>
                <div id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.name' | translate }}: </mat-label>
                  <label>{{this.name}}</label>
                </div>

                <div *ngIf="!productScanner" id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.surname' | translate }}: </mat-label>
                  <label>{{this.surname}}</label>
                </div>

                <div *ngIf="!productScanner" id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.email' | translate }}: </mat-label>
                  <label>{{this.email}}</label>
                </div>

                <div *ngIf="!productScanner" id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">{{ 'showContacts.phone' | translate }}: </mat-label>
                  <label>{{this.phone}}</label>
                </div>
                <!-- <div id="recharge-button" class="bracels-input-width input-primary">
                  <mat-label class="light-16px-left-primary">COMENTARIOS: </mat-label>
                  <input type="text" formControlName="comment" size="1000">
                </div> -->
                <div id="recharge-button" class="bracels-input-width input-primary">
                  <!-- <mat-label class="light-16px-left-primary">COMENTARIOS: </mat-label> -->
                  <bee-form [beeFormGroup]="myForm"
                            [method]="'post'"
                            [toastMessage]="'Usuario importado con éxito'"
                            [errorMessage]="'Error al importado usuario, inténtalo de nuevo'"
                            [saveButtonText]="'GUARDAR CAMBIOS'"
                            [backEnabled]="false"
                            [requiredSign]="false"
                            [showButtons]="false"
                            [layoutGap]="'0'"
                            (postSubmitFunction)="saveComment()">
                  <div *ngIf="productScanner">
                    <mat-label class="light-16px-left-primary">VALORACIÓN: </mat-label>
                    <ngb-rating [max]="5" [(rate)]="calification" [readonly]="false"></ngb-rating>
                    <!-- <bee-form-item fxFlex="100"
                            [type]="'number'"
                            [id]="'user-mail-subject'"
                            [label]="''"
                            [placeholder]="'VALORACION: '"
                            [appearance]="'outline'"
                            class="dialog-custom-input"
                            [beeFormControl]="myForm.controls['calification']"></bee-form-item> -->
                  </div>
                  <div *ngIf="hasComment" style="padding-top: 10%">
                  <!-- <mat-label class="light-16px-left-primary">COMENTARIOS: </mat-label> -->
                  <bee-form-item fxFlex="100"
                            [type]="'text'"
                            [id]="'user-mail-subject'"
                            [label]="''"
                            [placeholder]="comment"
                            [appearance]="'outline'"
                            [beeFormControl]="myForm.controls['comment']"></bee-form-item>
                  </div>
                  </bee-form>
                </div>
              </div>
            </div>
            <div *ngIf="!errormessage && !productScanner" fxFlex="100" fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 10px">
                <button class="accent-background regular-16px-center-primary" mat-raised-button
                        (click)="saveComment()">{{ 'showContacts.save' | translate }}</button>
            </div>
            <div *ngIf="!errormessage && productScanner" fxFlex="100" fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 10px">
              <button class="accent-background regular-16px-center-primary" mat-raised-button
                      (click)="saveComment()">Valorar cerveza</button>
          </div>
            <div *ngIf="errormessage && !productScanner" id ="recharge-tex" class="light-16px-left-primary" fxLayout="column" fxLayoutAlign="center center">{{texto}}</div>
            <div *ngIf="errormessage && productScanner" id ="recharge-tex" class="light-16px-left-primary" fxLayout="column" fxLayoutAlign="center center">{{textoProduct}}</div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </form>
</div>
