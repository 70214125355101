import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QRCodeModule } from 'angularx-qrcode';

import { MaterialModule } from '@shared/angular-material';

import { BeeQrGeneratorComponent } from './bee-qr-generator/bee-qr-generator.component';
import { BeeQrScanComponent } from './bee-qr-scan/bee-qr-scan.component';
import { BracelQrGeneratorComponent } from './bracel-qr-generator/bracel-qr-generator.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BeeQrGeneratorComponent,
    BeeQrScanComponent,
    BracelQrGeneratorComponent
  ],
  imports: [
    CommonModule,
    QRCodeModule,
    MaterialModule,
    ZXingScannerModule,
    TranslateModule,
    FlexLayoutModule
  ],
  exports: [
    BeeQrGeneratorComponent,
    BeeQrScanComponent,
    BracelQrGeneratorComponent
  ]
})
export class BeeQrModule {
}
