/**
 * Class Option, the multiple options of the selector
 */
export class Option {
  /**
   * Value of the option
   */
  value: any;
  /**
   * Icon of the option (non-mandatory)
   */
  icon?: string;
  /**
   * Label of the option
   */
  label?: any;
  /**
   * Checks if the option is disabled
   */
  disabled = false;
  /**
   * Checks if the option is highlighted (hover, cursor, keyboard)
   */
  highlighted = false;
  /**
   * Checks if the option is selected
   */
  selected = false;
}
