import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

/**
 * PWAService, manages the PWA installation.
 */
@Injectable()
export class PwaService {
  /**
   * Prompt event listener variable.
   * @type {any}
   */
  promptEvent: any;

  /**
   * Constructor, install the app or reloads if new version is available.
   * @param swUpdate
   */
  constructor(private swUpdate: SwUpdate) {
    // DEPRECATED
    /*swUpdate.available.subscribe(event => {
      if (confirm('Hay una nueva versión de la aplicación disponible. Recarga la página para actualizar a la nueva versión')) {
        window.location.reload();
      } else {
        // window.location.reload();
      }
    });*/

    // NUEVA FORMA
    swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          if (confirm('Hay una nueva versión de la aplicación disponible. Recarga la página para actualizar a la nueva versión')) {
            // Reload the page to update to the latest version.
            window.location.reload();
          }
        });

    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });
  }
}
