import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from './shared/services/authentication.service';

@Injectable()
export class BracelitAuthGuard implements CanActivate {
  eventId: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.route.paramMap.subscribe(async params => {
      const eventId = params.get('eventId');
      this.eventId = eventId;});
    const physicalId = route.queryParamMap.get('physical_id') ?
      route.queryParamMap.get('physical_id') : route.queryParamMap.get('physhical_id');

    if (physicalId) {
      localStorage.setItem('physical_id', physicalId);
    }

    let token = false;
    if (this.authService.token) {
      token = true;
    }
    if (token) {
      return true;
    } else {
      // For routes from Wordpress
      if (route.queryParams.token && route.queryParams.token !== '') {
        return true;
      }

      this.router.navigate(['/registro']);

      return false;
    }
  }
}
