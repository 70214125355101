<div [ngClass]="{'standard': appearance === 'standard'}" class="general-container">
  <div *ngIf="label" class="label-wrapper">
    <label
        [ngClass]="{'selector-error': beeFormControl.invalid && beeFormControl.dirty, 'background-label': backgroundLabel, 'disabled': isDisabled}">
      {{ label }}
      <ng-container *ngIf="requiredSign && isRequired"> *</ng-container>
    </label>
  </div>
  <div [id]="id" class="bee-selector">
    <div #selector (click)="onSelectorClick()"
         (keydown)="onSelectorKeyDown($event)"
         (window:click)="onWindowClick()"
         (window:resize)="onWindowResize()"
         [attr.tabindex]="isDisabled ? null : 0"
         [ngClass]="{'open': show, 'disabled': isDisabled, 'error-border': beeFormControl.invalid && beeFormControl.dirty, 'with-icon': icon}"
         class="bee-selector-container">
      <div class="single">
        <div *ngIf="selectedOption" class="value">
          {{ selectedOption.label }}
        </div>
        <div *ngIf="!selectedOption" class="placeholder">
          {{ placeholder }}
        </div>
        <div (click)="clearSelection(false); $event.stopPropagation()"
             *ngIf="allowClear && selectedOption && !isDisabled"
             class="clear">
          &#x2715;
        </div>
        <div *ngIf="show && !isDisabled"
             class="toggle">
          <mat-icon svgIcon="menu-up"></mat-icon>
        </div>
        <div *ngIf="!show && !isDisabled"
             class="toggle">
          <mat-icon svgIcon="menu-down"></mat-icon>
        </div>
      </div>
    </div>
    <mat-icon *ngIf="icon" svgIcon={{icon}}></mat-icon>
  </div>

  <div (click)="onOptionsContainerClick()"
       [@showSelectorState]="show ? 'open' : 'close'" [ngClass]="{'open': show, 'inside-modal': insideModal}" [ngStyle]="{'width.px': width}"
       class="bee-dropdown">
    <ng-template [ngIf]="show">
      <ng-container *ngIf="isSetAbilityToSelect">
        <mat-spinner *ngIf="creating" [diameter]="50"></mat-spinner>

        <div *ngIf="!creating" class="filter">
          <mat-form-field>
            <input #filterInput (input)="searchSubject.next(filterInput.value)" (keydown)="onSearchKeyDown($event)"
                   [value]="currentQuery" autocomplete="off" id="filter-input" matInput
                   name="search" placeholder="{{searchPlaceholder}}">
            <button (click)="filterInput.value=''; search('')" *ngIf="filterInput.value != ''" aria-label="Limpiar" class="clear-input-button" mat-button
                    mat-icon-button matSuffix
                    type="button">
              <mat-icon svgIcon="crossClose" class="close"></mat-icon>
            </button>
          </mat-form-field>

          <button (click)="insertElement($event)"
                  *ngIf="searchText && allowCreation"
                  class="insert-button button-primary-border"
                  color="primary"
                  mat-button>
            <mat-icon svgIcon="plus"></mat-icon>
            <span>{{createButtonLabel}}</span>
          </button>
        </div>

        <mat-spinner *ngIf="loadingData && selectorValues.length == 0" [diameter]="50"></mat-spinner>

        <div #optionsList *ngIf="!loadingData || selectorValues.length > 0" [ngClass]="{'options-error': error}"
             class="options">
          <ul>
            <ng-container *ngFor="let item of selectorValues">
              <li (click)="onOptionClick(item)"
                  (mouseover)="onOptionMouseOver(item)"
                  *ngIf="!hiddenOptions.includes(item.value)"
                  [ngClass]="{'highlighted': item.highlighted, 'selected': item.selected, 'disabled': item.disabled}"
                  class="selector-option">
                <img *ngIf="item.icon" alt="icon" src="{{item.icon}}">
                <span> {{ item.label }}</span>
              </li>
            </ng-container>

            <li *ngIf="selectorValues.length == 0 && !error" class="message" style="padding-bottom: 15px;">
              {{ noResultsText }}
            </li>

            <li *ngIf="error" class="message">
              <div class="valign-wrapper">
                <img alt="error" class="margin-right" src="app/shared/assets/icons/high-priority.svg">
                <span class="red-text">{{ error }}</span>
              </div>
            </li>
          </ul>
        </div>
        <mat-paginator (page)="pageEvent($event)"
                       *ngIf="!error && length > selectorValues.length"
                       [length]="length"
                       [pageSizeOptions]="pageSizeOptions"
                       [pageSize]="pageSize"
                       [showFirstLastButtons]="true">
        </mat-paginator>
      </ng-container>

      <div *ngIf="!isSetAbilityToSelect" class="no-select-ability-container">
        <img alt="No hay permiso" src="app/shared/assets/images/forbidden.png">
        <span>No tienes permisos para realizar esta acción</span>
      </div>
    </ng-template>
  </div>

  <div *ngIf="beeFormControl.invalid && beeFormControl.dirty" class="selector-error">
    <span>{{ requiredText }}</span>
  </div>
</div>
