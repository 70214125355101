import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, AuthenticationService, SnackBarService } from '@shared/services';
import { EventUser } from '@shared/models/bracelit/event-user';

@Component({
  selector: 'app-questionary',
  templateUrl: './questionary.component.html',
  styleUrls: ['./questionary.component.scss']
})
export class QuestionaryComponent implements OnInit {
  myForm: FormGroup;
  accountId: string;
  eventId: string;
  userId: string;
  loading = true;
  eventForm: any[] = [];
  eventUser: EventUser;
  message: string;
  eventName: string;
  isEmpty: boolean;
  boton_deshabilitado: boolean;
  // resultados = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '', 11: ''};
  // saved_results = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '', 11: ''};
  // clases = ['1','2','3','4','5','6','7','8','9','10','11'];
  resultados = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: ''};
  saved_results = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: ''};
  clases = ['1','2','3','4','5','6','7','8','9'];
  pregunta: any;


  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<QuestionaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.eventId = data.eventId;
    this.myForm = formBuilder.group({});
}

  async ngOnInit(): Promise<void> {
    this.accountId = JSON.parse(localStorage.getItem('accountId'));
    this.userId = this.authenticationService.user.id;
    const clientId = this.authenticationService.client.id;
    const url = `clients/${clientId}/events/${this.eventId}/event-user`;
    this.eventUser = await this.apiService.get(url).toPromise();
    this.isEmpty = true;
    this.boton_deshabilitado = true;
    if(this.eventUser.registerExtraFields !== null){
      let extraFields = JSON.parse(this.eventUser.registerExtraFields);
      if (extraFields.quiz !== undefined){
        this.saved_results = extraFields.quiz
        this.checkedOnInit(this.clases);
      }else{
        // this.saved_results = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '', 11: ''};
        this.saved_results = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: ''};
      }
    }else{
      // this.saved_results = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '', 11: ''};
      this.saved_results = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: ''};
    }
    this.resultados = this.saved_results;
  }

  checkedOnClick(event: any, clase: any, letter?: string) {
    const checkboxes = document.getElementsByClassName(clase);
    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes.item(i) as HTMLInputElement;
      checkbox.checked = false;
    }
    const target = event.target as HTMLInputElement;
    target.checked = true;
    this.pregunta = clase.toString();
    this.resultados[clase] = letter;
    this.isEmpty = Object.values(this.resultados).some(x => (x === ''));

    if (this.isEmpty){
      this.boton_deshabilitado = true
    }else{
      this.boton_deshabilitado = false
    }
  }

  checkedOnInit(clases: any) {
    for (let j=1; j<10; j++){
      let clase = clases[j-1];
      const checkboxes = document.getElementsByClassName(clase);
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes.item(i) as HTMLInputElement;
        if (checkbox.name === this.saved_results[j]){
          checkbox.checked = true;
        }else{
          checkbox.checked = false;
        }
      }
    }
    this.boton_deshabilitado = false
  }

  sendQuestionary() {
    this.apiService
        .post(`accounts/${this.accountId}/events/${this.eventId}/users/${this.userId}/actionUpdateExtraFields`, {action: 'quiz', form: this.resultados})
        .subscribe(_data => {
          this.snackBarService.openSnackBar('Quiniela enviada correctamente.');
          this.dialogRef.close();
        }, err => {
          console.error(err);
        });
  }

}
