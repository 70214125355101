<bracelit-preloader *ngIf="loading" style="height: 100%; display: flex; justify-content: center; align-items: center;"></bracelit-preloader>

<div fxLayout="row wrap">
  <div id="close-container" *ngIf="!loading" fxFlex="100" fxLayout="row" fxLayoutAlign="end center">
    <img src="assets/icons/crossClose.svg" (click)="dialogRef.close()" style="cursor: pointer;">
  </div>
  <bee-form fxFlex="100"
            *ngIf="!loading"
            [backEnabled]="false"
            [deleteEnabled]="false"
            [customSubmit]="true"
            [beeFormGroup]="myForm"
            [method]="'post'"
            saveButtonText="{{ 'update' | translate }}"
            [submitButtonClasses]="'primary-background bold-20px-center-white button-container'"
            (responseOutput)="save()">

            <h2 fxFlex="100">{{headerText}}</h2>
            <h3 fxFlex="100">{{messageText}}</h3>

    <ng-container *ngIf="eventForm !== null">
      <ng-container *ngFor="let formData of eventForm">
        <bee-form-item *ngIf="formData.type !== 'image' && myForm.controls['extraFields'] != undefined && formData.name !== 'birthdate'"
                       [appearance]="'outline'"
                       [beeFormControl]="myForm.controls['extraFields']['controls'][formData.name]"
                       [id]="'event-form-' + formData.name"
                       [placeholder]="formData.webName"
                       [type]="formData.type"
                       fxFlex="100"
                       [max]="formData.max"
                       [options]="formData.options"></bee-form-item>
        <bee-form-item *ngIf="formData.type !== 'image' && myForm.controls['extraFields'] != undefined && formData.name === 'birthdate'"
                       [appearance]="'outline'"
                       [beeFormControl]="myForm.controls['extraFields']['controls'][formData.name]"
                       [id]="'event-form-' + formData.name"
                       [placeholder]="formData.webName"
                       [type]="formData.type"
                       fxFlex="100"
                       [isDisabled]="true"
                       [max]="formData.max"
                       [options]="formData.options"></bee-form-item>
        <bee-file-uploader *ngIf="formData.type === 'image' && myForm.controls['extraFields'] != undefined"
                           [allowedFileFormats]="['image/jpeg','image/png','image/gif', 'image/svg+xml']"
                           [beeFormControl]="myForm.controls['extraFields']['controls'][formData.name]['controls']['fileId']"
                           [title]="'Foto de perfil'"
                           [selectImageLabel]="null"
                           [placeholderImageUrl]="'app/shared/assets/images/avatar-placeholder.png'"
                           [category]="'eventProfileImage'"
                           [preview]="true"
                           [displayMode]="'circle'"
                           [aspectRatio]="1"
                           [inputText]="'Elegir imagen'"
                           [maxFileSize]="6000000"
                           [myFiles]="eventUser.files">
        </bee-file-uploader>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="eventForm === null">
      <bee-form-item  [appearance]="'outline'"
                      [beeFormControl]="myForm.controls['extraFields']['controls']['name']"
                      [id]="'name'"
                      placeholder="{{ 'settings.name' | translate }}"
                      type="'text'"
                      fxFlex="100"></bee-form-item>

      <bee-form-item  [appearance]="'outline'"
                      [beeFormControl]="myForm.controls['extraFields']['controls']['surname']"
                      [id]="'surname'"
                      placeholder="{{ 'settings.surname' | translate }}"
                      type="'text'"
                      fxFlex="100"></bee-form-item>

      <bee-form-item  [appearance]="'outline'"
                      [beeFormControl]="myForm.controls['extraFields']['controls']['phone']"
                      [id]="'phone'"
                      placeholder="{{ 'settings.phone' | translate }}"
                      type="'text'"
                      fxFlex="100"></bee-form-item>
    </ng-container>
  </bee-form>
</div>