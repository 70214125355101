import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Account } from '@shared/models';
import { Client } from '@shared/models/bracelit/client';

import { environment } from '../../../environments/environment';
import { User } from '../models/user';

@Injectable()
export class AuthenticationService {
  headers: HttpHeaders;
  private protocol = '';
  private server = '';
  private apiUrl = '';
  private subdomain = '';

  constructor(private router: Router,
              private httpClient: HttpClient) {
    this.protocol = environment.protocol;
    this.server = environment.server;
    this.apiUrl = environment.apiUrl;
    this._apiRoute = this.protocol + this.server + this.apiUrl;

    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Authorization', '');

    const currentToken = JSON.parse(localStorage.getItem('token'));
    if (currentToken) {
      this._token = currentToken;
      if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined') {
        this._user = new User(JSON.parse(localStorage.getItem('user')));
      }
      if (localStorage.getItem('abilities') && localStorage.getItem('abilities') !== 'undefined') {
        this._abilities = JSON.parse(localStorage.getItem('abilities'));
      }
      if (localStorage.getItem('clientId') && localStorage.getItem('clientId') !== 'undefined') {
        this._clientId = JSON.parse(localStorage.getItem('clientId'));
      }
      if (localStorage.getItem('client') && localStorage.getItem('client') !== 'undefined') {
        this._client = new Client(JSON.parse(localStorage.getItem('client')));
      }
      if (localStorage.getItem('accountId') && localStorage.getItem('accountId') !== 'undefined') {
        this._accountId = localStorage.getItem('accountId');
      }
      if (this.getAccountTheme() && this.getAccountTheme() !== 'undefined') {
        this._accountTheme = JSON.parse(this.getAccountTheme());
      }
      if (localStorage.getItem('account') && localStorage.getItem('account') !== 'undefined') {
        this._account = new Account(JSON.parse(localStorage.getItem('account')));
      }
      if (localStorage.getItem('redirectUrl') && localStorage.getItem('redirectUrl') !== 'undefined') {
        this._redirectUrl = JSON.parse(localStorage.getItem('redirectUrl'));
      }
    }
  }

  private _token: string;

  get token(): string {
    if (this._token) {
      return this._token;
    } else {
      return '';
    }
  }

  set token(value: string) {
    this._token = value;
    localStorage.setItem('token', JSON.stringify(this.token));
  }

  private _redirectUrl: string;

  get redirectUrl(): string {
    if (this._redirectUrl) {
      return this._redirectUrl;
    } else {
      return '';
    }
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
    localStorage.setItem('redirectUrl', JSON.stringify(this._redirectUrl));
  }

  private _user: User;

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
    this._user$.next(this._user);
    localStorage.setItem('user', JSON.stringify(this._user));
  }

  private _user$: Subject<User> = new Subject<User>();

  get user$(): Subject<User> {
    return this._user$;
  }

  private _abilities: string[] = [];

  get abilities(): string[] {
    return this._abilities;
  }

  set abilities(data: string[]) {
    this._abilities = data;
    localStorage.setItem('abilities', JSON.stringify(this._abilities));
  }

  private _impersonateInformation: any;

  get impersonateInformation(): any {
    return this._impersonateInformation;
  }

  set impersonateInformation(value: any) {
    this._impersonateInformation = value;
    this._impersonateInformation$.next(value);
    localStorage.setItem('impersonateInformation', JSON.stringify(this._impersonateInformation));
  }

  private _impersonateInformation$ = new Subject<any>();

  get impersonateInformation$(): Subject<string> {
    return this._impersonateInformation$;
  }

  private _accountId: string;

  get accountId(): string {
    return this._accountId;
  }

  set accountId(value: string) {
    this._accountId = value;
    localStorage.setItem('accountId', JSON.stringify(this._accountId));
  }

  private _accountTheme: string;

  get accountTheme(): string {
    return this._accountTheme;
  }

  set accountTheme(value: string) {
    this._accountTheme = value;
    localStorage.setItem('accountTheme', JSON.stringify(this._accountTheme));

    this.setAccountTheme(this._accountTheme);
  }

  private _accountLogo: string;

  get accountLogo(): string {
    return this._accountLogo;
  }

  set accountLogo(value: string) {
    this._accountLogo = value;
    localStorage.setItem('accountLogo', JSON.stringify(this._accountLogo));
    this.setAccountLogo(this._accountLogo);
  }

  private _account: Account;

  get account(): Account {
    return this._account;
  }

  set account(value: Account) {
    this._account = value;
    localStorage.setItem('account', JSON.stringify(this._account));
  }

  private _clientId: string;

  get clientId(): string {
    return this._clientId;
  }

  set clientId(value: string) {
    this._clientId = value;
    localStorage.setItem('clientId', JSON.stringify(this._clientId));
  }

  private _client: Client;

  get client(): Client {
    return this._client;
  }

  set client(value: Client) {
    this._client = value;
    localStorage.setItem('client', JSON.stringify(this._client));
  }

  private _apiRoute: string = this.protocol + this.server + this.apiUrl;

  get apiRoute(): string {
    return this._apiRoute;
  }

  set apiRoute(value: string) {
    this._apiRoute = value;
    this._apiRoute$.next(value);
  }

  private _apiRoute$ = new Subject<string>();

  get apiRoute$(): Subject<string> {
    return this._apiRoute$;
  }

  isSetAbility(ab: string) {
    if (this.user.role.slug === 'super-admin') {
      return true;
    }

    return this.abilities.includes(ab);
  }

  impersonateAccount(accountId): Observable<boolean> {
    return this.authenticate(this._apiRoute + 'impersonate-account/' + accountId, '', true);
  }

  impersonate(userId): Observable<boolean> {
    return this.authenticate(this._apiRoute + 'impersonate/' + userId, '', true);
  }

  login(data: any): Observable<boolean> {
    if (this.token) {
      this.resetUser();
    }

    return this.authenticate(this._apiRoute + 'login', data);
  }

  loginWegow(data: any) {
    if (this.token) {
      this.resetUser();
    }

    this.token = 'Bearer ' + data['token'];
    this.abilities = data['abilities'];
    if (data['User']) {
      this.user = new User(data['User']);
    }
    if (data['Client']) {
      this.client = new Client(data['Client']);
      this.clientId = this.client.id;
      if (data['Client'].wristbands && data['Client'].wristbands.length > 0) {
        this.accountId = data['Client'].wristbands[0].accountId;
      } else {
        this.accountId = null;
      }
    }
    if (data['Account']) {
      this.account = new Account(data['Account']);
      this.accountId = this.account.id;
      this.accountTheme = this.account.selectedTheme;
    }
    
    return true;
  }

  loginGoogle(data: any){
    if (this.token) {
      this.resetUser();
    }

    this.token = 'Bearer ' + data['token'];
    this.abilities = data['abilities'];
    if (data['User']) {
      this.user = new User(data['User']);
    }
    if (data['Client']) {
      this.client = new Client(data['Client']);
      this.clientId = this.client.id;
      if (data['Client'].wristbands && data['Client'].wristbands.length > 0) {
        this.accountId = data['Client'].wristbands[0].accountId;
      } else {
        this.accountId = null;
      }
    }
    if (data['Account']) {
      this.account = new Account(data['Account']);
      this.accountId = this.account.id;
      this.accountTheme = this.account.selectedTheme;
    }
    
    return true;
  }

  undoImpersonate() {
    if (this.impersonateInformation) {
      this.token = this._impersonateInformation.token;
      this.abilities = this._impersonateInformation.abilities;
      this.user = this._impersonateInformation.user;
      this.impersonateInformation = null;
      localStorage.removeItem('impersonateInformation');
    }
  }

  logout(redirectRoute: string = '/'): void {
    this.resetUser();
    this.apiRoute = this.protocol + this.server + this.apiUrl;
    if (redirectRoute) {
      this.router.navigate([redirectRoute]);
    }
  }

  logoutAndStay(): void {
    this.resetUser();
  }

  private resetUser() {
    this._token = null;
    this._abilities = null;
    this.user = null;
    this._impersonateInformation = null;
    this._clientId = null;
    this.client = null;
    this._accountId = null;
    this._accountTheme = null;
    this.account = null;
    localStorage.removeItem('token');
    localStorage.removeItem('abilities');
    localStorage.removeItem('user');
    localStorage.removeItem('pointOfSale');
    localStorage.removeItem('impersonateInformation');
    localStorage.removeItem('accountId');
    localStorage.removeItem('accountTheme');
    localStorage.removeItem('account');
    localStorage.removeItem('clientId');
    localStorage.removeItem('client');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('voucherPurchaseData');
    localStorage.removeItem('productPurchaseData');
  }

  private authenticate(route: string, data: any, impersonating: boolean = false): Observable<boolean> {
    return this.httpClient.post(route, data)
      .pipe(
        map((response) => {
          if (response['token']) {
            if (impersonating) {
              this.impersonateInformation = {
                token: this.token,
                abilities: this.abilities,
                user: this.user,
              };
            }
            // const res = response['token'].split('.');
            // const payload = JSON.parse(window.atob(res[1]));
            this.token = 'Bearer ' + response['token'];
            this.abilities = response['abilities'];
            // if (response['account']) {
            //   this.acc = new Account(response['account']);
            // }
            if (response['user']) {
              this.user = new User(response['user']);
            }
            if (response['client']) {
              this.client = new Client(response['client']);
              this.clientId = this.client.id;
              if (response['client'].wristbands.length > 0) {
                this.accountId = response['client'].wristbands[0].accountId;
              } else {
                this.accountId = null;
              }
            }
            if (response['account']) {
              this.account = new Account(response['account']);
              this.accountId = this.account.id;
              this.accountTheme = this.account.selectedTheme;
            }



            return true;
          } else {
            return false;
          }
        }),
        catchError((error: any) => this.handleError(error))
      );
  }

  private handleError(err: HttpErrorResponse | any) {
    if (err.status === 500 && !err.error.error) {
      return throwError('Ha ocurrido un error en el servidor.');
    }

    return throwError(err.error.error);
  }

  getAccountTheme(): string {
    return this._accountTheme;
  }

  setAccountTheme(value: string) {
    this._accountTheme = value;
  }

  getAccountLogo(): string {
    return this._accountLogo;
  }

  setAccountLogo(value: string) {
    this._accountLogo = value;
    localStorage.setItem('accountLogo', JSON.stringify(this._accountLogo));
  }
}
