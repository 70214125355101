import { FormControl } from '@angular/forms';

/**
 * Validates NIF; NIE; TR; CIF;
 */
export class BeeNifValidator {

  /**
   * The validator.
   * @param control
   */
  static validator(control: FormControl) {
    if (control.value === '' || !control.value) {
      return null;
    }
    if (BeeNifValidator.checkNIF(control.value) || BeeNifValidator.checkCIF(control.value) || BeeNifValidator.checkNIE(control.value)
      || BeeNifValidator.checkTR(control.value)) {
      return null;
    }

    return {invalidCifNifNieTr: true};
  }

  /**
   * Validates NIF.
   * @param nif
   */
  static checkNIF(nif) {
    const NIF_regExp = '^\\d{8}[a-zA-Z]{1}$';
    const NIF_Letters = 'TRWAGMYFPDXBNJZSQVHLCKET';
    // Comprueba la longitud. Los DNI antiguos tienen 7 digitos.
    if ((nif.length !== 8) && (nif.length !== 9)) {
      return false;
    }
    if (nif.length === 8) {
      nif = '0' + nif;
    } // Ponemos un 0 a la izquierda y solucionado

    // Comprueba el formato
    const regExp = new RegExp(NIF_regExp);
    if (!nif.match(regExp)) {
      return false;
    }

    const e = nif.charAt(nif.length - 1);
    const dni = nif.substring(0, nif.length - 1);
    const letra = NIF_Letters.charAt(dni % 23);

    return (letra === e.toUpperCase());
  }

  /**
   * Validates NIE.
   * @param nie
   */
  static checkNIE(nie) {
    nie = nie.toUpperCase();
    if (!nie.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
      return false;
    }
    // Test NIE
    // T
    if (/^[T]{1}/.test(nie)) {
      return (nie[8] === /^[T]{1}[A-Z0-9]{8}$/.test(nie));
    }

    // XYZ
    if (/^[XYZ]{1}/.test(nie)) {
      return (
        nie[8] === 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(
          nie.replace('X', '0')
            .replace('Y', '1')
            .replace('Z', '2')
            .substring(0, 8) % 23
        )
      );
    }

    return false;
  }

  /**
   * Validates CIF.
   * @param cif
   */
  static checkCIF(cif) {
    if (!cif || cif.length !== 9) {
      return false;
    }

    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = cif.substr(1, cif.length - 2);
    const letter = cif.substr(0, 1);
    const control = cif.substr(cif.length - 1);
    let sum = 0;
    let i;
    let digit;

    if (!letter.match(/[A-Z]/)) {
      return false;
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i], null);
      if (isNaN(digit)) {
        return false;
      }
      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = Math.floor(digit / 10) + (digit % 10);
        }
        sum += digit;
      } else {
        sum += digit;
      }
    }
    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
  }

  /**
   * Validates TR. (Tarjeta de residencia)
   * @param tr
   */
  static checkTR(tr) {
    if ((tr.length !== 10) && (tr.length !== 9)) {
      return false;
    }
    if ((tr.charAt(0).toUpperCase() !== 'X') && (tr.charAt(0).toUpperCase() !== 'Y') && (tr.charAt(0).toUpperCase() !== 'Z')) {
      return false;
    }

    let leftNum = '0';
    if (tr.charAt(0).toUpperCase() === 'Y') {
      leftNum = '1';
    }

    if (tr.length === 9) {
      return this.checkNIF(leftNum + tr.substring(1, tr.length));
    } else {
      return this.checkNIF(tr.substring(1, tr.length));
    }
  }

  /**
   * Validates Malta VAT.
   * @param vat
   */
  static checkMaltaVAT(vat) {
    const VAT_regExp = '(MT)?[0-9]{8}';
    const tempStr = vat.toUpperCase(); // pasar a mayúsculas
    // Comprueba el formato
    const regExp = new RegExp(VAT_regExp);
    if (!tempStr.match(regExp)) {
      return false;
    }
    return true;
  }
}
