import { AfterViewChecked, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateConfigService } from '@app/shared/services/translate-config.service';

@Component({
  selector: 'app-info',
  templateUrl: './info-dev.component.html',
  styleUrls: ['./info-dev.component.scss']
})
export class InfoDevComponent implements OnInit, AfterViewChecked {
  public info: any;
  intervalReturnMoneyStarting: any;
  intervalReturnMoneyEnding: any;
  intervalReturnMoneySend: any;
  returnMoneyCommission: any;
  event: any;

  constructor(
    private translateConfigService: TranslateConfigService,
    public dialogRef: MatDialogRef<InfoDevComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
    this.intervalReturnMoneyStarting = this.data.intervalReturnMoneyStarting;
    this.intervalReturnMoneyEnding = this.data.intervalReturnMoneyEnding;
    this.intervalReturnMoneySend = this.data.intervalReturnMoneySend;
    this.returnMoneyCommission = this.data.returnMoneyCommission;
    this.event = this.data.event;
  }

  ngAfterViewChecked(): void {
    const element = document.getElementById("goTo");
    element.scrollIntoView(true);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
