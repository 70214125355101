<head>
  <meta name="robots" content="NOODP">
  <meta name="description" content="Soluciones tecnológicas para la gestión de eventos. Ofrecemos: ticketing, control de accesos, pagos cashless a través de tecnología RFID y marketing y CRM.">
  <title></title>
</head>
<div [ngClass]="themeService.selectedTheme" style="background-color: #FFFFFF"
     id="theme-container" #div>
  <bracelit-preloader *ngIf="!loaded" [absoluteCentered]="true"></bracelit-preloader>
  <router-outlet *ngIf="loaded"></router-outlet>
</div>
