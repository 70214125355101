import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Animation for the sub elements.
 */
export const subElementStateTrigger = trigger('subElementState', [
  transition(':enter', [
    style({
      height: 0,
      overflow: 'hidden'
    }),
    animate('300ms ease-out', style({
      height: '*'
    })),
  ]),
  transition(':leave', [
    style({
      overflow: 'hidden'
    }),
    animate('300ms ease-out', style({
      height: 0
    }))
  ])
]);
