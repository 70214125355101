import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { PaymentDetailsComponent } from './payment-details.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [PaymentDetailsComponent],
    imports: [
        SharedModule,
        TranslateModule
    ]
})
export class PaymentDetailsModule {
}
