import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * ToggleSidebarService, toggles the sidebar from outside the Sidebar component.
 */
@Injectable()
export class ToggleSidebarService {

  /**
   * Checks if the sidebar is currently activePolygon.
   * @type {boolean}
   */
  public _sidebarActive = false;

  /**
   * Gets the sidebar status.
   * @returns {boolean}
   */
  get sidebarActive(): boolean {
    return this._sidebarActive;
  }

  /**
   * Emit when the sidebar status change.
   * @type {Subject<boolean>()}
   */
  private _sidebarStatusChanged$: Subject<boolean> = new Subject<boolean>();

  /**
   * Gets the sidebar status change Subject.
   * @returns {Subject<boolean>}
   */
  get sidebarStatusChanged$(): Subject<boolean> {
    return this._sidebarStatusChanged$;
  }

  /**
   * Toggles the sidebar given a value.
   * @param value
   */
  public toggleSidebar(value: boolean = null) {
    if (value !== null) {
      this._sidebarActive = value;
    } else {
      this._sidebarActive = !this._sidebarActive;
    }
    this._sidebarStatusChanged$.next(this._sidebarActive);
  }
}
