<div style="display: flex; flex-direction: column; color:#0f223e; margin: 0px -10px 0px -10px;">
  <div style="display: flex; justify-content:center; margin-top: 12px; margin-bottom: 16px; font-size: 20px; font-weight: bold">PREGUNTAS</div>
  <div style="display: flex; flex-direction: row; margin-top: 16px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/Dechra.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      Zenalpha es la combinación de medetomidina y vatinoxán para la sedación para procedimientos... (Indica cuál de las siguientes afirmaciones es falsa)
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="1" name="a" (change)="checkedOnClick($event,1,'a')">
      <div>
        en perros
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="1" name="b" (change)="checkedOnClick($event,1,'b')">
      <div>
        que produce un bradicardia e hipertensión, como todos los alfa-2 que ya conocemos
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="1" name="c" (change)="checkedOnClick($event,1,'c')">
      <div>
        que, gracias al vatinoxán, evita la mayoría de los efectos indeseados de la medetomidina, consiguiendo una sedación adecuada y segura
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="1" name="d" (change)="checkedOnClick($event,1, 'd')">
      <div>
        que tiene un rápido inicio de acción y una duración de acción de unos 45 minutos, lo que permite que no tenga que revertirse en la mayoría de los casos
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/AliviraKarizoo.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      Con el fin de no doler ni picar que excipiente antiemético, ¿lleva el Vominil?
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="2" name="a" (change)="checkedOnClick($event,2,'a')">
      <div>
        Metacresol
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="2" name="b" (change)="checkedOnClick($event,2,'b')">
      <div>
        Alcohol bencílico
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="2" name="c" (change)="checkedOnClick($event,2,'c')">
      <div>
        N-butanol
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/Ecuphar.png">
    </div>
    <div style="width: 100%;font-weight: bold">
      ¿Qué ventajas ofrece la Anestesia Locorregional?
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="3" name="a" (change)="checkedOnClick($event,3,'a')">
      <div>
        Abolición total de la nocicepción
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="3" name="b" (change)="checkedOnClick($event,3,'b')">
      <div>
        Reducción de la mortalidad anestésica
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="3" name="c" (change)="checkedOnClick($event,3,'c')">
      <div>
        Ambas son correctas
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/ELANCO.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      ¿Los AINE son la única opción de tratamiento farmacológico o biológico de primera línea unánime en COASTeR 2?
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="4" name="a" (change)="checkedOnClick($event,4,'a')">
      <div>
        Sí
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="4" name="b" (change)="checkedOnClick($event,4,'b')">
      <div>
        No
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/LogoFatro.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      ¿Cuál fue el novedoso formato del IV Congreso online Fatro de anestesia 2024?
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="5" name="a" (change)="checkedOnClick($event,5,'a')">
      <div>
        Grabaciones
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="5" name="b" (change)="checkedOnClick($event,5,'b')">
      <div>
        Mesas redondas de debate
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="5" name="c" (change)="checkedOnClick($event,5,'c')">
      <div>
        Webinar abierta
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/BBraun.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      El Ventilador de Turbina V420:
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="6" name="a" (change)="checkedOnClick($event,6,'a')">
      <div>
        Se puede conectar a un compresor para ventilar a cualquier paciente en modo presión.
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="6" name="b" (change)="checkedOnClick($event,6,'b')">
      <div>
        Se puede conectar a cualquier máquina de anestesia sin usar compresor, y ventilar con 3 modos ventilatorios diferentes.
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="6" name="c" (change)="checkedOnClick($event,6,'c')">
      <div>
        Solo se puede conectar a una Turbina de presión.
      </div>
    </div>
  </div>


  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/elite.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      En todos los equipos de anestesia con ventilación mecánica de ELITE Veterinaria, puede añadirse un módulo con medición de:
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="7" name="a" (change)="checkedOnClick($event,7,'a')">
      <div>
        Capnografía (CO2)
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="7" name="b" (change)="checkedOnClick($event,7,'b')">
      <div>
        Gases Halogenados
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="7" name="c" (change)="checkedOnClick($event,7,'c')">
      <div>
        Índice Biespectral (BIS)
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="7" name="d" (change)="checkedOnClick($event,7,'d')">
      <div>
        Todas son correctas
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/ifevet.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      ¿Qué ponente de esta edición de SEAAV 2024 es ponente también en nuestros postgrados universitarios?
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="8" name="a" (change)="checkedOnClick($event,8,'a')">
      <div>
        Rocío Fernández
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="8" name="b" (change)="checkedOnClick($event,8,'b')">
      <div>
        Franceso Staffieri
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="8" name="c" (change)="checkedOnClick($event,8,'c')">
      <div>
        Laura Vilalta
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="8" name="d" (change)="checkedOnClick($event,8,'d')">
      <div>
        Joaquín Araos
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/grupo-emer.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      ¿Que nueva actualización presentará mindray en su equipo de anestesia veta 5?.
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="9" name="a" (change)="checkedOnClick($event,9,'a')">
      <div>
        Control automático de la capnografía en sincronización con sus monitores multi paramétricos
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="9" name="b" (change)="checkedOnClick($event,9,'b')">
      <div>
        Espirometría en pantalla.
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="9" name="c" (change)="checkedOnClick($event,9,'c')">
      <div>
        Más cestas para colocar materiales.
      </div>
    </div>
  </div>

   <!-- <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/ceva.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      ¿Qué es Firodyl?
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="10" name="Es un coxib, un cox 2 selectivo" (change)="checkedOnClick($event,10)">
      <div>
        Es un coxib, un cox 2 selectivo
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="10" name="Es un cox2 preferente" (change)="checkedOnClick($event,10)">
      <div>
        Es un cox2 preferente
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="10" name="Es un meloxicam para gatos" (change)="checkedOnClick($event,10)">
      <div>
        Es un meloxicam para gatos
      </div>
    </div>
  </div> -->

  <!-- <div style="display: flex; flex-direction: row; margin-top: 16px;margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 50px">
      <img style="width: 40px;" src="app/shared/assets/logos/Jurox-Logo.png">
    </div>
    <div style="width: 100%; font-weight: bold">
      Una premedicación eficaz a base de medetomidina podría tener un efecto de ahorro de dosis del 50-90% sobre la dosis de Alfaxan Multidosis necesaria para inducir la anestesia
    </div>
  </div>
  <div style="display: flex; flex-direction: column; margin-bottom:20px">
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="11" name="Verdadero" (change)="checkedOnClick($event,11)">
      <div>
        Verdadero
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <input type="checkbox"  class="11" name="Falso" (change)="checkedOnClick($event,11)">
      <div>
        Falso
      </div>
    </div>
  </div> -->

  <div style="display: flex; justify-content: center; margin-top: 16px">
    <button *ngIf="boton_deshabilitado" type="submit" class="primary-background bold-20px-center-white"
    style="width: 40%; height: 40px; border-radius: 7px; border-width: 0px; box-shadow: none; color: rgba(255, 255, 255, 0.5) !important">
    ENVIAR
    </button>
    <button *ngIf="!boton_deshabilitado" type="submit" class="primary-background bold-20px-center-white"
    style="width: 40%; height: 40px; border-radius: 7px; border-width: 0px; box-shadow: none" (click)="sendQuestionary()">
    ENVIAR
    </button>
  </div>


</div>
