import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { ShowContactsComponent } from './show-contacts.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ShowContactsComponent],
  imports: [
    SharedModule,
    TranslateModule
  ]
})
export class ShowContactsModule {
}