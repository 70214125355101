import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-see-qr',
  templateUrl: './see-qr.component.html',
  styleUrls: ['./see-qr.component.scss']
})
export class SeeQrComponent implements OnInit {
  userId: string;
  modalMode = false;

  constructor(public dialogRef: MatDialogRef<SeeQrComponent>,
              private _location: Location,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.userId = this.data.userId;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
