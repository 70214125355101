import { BeeModel } from '@shared/abstract/bee-model';

import { BracelitEvent } from './bracelit-event';

export class Wallet extends BeeModel {
  money: number;
  pivot: any;
  events: BracelitEvent[] = [];

  constructor(data: any) {
    super(data);

    if (data.hasOwnProperty('events') && data['events']) {
      this.events = [];

      for (const event of data['events']) {
        this.events.push(new BracelitEvent(event));
      }
    }
  }
}
