import { FormGroup } from '@angular/forms';

/**
 * Validates that two FormGroups are equal.
 */
export const BeeEqualValidator = (group: FormGroup) => {
  let val;
  let valid = true;

  for (const name in group.controls) {
    if (val === undefined) {
      val = group.controls[name].value;
    } else if (val !== group.controls[name].value && val.length >= 1 && group.controls[name].value.length >= 1) {
      valid = false;
      break;
    }
  }
  if (valid) {
    return null;
  }

  return {areEqual: false};
};
