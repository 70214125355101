import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@shared/angular-material';

import { BeeFormItemModule } from '@bee-components/bee-form/bee-form-item/bee-form-item.module';

import { BeeConfirmDeleteComponent } from './bee-confirm-delete.component';

@NgModule({
  declarations: [
    BeeConfirmDeleteComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    BeeFormItemModule,
    FlexLayoutModule,
  ],
  exports: [
    BeeConfirmDeleteComponent
  ]
})
export class BeeConfirmDeleteModule {
}
