import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { InfoUserFormModule } from '@app/home/wristband-details/event-details/lead-scanner/info-user-form/info-user-form.module';

import { CreditMove } from '@shared/models/bracelit/credit-move';
import { ApiService } from '@shared/services/api.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { WalletsService } from '@shared/services/wallets.service';
import { DomainService } from '@shared/services/domain.service';

import { BracelitEvent } from '../models/bracelit/bracelit-event';
import { Wallet } from '../models/bracelit/wallet';
import { Wristband } from '../models/bracelit/wristband';

@Injectable()
export class WristbandsService {
  /** List of the wristbands of the user. */
  private _wristbands: Wristband[] = [];
  /** Subject to control when the Wristbands change. */
  wristbandsChange: Subject<Wristband[]> = new Subject<Wristband[]>();
  /** Variables for the selected elements by the Client */
  private _selectedWristband: Wristband;
  private _selectedWristband$ = new Subject<Wristband>();
  private _selectedWallet: Wallet;
  private _selectedWallet$ = new Subject<Wallet>();
  private _selectedEvent: BracelitEvent;
  private _selectedEvent$ = new Subject<BracelitEvent>();
  public eventWrist: BracelitEvent;
  public physicalId: string;
  public accountId: string;
  public secureQr: any;
  public wristInfo: Wristband;
  public qrId: string;
  public bidirectional;
  public comment;
  wristbandsAux = [];
  flag = true;
  subElements = [];
  routeParams: any;
  event: any;

  constructor(private apiService: ApiService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private walletsService: WalletsService,
              private authenticationService: AuthenticationService,
              private domainService: DomainService) {
    this.init();
  }

  async init() {
    if (this.authenticationService.client) {
      if (localStorage.getItem('wristbands')) {
        const tempWristbands: Wristband[] = [];
        const wristbands = JSON.parse(localStorage.getItem('wristbands'));
        for (const wristband of wristbands) {
          tempWristbands.push(new Wristband(wristband));
        }
        this.wristbands = tempWristbands;
      } else {
        await this.fetchWristbands(true);
      }

      this.loadData(); // LLAMARLO TAMBIÉN DESDE EL AUTHSERVICE AL HACER LOGIN!
    }
  }

  /**
   * Adds a wristband and emmits change.
   *
   * @param wristband
   */
  addWristband(wristband: Wristband) {
    this.wristbands.push(wristband);
    this.wristbandsChange.next(this.wristbands);
  }
  setEventWristband(event: BracelitEvent) {
    this.eventWrist = event;
  }
  setWristbandQrId(physicalId: string) {
    this.physicalId = physicalId;
  }
  setWristbandAccountId(accountId: string) {
    this.accountId = accountId;
  }
  setWristbandInfo(wristband: Wristband) {
    this.wristInfo = wristband;
  }
  setQrIdFromRoute() {
    this.qrId = this.route.params['value']['associated_qr_id'];
  }

  /**
   * Deletes a wristband and emmits change.
   *
   * @param wristbandId
   */
  deleteWristband(wristbandId: string) {
    const index = this.wristbands.findIndex(x => x.id === wristbandId);
    if (index > -1) {
      this.wristbands.splice(index, 1);
    }
    this.wristbandsChange.next(this.wristbands);
  }

  /**
   * Function to set the selected wristband, selected wallet and selected event based in 2 conditions:
   * Info stored in localStorage (checking if those values are accurate)
   * If there is only one wristband, or one wallet or one event it selects them.
   */
  async loadData() {
    // Wristband
    if (localStorage.getItem('selectedWristbandId')) {
      const selectedWristbandId = localStorage.getItem('selectedWristbandId');
      if (!this.wristbands.length) {
        await this.fetchWristbands(true);
      }
      const wristbandIndex = this.findExistingWristband(selectedWristbandId);
      if (wristbandIndex !== -1) {
        this.selectedWristband = this.wristbands[wristbandIndex];
      } else {
        this.selectedWristband = null;
      }
    } else {
      if (this.wristbands.length === 1) {
        this.selectedWristband = this.wristbands[0];
      } else {
        this.selectedWristband = null;
      }
    }
    this.wristbandsChange.next(this.wristbands);
    // Wallet
    if (this.selectedWristband) {
      if (localStorage.getItem('selectedWalletId') && localStorage.getItem('selectedWristbandId')) {
        const selectedWalletId = localStorage.getItem('selectedWalletId');
        const selectedWristbandId = localStorage.getItem('selectedWristbandId');
        const wallet_index = this.findExistingWallet(selectedWalletId);
        if (wallet_index !== -1) {
          this.selectedWallet = this.selectedWristband.wallets[wallet_index];
        } else {
          this.selectedWallet = await this.walletsService.fetchWallet(selectedWalletId, selectedWristbandId, this.wristbands);
        }
      } else {
        if (this.selectedWristband.wallets.length === 1) {
          this.selectedWallet = this.selectedWristband.wallets[0];
        } else {
          this.selectedWallet = null;
        }
      }
    } else {
      this.selectedWallet = null;
    }

    // Event
    if (this.selectedWallet) {
      if (localStorage.getItem('selectedEventId')) {
        const selectedEventId = localStorage.getItem('selectedEventId');
        const event_index = this.findExistingEvent(selectedEventId);
        if (event_index !== -1) {
          this.selectedEvent = this.selectedWallet.events[event_index];
        } else {
          this.selectedEvent = null;
        }
      } else {
        if (this.selectedWallet.events.length === 1) {
          this.selectedEvent = this.selectedWallet.events[0];
        } else {
          this.selectedEvent = null;
        }
      }
    } else {
      this.selectedEvent = null;
    }
  }

  /**
   * It returns the index of the id passed as param, if it doesn't exist returns -1
   * @param wristbandId
   * @returns {number}
   */
  findExistingWristband(wristbandId: string): number {
    return this.wristbands.findIndex(x => x.id === wristbandId);
  }

  /**
   * It returns the index of the id passed as param, if it doesn't exist returns -1
   * @param walletId
   * @returns {number}
   */
  findExistingWallet(walletId: string): number {
    return this.selectedWristband.wallets.findIndex(x => x.id === walletId);
  }

  /**
   * It returns the index of the id passed as param, if it doesn't exist returns -1
   * @param eventId
   * @returns {number}
   */
  findExistingEvent(eventId: string): number {
    return this.selectedWallet.events.findIndex(x => x.id === eventId);
  }

  /** Getters and setters */
  get wristbands(): Wristband[] {
    return this._wristbands;
  }

  set wristbands(value: Wristband[]) {
    if(JSON.stringify(this._wristbands) !== JSON.stringify(value) || (this.flag === false)) {
      this._wristbands = value;
      if (value !== null) {
        localStorage.setItem('wristbands', JSON.stringify(this._wristbands));
      }
      this.wristbandsChange.next(this._wristbands);
    }
  }

  get selectedWristband(): Wristband {
    return this._selectedWristband;
  }

  set selectedWristband(value: Wristband) {
    this._selectedWristband = value;
    this._selectedWristband$.next(value);
    if (value !== null) {
      localStorage.setItem('selectedWristbandId', this._selectedWristband.id);
    }
  }

  get selectedWristband$(): Subject<Wristband> {
    return this._selectedWristband$;
  }

  get selectedWallet(): Wallet {
    return this._selectedWallet;
  }

  set selectedWallet(value: Wallet) {
    if (value !== null) {
      this._selectedWallet = value;
      this._selectedEvent = value.events[0];
      this._selectedWallet$.next(value);
      localStorage.setItem('selectedWalletId', this._selectedWallet.id);
    }
  }

  get selectedWallet$(): Subject<Wallet> {
    return this._selectedWallet$;
  }

  get selectedEvent(): BracelitEvent {
    return this._selectedEvent;
  }

  set selectedEvent(value: BracelitEvent) {
    this._selectedEvent = value;
    this._selectedEvent$.next(value);
    if (value !== null) {
      localStorage.setItem('selectedEventId', this._selectedEvent.id);
    }
  }

  get selectedEvent$(): Subject<BracelitEvent> {
    return this._selectedEvent$;
  }

  async creditMovements(eventId: string, wristbandId: string, walletId: string) {
    /*const client_id = this.authenticationService.clientId;
    const moves = await this.apiService.get(
      `clients/${client_id}/events/${eventId}/wristbands/${wristbandId}/wallets/${walletId}/credit-moves`
    ).toPromise();
    return moves.map((move) => new CreditMove(move));*/

    const account_id = JSON.parse(localStorage.getItem('accountId'));
    const moves = this.apiService.get(`accounts/${account_id}/events/${eventId}/wristbands/${wristbandId}/historicWristband`)
            .subscribe(_data => {
            });

    return moves;
  }

  async creditMovementsAccounts(eventId: string, walletId: string) {
    const account_id = JSON.parse(localStorage.getItem('accountId'));

    const moves = await this.apiService.get(
      `accounts/${account_id}/events/${eventId}/wallets/${walletId}/credit-moves-account`
    ).toPromise();

    return moves.map((move) => new CreditMove(move));
  }
  async infoCreditMovements(eventId: string, wristbandId: string, walletId: string) {
    const client_id = this.authenticationService.clientId;

    const moves = await this.apiService.get(
      `clients/${client_id}/events/${eventId}/wristbands/${wristbandId}/wallets/${walletId}/credit-payment`
    ).toPromise();

    return moves;
  }

  async onlineOrderInfo(eventId: string, saleOrderId: string) {
    let accountId = JSON.parse(localStorage.getItem('accountId'))

    const lines = await this.apiService.get(
      `accounts/${accountId}/events/${eventId}/onlineOrders/${saleOrderId}`
    ).toPromise();

    return lines;
  }

  async fetchWristband(wristbandId: string, wristbands) {
    const client_id = this.authenticationService.clientId;
    localStorage.setItem('selectedWristbandId', wristbandId);
    if (client_id && client_id !== '') {
      let wristband: any;
      //OPTIMIZACIÓN GALLEGO: AQUÍ BUSCAMOS LA WRISTBAND CORRESPONDIENTE PARA NO TENER QUE LLAMAR AL BACK
      for (var i=0 ; i < wristbands.length ; i++)
      {
          if (wristbands[i].id == wristbandId) {
            wristband = wristbands[i];
            this.selectedWristband = wristbands[i];
          }
      }
      return wristband;

      // const wristband = await this.apiService.get(`clients/${client_id}/wristbands/${wristbandId}`).toPromise(); //OPTIMIZACIÓN GALLEGO: HACIENDO LO ANTERIOR YA NO ES NECESARIO HACE ESTA LLAMADA AL BACKEND
    }

    return null;
  }

  //OPTIMIZACION GALLEGO: Se ha metido una variable de entrada para activar o no la bandera
  async fetchWristbands(value: boolean) {
    this.flag = value;
    if (this.authenticationService.client) {
      this.apiService.get(`clients/${this.authenticationService.client.id}/newWristbandsFullData`).subscribe((data) => { //OPTIMIZACIÓN GALLEGO: ANTES SE LLAMABA A LA RUTA /wristbands
        /* const wristbands = [];
         for(const wristband of data) {
          wristbands.push(new Wristband(wristband));
        }
        if((JSON.stringify(this.wristbandManagement(wristbands)) !== JSON.stringify(this.wristbands)) || (value === false)) {
          this.wristbands = this.wristbandManagement(wristbands);
          localStorage.setItem('wristbands', JSON.stringify(this._wristbands));
        }*/

        //OPTIMIZACIÓN GALLEGO: AL RECOGER LOS DATOS DE ESTA MANERA DESDE BACK, YA NO HACE FALTA HACER EL PARSEO DE ARRIBA
        const wristbands = data;
        if((JSON.stringify(wristbands) !== JSON.stringify(this.wristbands)) || (value === false)) {
          this.wristbands = wristbands;
        }
        this.loadData();
      });
    }
  }

  //OPTIMIZACIÓN GALLEGO: ESTE MÉTODO YA NO SERÍA LLAMADO EN NINGÚN LADO DE LA APP
  /*fetchWristbands2() {
    if (this.authenticationService.client) {
      return this.apiService.get(`clients/${this.authenticationService.client.id}/wristbands`);
    }
  }*/

  //OPTIMIZACIÓN GALLEGO: ESTE MÉTODO YA NO SERÍA LLAMADO EN NINGÚN LADO DE LA APP
  /*fetchWristbandsWithEvent(wristbandId) {
    if (this.authenticationService.client) {
      return this.apiService.patch(`clients/${this.authenticationService.client.id}/wristbands/${wristbandId}/full-data`, this.authenticationService.client.id)
    }
  }*/

  async fetchWristbandsWithAccounts(wristbandId) {
    if (this.authenticationService.client) {
      return this.apiService.get(`clients/${this.authenticationService.client.id}/wristbands/${wristbandId}/with-accounts`);
    }
  }

  wristbandManagement(wristbands) {
    this.wristbandsAux = [];
    /*   if (wristbands.length > 1) {
         if ((wristbands[0]['accountId'] === wristbands[1]['accountId']) &&
           (wristbands[0]['clientId'] === wristbands[1]['clientId'])) {
           for (const wristband of wristbands) {
             if (wristband['chinoCode'] !== null) {
               this.wristbandsAux.push(wristband);
             }
           }
           // this.wristbandsAux.push(wristbands[1]);
         } else {
           for (const wristband of wristbands) {
             this.wristbandsAux.push(wristband);
           }
           // this.wristbandsAux.push(wristbands[0]);
           // this.wristbandsAux.push(wristbands[1]);
         }
    } else {
      this.wristbandsAux = wristbands;
    }*/
    this.wristbandsAux = wristbands;

    return this.wristbandsAux;
  }

  checkBidirectional(eventId) {
    this.apiService.get(`events/${eventId}/checkBidirectionalContact`).subscribe(data => {
      this.bidirectional = data['hasBidirectionalLeadScanner'];
      },
        err => {
          console.error(err);
    });
  }

  checkComment(value) {
    this.comment = value;
    // OPTIMIZACIÓN GALLEGO: AHORA SE RECIBE DIRECTAMENTE EL VALOR EN VEZ DE HACER UNA NUEVA LLAMADA AL BACK
    /*this.apiService.get(`events/${eventId}/checkComment`).subscribe(data => {
      this.comment = data['hasLeadScannerComment'];
      },
        err => {
          console.error(err);
    });*/
  }

  downloadFile(accountId, eventId, userId): any {
      //   var HTTPOptions = {
      //     headers: new HttpHeaders({
      //        'Accept':'application/pdf,text/html, application/xhtml+xml',
      //        'Content-Type': 'application/x-www-form-urlencoded'
      //     }),
      //     responseType: 'text',
      //     observe: 'response'
      //  };
      // 	return this.http.get(`https://test.bracelit.es/api/v1/accounts/${accountId}/events/${eventId}/users/${userId}/exportLSCSV`,HTTPOptions
      //   );
      return this.apiService.download(`accounts/${accountId}/events/${eventId}/users/${userId}/exportLSCSV`);
    }

  async listOrderPlaces(){
    let accountId = JSON.parse(localStorage.getItem('accountId'))
    let eventId = String(localStorage.getItem('selectedEventId'))
    return this.apiService.get(`accounts/${accountId}/events/${eventId}/onlineOrderPlaces`).toPromise();
  }

  getNotifications() {
    const user_id = this.authenticationService.user.id;

    return this.apiService.get(`users/${user_id}/notifications`);
  }

  getEventNotifications(eventId) {
    const user_id = this.authenticationService.user.id;

    return this.apiService.get(`events/${eventId}/users/${user_id}/notifications`);
  }

  syncDelem(wristbandId) {
    let accountId = JSON.parse(localStorage.getItem('accountId'))
    let eventId = String(localStorage.getItem('selectedEventId'))
    return this.apiService.post(`accounts/${accountId}/events/${eventId}/delem/wristbands/${wristbandId}/showOrCreateRoomPassengers`,'');
  }

  associateCode(clientId, code) {
    return this.apiService.post(
      `clients/${clientId}/associateCode`, {"code":code.toLowerCase()});
  }

  setSubElements(value: any) {
    this.subElements = value;
  }

  getSubElements() {
    return this.subElements;
  }

  setRouteParams(routeParams: any) {
    this.routeParams = routeParams
  }

  getRouteParams() {
    return this.routeParams
  }

  setEvent(event:any) {
    this.event = event;
  }

  getEvent() {
    return this.event
  }

}
